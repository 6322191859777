import { Accessibility, AddComment, Comment, Edit, GroupAdd, People } from '@mui/icons-material';
import React from 'react';
import { ERoles } from '../../constants/user';
import IRouteItems from '../../interfaces/routing';
import UserCreate, { Secondary as UserCreateSecondary } from '../../pages/account/Create';
import UserList from '../../pages/account/List';
import MessageCreate, { Secondary as MessageCreateSecondary } from '../../pages/message/Create';
import MessageList, { Secondary as MessageListSecondary } from '../../pages/message/List';

const items: IRouteItems = {
  hr: {
    title: 'HR',
    icon: <Accessibility />,
    description: 'Human resources manager instruments',
    items: {
      messages: {
        title: 'Automatic Message List',
        icon: <Comment />,
        component: MessageList,
        secondary: MessageListSecondary,
        roles: [ERoles.HR],
        description: 'View list of all existing automatic messages',
      },
      'messages/create': {
        title: 'Create new Message',
        icon: <AddComment />,
        component: MessageCreate,
        secondary: MessageCreateSecondary,
        roles: [ERoles.HR],
        description: 'Make new automatic messages',
      },
      'messages/edit/:id': {
        title: 'Edit Message',
        icon: <Edit />,
        component: MessageCreate,
        secondary: MessageCreateSecondary,
        roles: [ERoles.HR],
        description: 'Edit automatic messages by ID',
        menu: false,
      },
      accounts: {
        title: 'Account List',
        icon: <People />,
        component: UserList,
        roles: [ERoles.HR, ERoles.USER_MANAGER],
        description: 'View list of all existing company accounts',
      },
      'accounts/create': {
        title: 'Create new Account',
        icon: <GroupAdd />,
        component: UserCreate,
        secondary: UserCreateSecondary,
        roles: [ERoles.HR, ERoles.USER_MANAGER],
        description: 'Make new account for new team members or outsourcers/outstaffers',
      },
    },
  },
};

export default items;
