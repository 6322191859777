import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Context } from '../../../stores/top-message';

@observer
class TopMessage extends React.Component<any> {
  static contextType = Context;

  render() {
    if (!this.context || !this.context.component) {
      return <></>;
    }
    const Component = this.context.component;
    return (
      <Box p={1} sx={{ textAlign: 'center', backgroundColor: theme => theme.palette.error.main, color: theme => theme.palette.error.contrastText }}>
        <Component />
      </Box>
    );
  }
}

export default TopMessage;
