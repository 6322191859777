import { makeAutoObservable } from 'mobx';
import React, { createContext } from 'react';
import { createPageView } from '../../utils/analitycs';
import { history } from '../../utils/history/index';

const def = 'Admin Panel';

class Navigation {
  public active: string = '';
  public state: boolean = true;
  public title: string = def;
  public options?: any;
  public fullscreen: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  public setActive(active: string, title?: string, options?: any) {
    this.active = active;
    this.title = title || def;
    this.options = options;
    createPageView(active);
  }

  public setFullscreen(fullscreen: boolean = false) {
    this.fullscreen = fullscreen;
  }

  public setState(state: boolean) {
    this.state = state;
  }

  public toggleState() {
    this.state = !this.state;
  }

  private _timeout?: NodeJS.Timeout;

  public redirect(url: string) {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    history.replace(url);
  }

  public goTo(url: string) {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      history.push(url);
    }, 1);
  }
}

const navigation = new Navigation();

export const setActive = (active: string, title?: string, options?: any) => navigation.setActive(active, title, options);

export const setFullscreen = (fullscreen: boolean = false) => navigation.setFullscreen(fullscreen);

export const goTo = (url: string) => navigation.goTo(url);

export const redirect = (url: string) => navigation.redirect(url);

export const Context = createContext<Navigation>(navigation);
const Provider = ({ children }: any) => <Context.Provider value={navigation}>{children}</Context.Provider>;

export default Provider;
