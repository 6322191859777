import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormHelperText, TextField } from '@mui/material';
import { format, parse } from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import React from 'react';
import { IFieldProps, IProp, IString } from './interfaces';

export const TimeInput = ({ id, disabled, changed, editedRaw, field, error, value }: IFieldProps) => {
  const string = field as IString & IProp;
  const val = (value as string | undefined) || '';
  const dateTimeRenderInput = (params: any) => <TextField {...params} id="sendAfter" error={!!error} />;
  const timeFormat = 'HH:mm:ss';
  const setDate = (date: Date | null) => editedRaw(id, date && !isNaN(date.getTime()) ? format(date, timeFormat) : '');
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <TimePicker label={string.title} disabled={disabled} value={parse(val, timeFormat, new Date())} onChange={setDate} renderInput={dateTimeRenderInput} />
      </LocalizationProvider>
      <FormHelperText>{error}</FormHelperText>
    </>
  );
};
