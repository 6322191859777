import React from 'react';
import Article from '../../components/Article';

export { default as Secondary } from './Secondary';

class SickLeave extends React.Component {
  render() {
    return <Article key="article" alias="sickLeave" />;
  }
}

export default SickLeave;
