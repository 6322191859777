import Ajv from 'ajv';
import ajvf from 'ajv-formats';

const isInvalid = (schema: any, value: any) => {
  const validator = new Ajv({
    allErrors: true,
    coerceTypes: true,
  });
  const formatted = ajvf(validator);
  const validate = formatted.compile(schema);
  if (validate(value === undefined ? null : value)) {
    return false;
  }
  return validate.errors;
};

export default isInvalid;
