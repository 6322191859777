import { Box, Collapse, Divider, Typography } from '@mui/material';
import React from 'react';
import { ComponentType } from '../../interfaces/system';
import Animate from '../elements/Animate';
import Heading from '../elements/typography/Heading';

export interface IQuestion {
  question: string;
  answer: ComponentType;
}

const Item = ({ item }: { item: IQuestion }) => {
  const [checked, setChecked] = React.useState<boolean>(false);

  const toggle = () => setChecked(!checked);
  return (
    <Box className="faq-item" sx={{ pt: 1 }}>
      <Typography variant="h6" onClick={toggle} sx={{ cursor: 'pointer' }}>
        <Animate animationType="rotate-cw" checked={checked}>
          ›
        </Animate>{' '}
        {item.question}
      </Typography>
      <Collapse in={checked} sx={{ px: 2, pt: 1 }}>
        {item.answer}
      </Collapse>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

class FAQ extends React.Component<{ questions: IQuestion[] }> {
  render() {
    if (this.props.questions.length === 0) {
      return null;
    }
    return (
      <div className="faq">
        <Heading>Frequently asked questions</Heading>
        {this.props.questions.map((item, i) => (
          <Item key={`${i}-${item.question}`} item={item} />
        ))}
      </div>
    );
  }
}

export default FAQ;
