import { Card, CardActions, CardContent, Chip, FormControl, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import React from 'react';
import Button from '../../../../components/elements/Button';
import { IScheduled } from '../../../../interfaces/outstaff';

const Scheduled = ({ item, isLoading, removeItem }: { item: IScheduled; isLoading: boolean; removeItem: (id: number) => Promise<any> }) => {
  const removeOnClick = () => removeItem(item.id);
  return (
    <Card variant="outlined" sx={{ mt: 1 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom={true}>
          Scheduled message at <Chip variant="outlined" label={format(item.sendAfter, 'Pp', { locale })} size="small" /> to{' '}
          {item.group ? <Chip variant="outlined" label={item.group.name} size="small" /> : 'all chats'}
        </Typography>
        <FormControl fullWidth={true} sx={{ m: theme => theme.spacing(1), width: theme => `calc(100% - ${theme.spacing(2)})` }}>
          <TextField InputProps={{ readOnly: true }} multiline={true} label="Content" variant="outlined" value={item.message} />
        </FormControl>
      </CardContent>
      <CardActions>
        <Button size="small" disabled={isLoading} onClick={removeOnClick}>
          Cancel scheduled message
        </Button>
      </CardActions>
    </Card>
  );
};
export default Scheduled;
