import { LinearProgress } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Context } from '../../../stores/progress';

@observer
class Progressbar extends React.Component<any> {
  static contextType = Context;

  render() {
    if (!this.context || this.context.completed === null) {
      return null;
    }
    return (
      <LinearProgress
        sx={{
          zIndex: 9999,
          position: 'fixed',
          left: 0,
          right: 0,
        }}
        variant="determinate"
        color="primary"
        value={this.context.completed}
      />
    );
  }
}

export default Progressbar;
