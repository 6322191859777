import { Update } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React from 'react';
import Button from '../../../components/elements/Button';
import Heading from '../../../components/elements/typography/Heading';
import Preloader from '../../../components/Preloader';
import { IScheduled } from '../../../interfaces/outstaff';
import Scheduled from './partials/Scheduled';

const ScheduledList = ({ getList, removeItem }: { getList: () => Promise<IScheduled[]>; removeItem: (id: number) => Promise<any> }) => {
  const [init, setInit] = React.useState<boolean>(false);
  const [list, setList] = React.useState<IScheduled[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const loadList = React.useCallback(async () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    try {
      const res = await getList();
      setList(res);
    } catch (e) {
      // do nothing
    }
    setLoading(false);
  }, [getList, isLoading]);

  React.useEffect(() => {
    if (!init) {
      setInit(true);
      loadList();
    }
  }, [init, loadList]);

  const removeFn = async (id: number) => {
    await setLoading(true);
    await removeItem(id);
    await loadList();
  };

  return (
    <div>
      <Heading>Scheduled messages</Heading>
      <Preloader isLoading={isLoading} fallback={!list.length && <p>There are no scheduled messages</p>}>
        {list.length && list.map(item => <Scheduled key={item.id} item={item} isLoading={isLoading} removeItem={removeFn} />)}
      </Preloader>
      <Divider sx={{ my: 1 }} />
      <Button color="primary" variant="outlined" disabled={isLoading} onClick={loadList} startIcon={<Update />}>
        Reload
      </Button>
    </div>
  );
};
export default ScheduledList;
