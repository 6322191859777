import { IData } from '../../interfaces/api';
import { IMessage, IMessageShort } from '../../interfaces/message';
import request from '../request';

const messageApi = request('messages');

export const getById = async (id: number): Promise<IMessage> => {
  const result = await messageApi(`${id}`, 'get');
  return result;
};

export const testById = async (id: number): Promise<void> => {
  await messageApi(`${id}/test`, 'get');
};

export const get = async (): Promise<IMessage[]> => {
  const result = await messageApi();
  return result;
};

export const create = async (message: IMessageShort): Promise<IMessage> => {
  const data: IData = message as any;
  const result = await messageApi('', 'post', undefined, data);
  return result;
};

export const updateById = async (id: number, message: IMessageShort): Promise<void> => {
  const data: IData = message as any;
  await messageApi(`${id}`, 'patch', undefined, data);
};

export const deleteById = async (id: number): Promise<void> => {
  await messageApi(`${id}`, 'delete');
};
