import { Box, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { StateContext } from '../../../../stores';
import Container from '../../../elements/Container';
import CdmColor from '../../../elements/icons/CdmColor';
// import logo from './logo.png';

@observer
class Footer extends React.Component<any> {
  static contextType = StateContext;

  state: { anchorEl: null | HTMLElement } = { anchorEl: null };

  private _openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl: event.currentTarget });
  }

  private _closeMenu() {
    this.setState({ anchorEl: null });
  }

  render() {
    const from = 2021;
    const current = new Date().getFullYear();
    const years = from === current ? current : `${from} - ${current}`;
    return (
      <Container>
        <Box py={2} sx={{ background: '#000', color: '#eee' }}>
          <Grid container={true}>
            <Grid item={true}>
              <Box px={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box pl={2}>
                  {/* <img src={logo} alt="CDM" style={{ width: 48 }} /> */}
                  <CdmColor sx={{ width: 48, height: 48, borderRadius: 48 }} />
                </Box>
                <Box pl={2}>
                  <Typography variant="caption" component="div">
                    Codemotion
                  </Typography>
                  <Typography variant="caption" component="div">
                    {years}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default Footer;
