// import React from 'react';
// import HomeIcon from '@material-ui/icons/Home';

import IRouteItems from '../interfaces/routing';

import company from './company';
import contact from './contact';
import home from './home';
import hr from './hr';
import issues from './issues';
import outstaff from './outstaff';
import recruiterbox from './recruiterbox';
import user from './user';

const items: IRouteItems = { ...home, ...user, ...company, ...hr, ...contact, ...outstaff, ...recruiterbox, ...issues };

export default items;

export const topMenu: IRouteItems = { ...user, ...company, ...hr, ...contact, ...outstaff, ...recruiterbox, ...issues };

// import (\w+) from '../../pages
// import $1, { Secondary as $1Secondary } from '../../pages

// ^(\s*)component: (\w+),
// $1component: $2,\n$1secondary: $2Secondary,
