import { ArticleAliasType, BlogAliasType, FAQAliasType } from '../../constants/article';
import { IArticle, IBlog, IFAQ } from '../../interfaces/article';
import request from '../request';

const userApi = request('articles');

export const getByAlias = async (alias: ArticleAliasType): Promise<IArticle> => {
  const { title, content, link, createdAt } = await userApi(alias, 'get');
  return { title, content, link, createdAt: new Date(createdAt) };
};

export const getBlogByAlias = async (alias: BlogAliasType): Promise<IBlog> => {
  const { title, content, link, items, createdAt } = await userApi(`blog/${alias}`, 'get');
  return {
    title,
    content,
    link,
    items: items.map((i: { id: number; title: string; createdAt: string; link: string }) => ({ ...i, createdAt: new Date(i.createdAt) })),
    createdAt: new Date(createdAt),
  };
};
export const getBlogItemByIdAndParentAlias = async (alias: BlogAliasType, id: number): Promise<IArticle> => {
  const { title, content, createdAt, link } = await userApi(`blog/${alias}/${id}`, 'get');
  return {
    title,
    content,
    link,
    createdAt: new Date(createdAt),
  };
};

export const getFAQByAlias = async (alias: FAQAliasType): Promise<IFAQ> => {
  const { title, content, link, createdAt, items } = await userApi(`faq/${alias}`, 'get');
  return { title, content, link, createdAt: new Date(createdAt), items };
};
