import React from 'react';
import Article from '../../components/Article';
import FAQ, { IQuestion } from '../../components/FAQ';

export { default as Secondary } from './Secondary';

const Hardware = () => {
  const questions: IQuestion[] = [];
  return (
    <>
      <Article alias="hardware" />
      <FAQ questions={questions} />
    </>
  );
};

export default Hardware;
