import { Button, Chip } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React from 'react';
import { ResError } from '../../../apis/request';
import { deleteMyConnection, getMySlack, getMyStats } from '../../../apis/user';
import DataView, { DataType, IFieldView } from '../../../components/DataView';
import Heading from '../../../components/elements/typography/Heading';
import { dateFormat } from '../../../constants/date';
import { EOAuth, ERoles } from '../../../constants/user';
import { Context as AuthContext } from '../../../stores/auth';
import { redirectToAuth } from '../../../utils/auth';
import convertRole from '../../../utils/convert-role';
import './index.scss';

export const pascalCaseToString = (pascal: string) => {
  const str = pascal.replace(/(\t|(\s{2,})|_)/g, ' ').trim();
  let res = str[0].toUpperCase();
  res += str.substr(1).toLowerCase();
  return res;
};

interface IData {
  isLoading: boolean;
  data?: IFieldView[];
  error?: React.ReactNode;
}

const addLeadZero = (num: number) => `0${num}`.substr(-2, 2);

interface IState {
  stats: IData;
  slack: IData;
}

@observer
class My extends React.Component<{}, IState> {
  static contextType = AuthContext;

  state: IState = { stats: { isLoading: true }, slack: { isLoading: true } };

  // constructor(props: any) {
  //   super(props);
  // }

  private async _loadSlack() {
    try {
      await this.setState({ slack: { isLoading: true } });
      const slack = await getMySlack();

      let offset = 'N/A';

      if (slack.timeZone && (slack.timeZone.offset || slack.timeZone.offset === 0)) {
        const offsetAbsolute = Math.abs(slack.timeZone.offset);
        const offsetSymb = slack.timeZone.offset >= 0 ? '+' : '-';
        const offsetHours = Math.floor(offsetAbsolute);
        const offsetMinutes = Math.floor((offsetAbsolute - offsetHours) * 6) * 10; // experimental

        offset = `${offsetSymb}${addLeadZero(offsetHours)}:${addLeadZero(offsetMinutes)}`;
      }
      const status = [];
      if (slack.isBlocked) {
        status.push('Blocked');
      }
      if (slack.isDeleted) {
        status.push('Deleted');
      }
      if (status.length === 0) {
        status.push('Active');
      }

      const data: IFieldView[] = [
        {
          title: 'First name',
          value: slack.firstName,
          compact: true,
        },
        {
          title: 'Last name',
          value: slack.lastName,
          compact: true,
        },
        {
          title: 'Email',
          value: slack.email,
          compact: true,
        },
        {
          title: 'Display name (nickname)',
          value: `@${slack.nickname} `,
          compact: true,
        },
        {
          title: 'Title',
          value: slack.title,
          compact: true,
        },
        {
          title: 'Timezone',
          value: slack.timeZone ? `${slack.timeZone.label} (${offset}, ${slack.timeZone.name})` : 'N/A',
          compact: true,
        },
        {
          title: 'Status',
          value: status.join(', '),
          compact: true,
        },
        {
          title: 'Profile link',
          value: (
            <Button target="_blank" href={`https://app.slack.com/team/${slack.id}`} variant="outlined">
              Go to slack profile
            </Button>
          ),
          compact: true,
        },
      ];
      if (slack.isForced) {
        const unassign = async () => {
          this.setState({ slack: { isLoading: true } });
          await deleteMyConnection(EOAuth.SLACK);
          await this._loadSlack();
        };
        data.push({
          title: 'Account management',
          value: (
            <>
              This account was manually connected.{' '}
              <Button variant="contained" onClick={unassign}>
                Click here to unnasign
              </Button>
            </>
          ),
        });
      }
      await this.setState({ slack: { isLoading: false, data } });
    } catch (e) {
      let error: React.ReactNode = (e as Error).message;
      if (e instanceof ResError && e.code === 404) {
        const assign = async () => {
          this.setState({ slack: { isLoading: true } });
          try {
            await redirectToAuth(EOAuth.SLACK, window.location.pathname);
          } catch (e) {
            await this.setState({ slack: { isLoading: false, error: (e as Error).message } });
          }
        };
        error = (
          <>
            It looks like you do not have a slack, or it is tied to another email. Change the email in your slack profile, or link your slack by{' '}
            <Button onClick={assign} variant="outlined">
              logging in
            </Button>
          </>
        );
      }
      await this.setState({ slack: { isLoading: false, error } });
    }
  }

  private async _loadStats() {
    try {
      await this.setState({ stats: { isLoading: true } });
      const values = await getMyStats();

      const yearsExp = Math.floor(values.yearsInCompany);
      const monthExp = Math.floor((values.yearsInCompany - yearsExp) * 12);
      let exp = '';
      if (yearsExp) {
        exp += `${yearsExp} ${yearsExp > 1 ? 'years' : 'year'} `;
      }
      if (monthExp) {
        exp += `${monthExp} ${monthExp > 1 ? 'months' : 'month'}`;
      }
      if (yearsExp === 0 && monthExp === 0) {
        exp = 'Less than a month';
      }

      const data: IFieldView[] = [
        {
          title: 'Full name',
          value: values.fullName,
        },
        {
          title: 'Email',
          value: values.email,
          compact: true,
        },
        {
          title: 'Birth Date (age)',
          value: `${format(values.birthDate, dateFormat)} (${values.age})`,
          compact: true,
        },
        {
          title: 'Position',
          value: values.position,
          compact: true,
        },
        {
          title: 'Works at',
          value: values.worksAt,
          compact: true,
        },
        {
          title: 'Start working date',
          value: format(values.startWorkingDate, dateFormat),
          compact: true,
        },
        {
          title: 'Work expirience in company',
          value: exp,
          compact: true,
        },
      ];
      await this.setState({ stats: { isLoading: false, data } });
    } catch (e) {
      await this.setState({ stats: { isLoading: false, error: (e as Error).message } });
    }
  }

  componentDidMount() {
    this._loadStats();
    this._loadSlack();
  }

  render() {
    const { user } = this.context;
    const data: DataType = [
      {
        title: 'Google Account',
        fields: [
          {
            title: 'First name',
            value: user.name.firstName,
            compact: true,
          },
          {
            title: 'Last name',
            value: user.name.lastName,
            compact: true,
          },
          {
            title: 'Email',
            value: user.email,
            compact: true,
          },
          {
            title: 'Profile link',
            value: (
              <Button
                target="_blank"
                href={`https://accounts.google.com/AccountChooser?continue=https%3A%2F%2Fmyaccount.google.com%2Fu%2F%3Fauthuser%3DUSERNAME%40DOMAIN&service=mail&Email=${user.email}`}
                variant="outlined"
              >
                Go to google account
              </Button>
            ),
            compact: true,
          },
        ],
      },
      {
        title: 'Slack',
        isLoading: this.state.slack.isLoading,
        isError: this.state.slack.error,
        fields: this.state.slack.data || [],
      },
      {
        title: 'Company Statistic',
        isLoading: this.state.stats.isLoading,
        isError: this.state.stats.error,
        fields: this.state.stats.data || [],
      },
      {
        title: 'This application',
        fields: [
          {
            title: user.roles.length > 1 ? 'Roles' : 'Role',
            value: user.roles.map((role: ERoles) => <Chip sx={{ mr: 1 }} key={role} label={convertRole(role)} />),
          },
        ],
      },
    ];
    return (
      <>
        <Heading>Your personal info</Heading>
        <DataView data={data} />
      </>
    );
  }
}

export default My;
