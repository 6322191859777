import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { create, getById, updateById } from '../../../apis/cv';
import ContentForm, { IContentFormSchema } from '../../../components/ContentForm';
import { ICV, ICVShort } from '../../../interfaces/cv';
import { open } from '../../../stores/modal';
import { Context, goTo } from '../../../stores/navigation';
import { Modal } from '../elements/Modal';

interface IState {
  isLoading: boolean;
  cv: ICV | null;
  error?: string;
}

@observer
class CreateCV extends React.Component<{}, IState> {
  static contextType = Context;

  state: IState = { isLoading: false, cv: null };

  async componentDidMount() {
    const id = this.context.options.params.id;
    if (id) {
      try {
        const cv = await getById(id);
        this.setState({ isLoading: false, cv });
      } catch (e) {
        this.setState({ isLoading: false, error: (e as Error).message });
      }
    }
  }

  render() {
    const id = this.context.options.params.id;
    const reset = () => goTo('/outstaff/cv');
    const save = async (cv: any): Promise<void> => {
      let res: { link?: string };
      if (id) {
        res = await updateById(id, cv as ICVShort);
      } else {
        res = await create(cv as ICVShort);
      }
      if (res && res.link) {
        open(() => <Modal link={res.link as string} onLink={reset} />, reset);
        return;
      }
      reset();
    };

    const schema: IContentFormSchema = {
      general: {
        title: 'General',
        type: 'object',
        properties: {
          firstName: { title: 'First Name', type: 'string', required: true, maxLength: 200 },
          lastName: { title: 'Last Name', type: 'string', maxLength: 200 },
          position: { title: 'Position', type: 'string', required: true, maxLength: 200 },
        },
        required: true,
      },
      summary: {
        title: 'Summary',
        type: 'object',
        properties: {
          experience: {
            title: 'Experience',
            type: 'number',
            required: true,
            maxLength: 1000,
            suffix: '+ years of proven professional experience in software development',
          },
          stack: {
            title: 'Main/Final stack',
            examples: ['3.5+ years with Laravel', '8+ years with Java'],
            // description: (
            //   <>
            //     3.5+ years with Laravel
            //     <br />
            //     8+ years with Java
            //   </>
            // ),
            type: 'string',
            required: true,
            maxLength: 1000,
          },
          location: { title: 'Location (and timezone)', type: 'string', maxLength: 1000 },
          introdution: { title: 'Introdution', type: 'string', multiline: true, maxLength: 4000 },
        },
        required: true,
      },
      languages: {
        title: 'Languages',
        type: 'array',
        items: {
          type: 'object',
          title: 'Language',
          properties: {
            name: {
              title: 'Name',
              examples: ['English', 'Russian'],
              type: 'string',
              required: true,
              maxLength: 200,
            },
            level: {
              title: 'Level',
              type: 'string',
              enum: ['Intermediate', 'Upper-Intermediate', 'Advanced', 'Proficiency', 'Native'],
              required: true,
              maxLength: 200,
            },
            comment: { title: 'Comment', type: 'string', maxLength: 200 },
          },
        },
        required: true,
      },

      skillCategories: {
        type: 'array',
        title: 'Skill categories',
        items: {
          type: 'object',
          title: 'Skill category',
          properties: {
            name: {
              title: 'Main skill',
              description: 'Leave empty to generic skills category',
              type: 'string',
              maxLength: 200,
            },
            skills: {
              title: 'Skills',
              type: 'array',
              maxItems: 30,
              items: {
                type: 'object',
                title: 'Skill',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                    required: true,
                    maxLength: 200,
                  },
                },
              },
              required: true,
            },
          },
        },
      },

      educations: {
        title: 'Educations',
        type: 'array',
        maxItems: 5,
        items: {
          type: 'object',
          title: 'Education',
          properties: {
            name: { title: 'University name', type: 'string', required: true, maxLength: 200 },
            location: { title: 'University location', type: 'string', maxLength: 200 },
            degree: { title: 'Degree', type: 'string', maxLength: 200 },
            date: { title: 'Date', type: 'string', maxLength: 200 },
          },
        },
        required: true,
      },

      experiences: {
        title: 'Experience',
        type: 'array',
        items: {
          title: 'Company',
          type: 'object',
          properties: {
            name: { title: 'Company name', type: 'string', maxLength: 200 },
            location: { title: 'Company location', type: 'string', maxLength: 200 },
            start: { title: 'Start date', type: 'string', maxLength: 200 },
            end: { title: 'End date', type: 'string', maxLength: 200 },
            projects: {
              title: 'Projects',
              type: 'array',
              minItems: 1,
              items: {
                title: 'Project',
                type: 'object',
                properties: {
                  name: { title: 'Project name', type: 'string', maxLength: 200 },
                  description: { title: 'Description', type: 'string', multiline: true, maxLength: 4000 },
                  link: { title: 'Link to the project', type: 'string', maxLength: 1000 },
                  duration: { title: 'Duration', type: 'string', examples: ['1 month', '1 year'], maxLength: 200 },
                  role: { title: 'Role', type: 'string', maxLength: 1000 },
                  stack: { title: 'Stack', type: 'string', maxLength: 1000 },
                  responsibilities: { title: 'Responsibilities', type: 'string', multiline: true, maxLength: 1000 },
                  metrics: { title: 'Project metrics and key features', type: 'string', multiline: true, maxLength: 1000 },
                },
              },
              required: true,
            },
          },
        },
        required: true,
      },
      comment: {
        title: 'Comment to CV',
        type: 'string',
        multiline: true,
        maxLength: 4000,
      },
      export: {
        title: 'Also export as Google Document',
        type: 'boolean',
      },
    };
    return (
      <Box className="message-form" key="form">
        {this.state.error && <Box>{this.state.error}</Box>}
        <ContentForm
          title={id ? 'Update CV' : 'Create CV'}
          restore={true}
          disabled={this.state.isLoading || !!this.state.error}
          data={this.state.cv as any}
          schema={schema}
          submitBtnText="Save"
          onSubmit={save}
          onReset={reset}
        />
      </Box>
    );
  }
}

export default CreateCV;
