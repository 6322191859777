import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

type AnimationType = 'rotate-cw' | 'rotate-ccw';

const animations: { [key: string]: any } = {
  'rotate-cw': { transform: 'rotate(90deg)' },
  'rotate-ccw': { transform: 'rotate(-90deg)' },
};

const Animate = ({ animationType, checked, children, sx }: { animationType: AnimationType; checked: boolean; children: any; sx?: SxProps }) => {
  return (
    <Box
      sx={{ ...(sx || {}), ...(checked && animations[animationType] ? animations[animationType] : {}) }}
      style={{ transition: 'transform 0.3s', display: 'inline-block' }}
    >
      {children}
    </Box>
  );
};

export default Animate;
