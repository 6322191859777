import { Box } from '@mui/material';
import React from 'react';
import CdmColor from '../icons/CdmColor';
import Logo from '../Logo';
import './index.scss';

const Splash = () => (
  <Box className="splash">
    <CdmColor className="splash-icon" />
    <Box className="splash-container">
      <Box className="splash-text">
        <Logo variant="white" width={190} />
      </Box>
    </Box>
  </Box>
);

export default Splash;
