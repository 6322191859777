import React from 'react';
import Link from '../Link';

const TryAgain = () => {
  return (
    <div>
      Please try again later or <Link href="/feedback">contact the developer</Link>
    </div>
  );
};

export default TryAgain;
