import { BugReport } from '@mui/icons-material';
import { observer } from 'mobx-react';
import React from 'react';
import { ResError } from '../../apis/request';
import { getMy } from '../../apis/user';
import { Context as AuthContext } from '../../stores/auth';
import { add, remove } from '../../stores/float-buttons';
import { open } from '../../stores/top-message';
import checkVersion from '../../utils/check-version';
import Background from '../Background';
import Link from '../elements/Link';
import Splash from '../elements/Splash';
import TryAgain from '../elements/TryAgain';
import Preloader from '../Preloader';
import Routing from '../Routing';
import Alert from '../utils/Alert';
import FABs from '../utils/FABs';
import Menu from '../utils/Menu';
import Modal from '../utils/Modal';
import Progressbar from '../utils/Progressbar';
import TopMessage from '../utils/TopMessage';
import './index.scss';

interface IState {
  isLoading: boolean;
  error?: boolean;
}

@observer
class App extends React.Component<{}, IState> {
  static contextType = AuthContext;

  state: IState = { isLoading: true };

  private btnKey: any;

  private _timeout?: NodeJS.Timeout;
  private _interval?: NodeJS.Timeout;
  private _prevCheck?: boolean;

  async componentDidMount() {
    const start = Date.now();
    setInterval(async () => {
      const thisCheck = await checkVersion();
      if (!thisCheck && (this._prevCheck === undefined || this._prevCheck !== thisCheck)) {
        const onClick = () => window.location.reload();
        open(() => (
          <>
            The app version is out of date, please{' '}
            <Link color="inherit" onClick={onClick}>
              click here
            </Link>{' '}
            to reload
          </>
        ));
      }
      this._prevCheck = thisCheck;
    }, 60000);
    this.btnKey = add({ title: 'Bug report/Feature request', icon: <BugReport />, href: '/feedback', bgColor: '#000', color: '#FFF' });
    if (this.context.token && !this.context.user) {
      try {
        this.setState({ isLoading: true });
        await getMy();
      } catch (e) {
        const err = e as ResError;
        this.setState({ isLoading: false, error: !!err.code && err.code !== 401 });
        return;
      }
      this._timeout = setTimeout(() => {
        this.setState({ isLoading: false });
      }, Math.max(100, 3500 - (Date.now() - start)));
      return;
    }
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    if (this.btnKey) {
      remove(this.btnKey);
    }
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    if (this._interval) {
      clearInterval(this._interval);
    }
  }

  render() {
    return (
      <>
        <Background />
        <Progressbar />
        <TopMessage />
        <Preloader isFull={true} hideLogo={true} isLoading={this.state.isLoading} component={Splash} fallback={this.state.error && <TryAgain />}>
          <Routing />
        </Preloader>
        <Alert />
        <FABs />
        <Menu />
        <Modal />
      </>
    );
  }
}

export default App;
