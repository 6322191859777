import React, { Context, createContext, FC } from 'react';
import AlertProvider, { Context as AlertContext } from './alert';
import AuthProvider, { Context as AuthContext } from './auth';
import FABProvider, { Context as FABContext } from './float-buttons';
import MenuProvider, { Context as MenuContext } from './menu';
import ModalProvider, { Context as ModalContext } from './modal';
import NavigationProvider, { Context as NavigationContext } from './navigation';
import ProgressProvider, { Context as ProgressContext } from './progress';
import TopMessageProvider, { Context as TopMessageContext } from './top-message';

export const StateContext = createContext({} as any);

const map: { [key: string]: { provider: FC; context: Context<any> } } = {
  alert: { provider: AlertProvider, context: AlertContext },
  auth: { provider: AuthProvider, context: AuthContext },
  floatButton: { provider: FABProvider, context: FABContext },
  navigation: { provider: NavigationProvider, context: NavigationContext },
  menu: { provider: MenuProvider, context: MenuContext },
  modal: { provider: ModalProvider, context: ModalContext },
  progress: { provider: ProgressProvider, context: ProgressContext },
  topMessage: { provider: TopMessageProvider, context: TopMessageContext },
};

const recurcy = (keys: string[], cb: (values: any) => any, values = {}) => {
  const key = keys.shift();
  if (key === undefined) {
    return cb(values);
  }
  const { provider: Provider, context: Context } = map[key];
  return (
    <Provider>
      <Context.Consumer>
        {value => {
          return recurcy(keys, cb, Object.assign({}, values, { [key]: value }));
        }}
      </Context.Consumer>
    </Provider>
  );
};

const StateProvider = ({ children }: any) => {
  return recurcy(Object.keys(map), values => {
    return <StateContext.Provider value={values}>{children}</StateContext.Provider>;
  });
};

export default StateProvider;
