import { Chat as ChatIcon, Check, Delete, Error as ErrorIcon, Warning } from '@mui/icons-material';
import { Chip, CircularProgress, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { deepOrange, green, red } from '@mui/material/colors';
import React from 'react';
import { ICallbacks, IChat, IResponse } from '../../../../interfaces/outstaff';
import Managment from './Managment';

const MAX_COUNT_GROUPS = 5;

const Subscriber = ({
  uid,
  name,
  groups,
  response,
  loading,
  addGroupToItem,
  createAndAddGroupToItem,
  getGroups,
  removeGroupFromItem,
}: IChat & ICallbacks & { response?: IResponse; loading: boolean }) => {
  const grps = groups.slice(0, MAX_COUNT_GROUPS).map(({ id, name }) => {
    const onDelete = async () => {
      try {
        await removeGroupFromItem(uid, id);
      } catch (e) {
        // do nothing
      }
    };
    const shortName = name.length > 10 ? `${name.substr(0, 10)}...` : name;
    return <Chip key={id} color="primary" sx={{ mr: 1 }} disabled={loading} onDelete={onDelete} deleteIcon={<Delete />} label={shortName} size="small" />;
  });
  const title = (
    <span>
      {name || uid} {grps}
      <Managment
        uid={uid}
        disabled={loading}
        groups={groups}
        showMore={groups.length > MAX_COUNT_GROUPS}
        getGroups={getGroups}
        addGroupToItem={addGroupToItem}
        removeGroupFromItem={removeGroupFromItem}
        createAndAddGroupToItem={createAndAddGroupToItem}
      />
    </span>
  );
  let status = 'Ready';
  let color: string | undefined = undefined;
  let icon = null;
  if (loading) {
    status = 'Loading...';
    icon = <CircularProgress />;
  } else if (response && !loading) {
    if (response.success.includes(uid)) {
      color = green[900];
      status = 'Sent';
      icon = <Check />;
    } else if (response.error.includes(uid)) {
      color = red[900];
      status = 'Error';
      icon = <ErrorIcon />;
    } else if (response.unsubscribed.includes(uid)) {
      color = deepOrange[900];
      status = 'Unsubscribed';
      icon = <Warning />;
    }
  }
  return (
    <ListItem secondaryAction={icon}>
      <ListItemIcon>
        <ChatIcon />
      </ListItemIcon>
      <ListItemText primary={title} secondary={<span style={{ color }}>{status}</span>} />
    </ListItem>
  );
};
export default Subscriber;
