import { People } from '@mui/icons-material';
import React from 'react';
import IRouteItems from '../../interfaces/routing';
import DevFeedback from '../../pages/DevFeedback';
import Home from '../../pages/Home';

const items: IRouteItems = {
  '': {
    title: 'Home',
    icon: <People />,
    component: Home,
    menu: false,
  },
  feedback: {
    title: 'Feedback & Bug report',
    icon: <People />,
    component: DevFeedback,
    roles: [],
    menu: false,
  },
};

export default items;
