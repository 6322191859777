import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormHelperText, TextField } from '@mui/material';
import { format } from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import React from 'react';
import { IFieldProps, IProp, IString } from './interfaces';

export const DateInput = ({ id, disabled, editedRaw, field, error, value }: IFieldProps) => {
  const string = field as IString & IProp;
  const val = (value as string | undefined) || '';
  const dateTimeRenderInput = (params: any) => <TextField {...params} id="sendAfter" error={!!error} />;
  const setDate = (date: Date | null) => editedRaw(id, date && !isNaN(date.getTime()) ? format(date, 'yyyy-MM-dd') : '');
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DatePicker label={string.title} disabled={disabled} value={new Date(val) || null} onChange={setDate} renderInput={dateTimeRenderInput} />
      </LocalizationProvider>
      <FormHelperText>{error}</FormHelperText>
    </>
  );
};
