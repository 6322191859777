import React from 'react';

class Background extends React.Component<{ children?: any }> {
  render() {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: '100vh',
          zIndex: -1,
          background: 'linear-gradient(322deg, #998033, #023131, #021931, #00050a)',
        }}
      />
    );
  }
}

export default Background;
