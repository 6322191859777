import { Divider, FormControl, TextField } from '@mui/material';
import React from 'react';
import Copy from '../../../components/elements/Copy';
import Heading from '../../../components/elements/typography/Heading';
import './index.scss';

class CreateUser extends React.Component<any> {
  render() {
    const onChange = () => {
      // do nothing
    };
    return (
      <>
        <Heading>Instructions</Heading>
        <p>This message will be sent automatically on a specific event. The type of event is determined by the value of the Category field.</p>
        <ul>
          <li>
            <b>HAPPYBIRTHDAY</b> - will be sent on the employee's birthday (specified in the Statistics table)
          </li>
          <li>
            <b>ANNIVERSARY</b> - will be sent on the day of the anniversary of work in the company, starting from the first, if there is no special message for
            a specific anniversary.
          </li>
          <li>
            <b>ANNIVERSARY_1</b> - will be sent only on the first anniversary
          </li>
          <li>
            <b>ANNIVERSARY_2</b> - will be parsed only in the second and so on.
          </li>
        </ul>
        <p>Different categories have their own template fields:</p>
        <ul>
          <li>
            <Copy label="<%- name %>" /> - full name of the employee
          </li>
          <li>
            <Copy label="<%- age %>" /> - full age of the employee (only for <b>HAPPYBIRTHDAY</b>)
          </li>
          <li>
            <Copy label="<%- dob %>" /> - date of birth in dd.MMMM format (for example, April 10) (only for <b>HAPPYBIRTHDAY</b>)
          </li>
          <li>
            <Copy label="<%- start %>" /> - the time of starting work with the company in the format dd.MM.yyyy (for example, 01/01/1970) (only for{' '}
            <b>ANNIVERSARY</b>)
          </li>
          <li>
            <Copy label="<%- anniversary %>" /> - employee anniversary (only for <b>ANNIVERSARY</b>)
          </li>
        </ul>
        <Divider />
        <p>
          You can add a link to the picture in the <b>Image URL</b> input. Please note that the link should lead to a reliable source, and the image size should
          not exceed 2MB
        </p>
        <Divider />
        <p>
          The message fully supports markup from Slack, for example, <b>*make text bold*</b>, <i>_italic_</i>, insert emoji :facepalm:
        </p>
        <Divider />
        <p>Example:</p>
        <FormControl fullWidth={true}>
          <TextField
            value={"Hello, <%- name %>\nWe congrats you to <%- anniversary %> anniversary in company. You've worked since <%- start %>"}
            onChange={onChange}
            multiline={true}
          />
        </FormControl>
      </>
    );
  }
}

export default CreateUser;
