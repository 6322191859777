import React from 'react';
import pic from './honeybadger2.gif';

class Honeybadger2 extends React.Component {
  render() {
    return (
      <>
        <img alt="Does not cares" src={pic} />
        <p>Honey badger would look for what you needed, but he doesn't care</p>
      </>
    );
  }
}
export default Honeybadger2;
