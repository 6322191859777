import { IData } from '../../interfaces/api';
import { ICV, ICVShort } from '../../interfaces/cv';
import request from '../request';

const cvApi = request('cvs');

export const getById = async (id: number): Promise<ICV> => {
  const result = await cvApi(`${id}`, 'get');
  return result;
};

export const exportById = async (id: number): Promise<{ link: string }> => {
  const { link } = await cvApi(`${id}/export`, 'get');
  return { link };
};

export const viewById = async (id: number): Promise<{ link: string }> => {
  const { link } = await cvApi(`${id}/view`, 'get');
  return { link };
};

export const get = async (): Promise<ICV[]> => {
  const result = await cvApi();
  return result;
};

export const create = async (message: ICVShort): Promise<{ link: string }> => {
  const data: IData = message as any;
  const { link } = await cvApi('', 'post', undefined, data);
  return { link };
};

export const updateById = async (id: number, message: ICVShort): Promise<{ link: string }> => {
  const data: IData = message as any;
  const { link } = await cvApi(`${id}`, 'patch', undefined, data);
  return { link };
};

export const deleteById = async (id: number): Promise<void> => {
  await cvApi(`${id}`, 'delete');
};
