import { IconButton as MuiIconButton, IconButtonProps } from '@mui/material';
import React from 'react';
import { goTo } from '../../../stores/navigation';
import Tooltip from '../Tooltip';

interface IProps extends IconButtonProps {
  href?: string;
  tooltip?: React.ReactNode;
}

class IconButton extends React.Component<IProps, any> {
  render() {
    let onClick;
    if (!this.props.disabled) {
      if (this.props.onClick) {
        onClick = this.props.onClick;
      } else if (this.props.href !== undefined) {
        onClick = (e: any) => {
          if (this.props.href !== undefined) {
            e.preventDefault();
            goTo(this.props.href);
          }
        };
      }
    } else {
      onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
      };
    }
    const button = <MuiIconButton {...this.props} component={this.props.disabled ? 'div' : 'button'} onClick={onClick} />;
    if (this.props.tooltip) {
      return (
        <Tooltip style={{ pointerEvents: 'auto' }} title={this.props.tooltip}>
          {button}
        </Tooltip>
      );
    }
    return button;
  }
}

export default IconButton;
