import { CircularProgress } from '@mui/material';
import React, { Component } from 'react';

const information = [
  'Wait for a moment',
  'We read data from Recruiterbox',
  'We are looking for a new offer in Recruiterbox data',
  'We download data to google drive',
];
const importantInformation = [
  'We look out the window',
  'Your personal data has been successfully shared',
  'Checking your bank account',
  'We mine bitcoins',
  "Nice weather, isn't it?",
  'We start teleporting goats',
  'Just a couple more seconds',
  'Recruiterbox is so slow, really...',
  "Did you know that cake doesn't exist?",
];

class PleaseWait extends Component {
  state: { phrase: string | null } = { phrase: null };

  private toPhrase: any;

  private _setPhrase(phrase: string) {
    this.setState({ phrase });
  }

  private _numHistory: number[] = [];

  private _getRandomNum(): number {
    const num = Math.floor(Math.random() * importantInformation.length);
    if (this._numHistory.includes(num)) {
      return this._getRandomNum();
    }
    if (this._numHistory.length > importantInformation.length * 0.3) {
      this._numHistory.shift();
    }
    this._numHistory.push(num);
    return num;
  }

  private _changePhrase(i: number = 0) {
    if (i < information.length) {
      this._setPhrase(information[i]);
    } else {
      const num = this._getRandomNum();
      this._setPhrase(importantInformation[num]);
    }
    this.toPhrase = setTimeout(() => this._changePhrase(i + 1), 2500 + Math.random() * 1000);
  }

  componentDidMount() {
    this._changePhrase();
  }

  componentWillUnmount() {
    clearTimeout(this.toPhrase);
  }

  render() {
    return (
      <>
        <CircularProgress color="inherit" />
        <div>Please wait</div>
        <div>{this.state.phrase}</div>
      </>
    );
  }
}
export default PleaseWait;
