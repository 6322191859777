import { observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router';
import { getOfferLink } from '../../apis/recruiterbox';
import { Context } from '../../stores/navigation';
import { finish, start } from '../../stores/progress';
import Completed from './partials/Completed';
import PleaseWait from './partials/PleaseWait';

import './index.scss';

interface IState {
  link: string | null;
  error: string | null;
  tmpLink: string | null;
  completed: boolean;
}

@observer
class File extends React.Component<any, IState> {
  state: IState = {
    link: null,
    error: null,
    tmpLink: null,
    completed: false,
  };
  static contextType = Context;
  interval: any;

  toFakeSteps: any;
  fakeSteps = 10;

  private _processFakeSteps() {
    this.toFakeSteps = setTimeout(() => {
      if (this.fakeSteps > 0) {
        finish(1);
        this.fakeSteps -= 1;
        if (this.fakeSteps > 0) {
          this._processFakeSteps();
        }
      }
    }, Math.random() * 1000 + 2000);
  }

  async componentDidMount() {
    const id = this.context.options.params.id;
    start(this.fakeSteps);
    try {
      this._processFakeSteps();
      const { link, tmpLink, error } = await getOfferLink(id);
      this.setState({ link, tmpLink, error, completed: true });
    } catch (e: any) {
      this.setState({ error: e.message, completed: true });
    } finally {
      clearTimeout(this.toFakeSteps);
      finish(this.fakeSteps);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.toFakeSteps);
    finish(this.fakeSteps);
  }

  render() {
    return (
      <div className="File">
        <div className="content">
          {this.state.completed ? <Completed link={this.state.link} tmpLink={this.state.tmpLink} error={this.state.error} /> : <PleaseWait />}
        </div>
      </div>
    );
  }
}

export default withRouter(File);
