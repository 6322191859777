import { Box, Paper } from '@mui/material';
import React from 'react';
import { setFullscreen } from '../../../stores/navigation';
import Container from '../../elements/Container';
import Footer from './Footer';
import Header from './Header';
import Menu from './Menu';

export const Partial = ({ children }: any) => {
  setFullscreen(false);
  return (
    <Box id="content" className="partial">
      <Header />
      <Menu />
      <Container className="content-container">
        <Paper className="partial-paper content-container" square={true}>
          <Box px={4} py={2}>
            <main>{children}</main>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
};

export default Partial;
