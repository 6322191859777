import React, { Component } from 'react';
import Button from '../../../components/elements/Button';
import TryAgain from '../../../components/elements/TryAgain';

class Completed extends Component<{ link: string | null; error: string | null; tmpLink: string | null }> {
  private toRedirect: any;
  componentWillUnmount() {
    clearTimeout(this.toRedirect);
  }

  componentDidMount() {
    clearTimeout(this.toRedirect);
    if (!this.props.error && this.props.link) {
      this.toRedirect = setTimeout(() => {
        if (this.props.link) {
          window.location.href = this.props.link;
        }
      }, 2000);
    }
  }

  render() {
    if (this.props.error) {
      if (this.props.tmpLink) {
        return (
          <>
            <div>Something went wrong</div>
            <div>
              But we managed to get a <b>TEMPORARY</b> link
            </div>
            <Button variant="contained" color="secondary" href={this.props.tmpLink}>
              Click here
            </Button>
            <div>But remember, this link will expire soon</div>
            <div>Error text: {this.props.error}</div>
          </>
        );
      }

      return (
        <>
          <div>Something went wrong</div>
          <div>We tried it, but it didn't work</div>
          <div>Error text: {this.props.error}</div>
          <TryAgain />
        </>
      );
    }

    return <>Finally, we get a link to the file, one more moment, and you will be redirected to the file</>;
  }
}
export default Completed;
