import React from 'react';
import Article from '../../components/Article';

export { default as Secondary } from './Secondary';

class Training extends React.Component {
  render() {
    return <Article alias="training" />;
  }
}

export default Training;
