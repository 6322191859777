import { Close, EventNote, FormatListBulleted, Update } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { UsedDetailsType } from '../../interfaces/user';
import Preloader from '../Preloader';

interface IState {
  isLoading: boolean;
  isLoaded: boolean;
  details?: UsedDetailsType;
  error?: React.ReactNode;
}
class UsedDetails extends React.Component<{ getDetails: () => Promise<UsedDetailsType>; details?: string; sx?: SxProps }, IState> {
  state: IState = { isLoaded: false, isLoading: false };

  private async _loadDetails() {
    if (!this.props.getDetails) {
      return;
    }
    try {
      await this.setState({ isLoading: true, isLoaded: false });
      const all = await this.props.getDetails();
      const details = all.filter(({ items }) => items.filter(({ value }) => value && parseFloat(value.toString()) !== 0).length > 0);
      await this.setState({ isLoading: false, isLoaded: true, details, error: undefined });
    } catch (e) {
      this.setState({ isLoading: false, isLoaded: true, error: (e as Error).message });
    }
  }

  private async _moveDetails() {
    this.setState({ isLoading: false, isLoaded: false, details: undefined });
  }

  render() {
    const loadDetails = () => this._loadDetails();
    const moveDetails = () => this._moveDetails();
    return (
      <Box className="used-details" sx={this.props.sx}>
        <Button size="small" onClick={loadDetails} variant="outlined" startIcon={this.state.isLoaded ? <Update /> : <FormatListBulleted />}>
          {this.state.isLoaded ? 'Reload ' : 'Get '} {this.props.details || 'Details'}
        </Button>
        <Preloader sx={{ mt: 3 }} isLoading={this.state.isLoading} fallback={this.state.error && <Box sx={{ my: 2 }}>Error: {this.state.error}</Box>}>
          <List>
            {this.state.details && this.state.details.length ? (
              <>
                {this.state.details.map(({ title, items }, i) => (
                  <>
                    {i > 0 && <Divider variant="inset" component="li" />}
                    <ListItem alignItems="flex-start" key={`${i}-${title}`}>
                      <ListItemAvatar>
                        <Avatar>
                          <EventNote />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={title}
                        secondary={items.map(({ title, value }) => (
                          <div key={title}>
                            <Typography component="span" variant="body2">
                              {title}:{' '}
                            </Typography>
                            {value && isNaN(parseFloat(value.toString())) ? (
                              value
                            ) : (
                              <>
                                {parseFloat((value || 0).toString())} {value && value === 1 ? 'hour' : 'hours'}
                              </>
                            )}
                          </div>
                        ))}
                      />
                    </ListItem>
                  </>
                ))}
                <Button size="small" onClick={moveDetails} variant="outlined" startIcon={<Close />}>
                  Hide details
                </Button>
              </>
            ) : (
              this.state.isLoaded && <Box sx={{ mt: 2 }}>No details to show</Box>
            )}
          </List>
        </Preloader>
      </Box>
    );
  }
}

export default UsedDetails;
