import { Home, KeyboardArrowUp, Menu as MenuIcon } from '@mui/icons-material';
import { Box, Grid, Paper } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Context as MenuContext } from '../../../../stores/navigation';
import Button from '../../../elements/Button';
import Container from '../../../elements/Container';
import Navigation from '../../../Navigation';

@observer
class Menu extends React.Component<any> {
  static contextType = MenuContext;

  state: { collapsed: boolean } = { collapsed: true };

  render() {
    if (this.context.fullscreen || this.context.active === '/') {
      if (!this.state.collapsed) {
        this.setState({ collapsed: true });
      }
      return null;
    }
    const toggleCollapse = () => {
      this.setState({ collapsed: !this.state.collapsed });
    };

    return (
      <>
        <Paper square={true} sx={{ background: '#ccc', borderBottom: 1, borderColor: 'divider' }} elevation={0}>
          <Container>
            <Box sx={{ py: 1 }}>
              <Grid container={true}>
                <Grid item={true} xs={true}>
                  <Button href="/" variant="contained" size="small" startIcon={<Home />}>
                    To home
                  </Button>
                </Grid>
                <Grid item={true} xs={true} sx={{ textAlign: 'right' }}>
                  <Button variant="outlined" size="small" onClick={toggleCollapse} startIcon={this.state.collapsed ? <MenuIcon /> : <KeyboardArrowUp />}>
                    {this.state.collapsed ? 'Show menu' : 'Hide Menu'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
        {!this.state.collapsed && <Navigation />}
      </>
    );
  }
}

export default Menu;
