import { Add, Delete, Edit, Update, Upgrade } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { deleteById, exportById, get } from '../../../apis/cv';
import ContentTable, { ContentTableColsType } from '../../../components/ContentTable';
import Button from '../../../components/elements/Button';
import IconButton from '../../../components/elements/IconButton';
import Heading from '../../../components/elements/typography/Heading';
import Preloader from '../../../components/Preloader';
import { IMessage } from '../../../interfaces/message';
import { show } from '../../../stores/alert';
import { add, remove } from '../../../stores/float-buttons';
import { open } from '../../../stores/modal';
import { Modal } from '../elements/Modal';
import './index.scss';

class List extends React.Component<any> {
  state: {
    cvs: IMessage[];
    isLoading: boolean;
    isWaiting: boolean;
  } = { cvs: [], isLoading: true, isWaiting: false };

  // constructor(props: any) {
  //   super(props);
  // }

  private async _loadMessages() {
    try {
      await this.setState({ isLoading: true });
      const cvs = await get();
      this.setState({ cvs, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  btnKey?: any;

  componentDidMount() {
    this.btnKey = add({ title: 'Create new CV', icon: <Add />, href: '/outstaff/cv/create' });
    this._loadMessages();
  }

  componentWillUnmount() {
    if (this.btnKey) {
      remove(this.btnKey);
    }
  }

  render() {
    const reloadMessages = () => this._loadMessages();
    const makeExportCb = (id: number) => async () => {
      await this.setState({ isWaiting: true });
      try {
        const { link } = await exportById(id);
        open(() => <Modal link={link} />);
        // show('CV was successfully exported as Google Document');
      } catch (e) {
        //
      }
      await this.setState({ isWaiting: false });
    };
    const makeDeleteCb = (id: number) => async () => {
      await this.setState({ isWaiting: true });
      try {
        await deleteById(id);
        show('CV was successfully deleted');
        await reloadMessages();
      } catch (e) {
        //
      }
      await this.setState({ isWaiting: false });
    };

    const reloadButton = (
      <Button disabled={this.state.isLoading || this.state.isWaiting} size="small" onClick={reloadMessages} variant="outlined" startIcon={<Update />}>
        Reload list
      </Button>
    );
    const cols: ContentTableColsType = [
      { title: 'ID', getValue: ({ id }) => id },
      { title: 'Name', getValue: ({ general: { firstName, lastName } }) => `${firstName} ${lastName}` },
      { title: 'Position', getValue: ({ general: { position } }) => position },
      { title: 'Experience', getValue: ({ summary: { experience } }) => experience },
      { title: 'Stack', getValue: ({ summary: { stack } }) => stack },
      {
        title: 'Comment',
        getValue: ({ comment }) => (
          // comment && comment.length > 20 ? (
          //   <>
          //     {comment.substr(0, 13)}...{' '}
          //     <IconButton tooltip={<Box sx={{ whiteSpace: 'pre-wrap' }}>{comment}</Box>}>
          //       <Info />
          //     </IconButton>
          //   </>
          // ) : (
          <Box sx={{ whiteSpace: 'pre-wrap' }}>{comment}</Box>
        ),
        // ),
      },
      {
        title: '',
        textAlign: 'right',
        getValue: ({ id }) => (
          <div style={{ whiteSpace: 'nowrap' }}>
            <IconButton disabled={this.state.isWaiting} onClick={makeExportCb(id)} tooltip="Export as document">
              <Upgrade />
            </IconButton>
            <IconButton disabled={this.state.isWaiting} href={`/outstaff/cv/edit/${id}`} tooltip="Edit">
              <Edit />
            </IconButton>
            <IconButton disabled={this.state.isWaiting} onClick={makeDeleteCb(id)} tooltip="Delete">
              <Delete />
            </IconButton>
          </div>
        ),
      },
    ];
    return (
      <>
        <Heading>CV list</Heading>
        <Preloader
          isLoading={this.state.isLoading}
          fallback={
            this.state.cvs.length === 0 && (
              <>
                <p>There are no messages</p>
                {reloadButton}
              </>
            )
          }
        >
          {this.state.cvs.length > 5 && reloadButton}
          <ContentTable cols={cols} data={this.state.cvs} />
          {reloadButton}
        </Preloader>
      </>
    );
  }
}

export default List;
