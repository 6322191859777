import { Divider } from '@mui/material';
import React from 'react';
import Article from '../../components/Article';

export { default as Secondary } from './Secondary';

class Vacation extends React.Component {
  render() {
    return (
      <>
        <Article alias="vacation" />
        <Divider />
        <Article alias="extraVacation" />
      </>
    );
  }
}

export default Vacation;
