import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';
import { ERoles } from '../../constants/user';
import IRouteItems, { MenuItemType, RouteItemType } from '../../interfaces/routing';
import { ComponentType } from '../../interfaces/system';
import IUser from '../../interfaces/user';
import { topMenu as routes } from '../../routes';
import { StateContext } from '../../stores';
import acl from '../../utils/acl';
import Container from '../elements/Container';
import MenuItem from '../elements/MenuItem';

type RouteType = RouteItemType & {
  isActive: boolean;
  path: string;
  children: RouteType[];
  hasComponent: boolean;
  isDisabled: boolean;
};

interface IState {
  id: string | null;
  activePath: string | null;
}

const makeUri = (path: string[]) => {
  return `/${path.join('/')}`.replace(/\/{2,}/g, '/').replace(/([^^])\/$/g, '$1');
};

const buildMenu = (user: IUser, active: string, items: IRouteItems, parentIds: string[] = []): { build: RouteType[]; activePath: string | null } => {
  const build: RouteType[] = [];
  let activePath: string | null = null;
  for (const id in items) {
    const item = items[id];
    const allowedAcl = acl(item.roles, user);
    const skipAcl = !allowedAcl && item.roles && (item.roles.length === 0 || item.roles.includes(ERoles.NINJA));
    if (item.menu !== false && (skipAcl || allowedAcl)) {
      const ids = [...(parentIds || []), id];
      const { build: children, activePath: activeChildrenPath } = buildMenu(user, active, item.items || {}, ids);
      const hasComponent = item.hasOwnProperty('component') || item.hasOwnProperty('redirect');
      if (children.length || hasComponent || skipAcl || item.disabled) {
        const path = makeUri(ids);
        const isActive = !!activeChildrenPath || path === active;

        activePath = isActive ? path : activePath;
        const isDisabled = skipAcl || item.disabled || false;
        build.push({ ...item, hasComponent, isDisabled, children, path, isActive });
      }
    }
  }
  return { build, activePath };
};

const Item = ({ item }: { item: RouteType }) => {
  const disabled = !item.hasComponent || item.isDisabled;
  const menu = item as MenuItemType;
  return (
    <Grid item={true} xs={12} sm={12} md={6} lg={6}>
      <MenuItem
        href={!disabled ? item.path : undefined}
        disabled={disabled}
        icon={menu.icon}
        title={menu.title}
        description={menu.description}
        sx={{ p: 1, borderTop: 1, borderColor: 'divider' }}
      />
    </Grid>
  );

  // return (
  //     <Divider />
  //     <Typography variant="h6" sx={{ px: 2, py: 1 }}>
  //       {item.component ? <Link href={item.path}>{item.title}</Link> : <span>{item.title}</span>}
  //     </Typography>
  // );
};

@observer
class Navigation extends React.Component<{ sx?: SxProps; firstExpandedByDefault?: boolean }, IState> {
  static contextType = StateContext;
  state: IState = { id: null, activePath: null };

  render() {
    const handleChange = (e: any, id: string) => {
      this.setState({ id });
    };
    const {
      auth: { user },
      navigation: { active },
    } = this.context;

    const { build: menuItems, activePath } = buildMenu(user, active, routes);

    const tabs: ComponentType[] = [];
    const items: ComponentType[] = [];
    let menu: ComponentType | null = null;

    let activeId = this.state.id;
    if (activeId === null) {
      activeId = this.props.firstExpandedByDefault && menuItems[0] ? menuItems[0].path : activePath;
    }

    for (const tab of menuItems) {
      const menuTab = tab as MenuItemType;
      tabs.push(<Tab label={tab.title} id={tab.path} key={tab.path} value={tab.path} />);
      if (activeId === tab.path) {
        const description =
          menuTab.description && !tab.hasComponent ? (
            <Typography variant="subtitle1" sx={{ p: 2 }}>
              {menuTab.description}
            </Typography>
          ) : null;
        if (tab.hasComponent) {
          items.push(<Item item={tab} key={tab.path} />);
        }
        const list = tab.children;
        for (const item of list) {
          items.push(<Item item={item} key={item.path} />);
        }
        menu = (
          <div>
            {description}
            <Grid container={true} spacing={2}>
              {items}
            </Grid>
          </div>
        );
      }
    }

    return (
      <Box sx={this.props.sx}>
        <Paper square={true} sx={{ background: '#ddd', borderBottom: 1, borderColor: 'divider' }} elevation={0}>
          <Container>
            <Box>
              <Tabs value={activeId} onChange={handleChange}>
                {tabs}
              </Tabs>
            </Box>
          </Container>
        </Paper>
        <Paper square={true} sx={{ background: '#eee', borderBottom: 1, borderColor: 'divider' }} elevation={0}>
          <Container sx={{ overflow: 'hidden', pb: 0 }}>{menu}</Container>
        </Paper>
      </Box>
    );
  }
}

export default Navigation;
