import { Box } from '@mui/material';
import React from 'react';
import { setFullscreen } from '../../../stores/navigation';
import Logo from '../../elements/Logo';

const Full = ({ hideLogo, children }: { hideLogo?: boolean; children: React.ReactNode }) => {
  setFullscreen(true);
  return (
    <Box id="content" className="fullscreen">
      {!hideLogo && (
        <Box className="logo-wrapper">
          <Logo variant="white" />
        </Box>
      )}
      <main className="content-container">{children}</main>
    </Box>
  );
};

export default Full;
