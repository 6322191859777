import { Divider } from '@mui/material';
import React from 'react';
import Heading from '../../../components/elements/typography/Heading';
import './index.scss';

class CreateUser extends React.Component<any> {
  render() {
    return (
      <>
        <Heading>Instructions</Heading>
        <p>
          This form is for simplified creating of new Google accounts for new team members and for outstaffers/outsourcers. Follow steps below to get profit
        </p>
        <ul>
          <li>
            <b>Is team member</b> checkbox - check if the new account is a member of the team, uncheck the box if the new account is an outsourcer, outstaffer,
            etc. This will affect the name of the proposed email (<em>.k</em>, before the <em>@</em>) and the groups to which the account will be added
          </li>
          <li>
            <b>First name</b> and <b>Last name</b> inputs - please make sure that these fields are filled in correctly, so that there are no typos, and that the
            transliteration (but not translation) is correct in accordance with the rules of the respective country of account. Can contain only{' '}
            <b>Latin letters</b>, <b>numbers</b> and <b>spaces</b>
          </li>
          <li>
            <b>Email</b> input - email name. Please note that <em>@</em> and domain must be excluded from the input value as it will be added automatically. And
            also a special suffix <em>.k</em> for outsourcing/outstaff accounts. May contain only <b>Latin letters</b>
          </li>
          <li>
            <b>Suggestions</b> chips - after you check/uncheck the box and also fill in the first and last name, the application will present you with the
            available options for the email name. The options are made according to the rules - the first letter of the name and surname. If there are no free
            options, one more letter is added to the name, then to the surname. If letters end, random letters will be added to the name.
          </li>
        </ul>
        <p>
          After you filled all inputs and clicked <em>Create</em> button, you'll get info of created account - email and password
        </p>
        <Divider />
        <p>
          Please note, in some cases, it may not be possible to create a user. For example, if the quota in the company's tariff plan has expired. Report the
          error text to the responsible employee
        </p>
      </>
    );
  }
}

export default CreateUser;
