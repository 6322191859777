import { MenuProps } from '@mui/material';
import { makeAutoObservable } from 'mobx';
import React, { Component, createContext, FC } from 'react';

class Menu {
  public component?: FC | typeof Component;
  public anchorEl?: MenuProps['anchorEl'];
  public props?: MenuProps;
  public handleClose?: () => any;

  constructor() {
    makeAutoObservable(this);
  }

  public open(anchorEl: MenuProps['anchorEl'], component: FC | typeof Component, props?: MenuProps, handleClose?: () => any) {
    this.anchorEl = anchorEl;
    this.component = component;
    this.props = props;
    this.handleClose = handleClose;
  }

  public close() {
    this.anchorEl = undefined;
    this.component = undefined;
    this.props = undefined;
    this.handleClose = undefined;
  }
}

const menu = new Menu();

export const open = (anchorEl: MenuProps['anchorEl'], component: FC | typeof Component, props?: MenuProps, handleClose?: () => any) =>
  menu.open(anchorEl, component, props, handleClose);
export const close = () => menu.close();

export const Context = createContext<Menu>(menu);
const Provider = ({ children }: any) => <Context.Provider value={menu}>{children}</Context.Provider>;

export default Provider;
