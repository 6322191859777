import { Divider } from '@mui/material';
import React from 'react';
import { getMyVacation, getMyVacationUsed } from '../../apis/user';
import CommunicationForm from '../../components/CommunicationForm';
import Remaining from '../../components/Remaining';

class Secondary extends React.Component {
  render() {
    const getRemaining = () => getMyVacation();
    const getUsed = () => getMyVacationUsed();
    return (
      <>
        <Remaining getRemaining={getRemaining} getDetails={getUsed} description="Your Personal Remaining Vacation:" details="details of the vacation used" />
        <Divider sx={{ my: 2 }} />
        <CommunicationForm title="Leave a vacation request or question" category="vacation" />,
      </>
    );
  }
}

export default Secondary;
