import { Box, CircularProgress, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import Full from '../Routing/Full';

const Preloader = ({
  isLoading,
  isFull,
  hideLogo,
  component: Component,
  children,
  fallback,
  sx,
}: {
  isLoading: boolean;
  isFull?: boolean;
  hideLogo?: boolean;
  component?: React.FC;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
  sx?: SxProps;
}) => {
  if (isLoading || fallback) {
    const content =
      !isLoading && fallback ? (
        <>{fallback}</>
      ) : (
        <Box sx={sx} className="preloading" style={{ textAlign: 'center' }}>
          {Component ? (
            <Component />
          ) : (
            <>
              <CircularProgress color="inherit" />
              <Typography component="h6" variant="h6" sx={{ mb: 1, my: 2 }}>
                Please wait
              </Typography>
            </>
          )}
        </Box>
      );
    if (isFull) {
      return <Full hideLogo={hideLogo}>{content}</Full>;
    }
    return content;
  }
  return <>{children || null}</>;
};

export default Preloader;
