import { Sick } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import React from 'react';
import { getMySickLeave, getMySickLeaveUsed } from '../../apis/user';
import CommunicationForm from '../../components/CommunicationForm';
import Remaining from '../../components/Remaining';

class SickLeave extends React.Component {
  render() {
    const getRemaining = () => getMySickLeave();
    const getUsed = () => getMySickLeaveUsed();
    return (
      <>
        <Button disabled={true} fullWidth={true} color="primary" variant="contained" size="large" startIcon={<Sick />}>
          Report your #sick leave
        </Button>
        <Divider sx={{ my: 2 }} />
        <Remaining getRemaining={getRemaining} getDetails={getUsed} description="Your Personal Remaining Sick Leave:" details="details of used sick leave" />
        <Divider sx={{ my: 2 }} />
        <CommunicationForm title="Leave a question" category="sickLeave" />
      </>
    );
  }
}

export default SickLeave;
