import { Article, Business, BusinessCenter, Contacts, Work } from '@mui/icons-material';
import React from 'react';
import Charity from '../../components/elements/icons/Charity';
import IRouteItems from '../../interfaces/routing';
import Digest, { Secondary as DigestSecondary } from '../../pages/Digest';
import DigestItem from '../../pages/Digest/Item';
import Vacancies, { Secondary as VacanciesSecondary } from '../../pages/Vacancies';

const items: IRouteItems = {
  company: {
    title: 'My company',
    icon: <Business />,
    description: 'Info about your favorite company',
    items: {
      office: {
        title: 'Office',
        description: 'About office',
        icon: <BusinessCenter />,
        roles: [],
        disabled: true,
      },
      contacts: {
        title: 'Contacts',
        description: 'List of HRs etc.',
        icon: <Contacts />,
        roles: [],
        disabled: true,
      },
      vacancies: {
        title: 'Vacancies',
        description: 'Current opened vacancies and referral program',
        icon: <Work />,
        roles: [],
        component: Vacancies,
        secondary: VacanciesSecondary,
        // disabled: true,
      },
      digest: {
        title: 'Digest',
        description: "Company's news",
        icon: <Article />,
        roles: [],
        component: Digest,
        secondary: DigestSecondary,
      },
      'digest/:id': {
        title: 'Digest',
        roles: [],
        component: DigestItem,
        menu: false,
      },
      charity: {
        title: 'Charity',
        icon: <Charity />,
        roles: [],
        disabled: true,
      },
    },
  },
};

export default items;
