import React from 'react';
import Columns from '../../../components/Routing/Columns';
import Tabs from '../../../components/Tabs';
import { IBot, IChat, IGroup, IResponse, IScheduled } from '../../../interfaces/outstaff';
import Form from './Form';
import Instruction from './Instruction';
import ScheduledList from './ScheduledList';

const Outstaff = ({
  title,
  assignChatToGroup,
  unassignChatToGroup,
  createChatGroup,
  getChatBotInfo,
  getChatGroups,
  getChats,
  sendToChats,
  createChatSheduled,
  getChatScheduleds,
  deleteChatScheduled,
  Instruction: InstructionComponent,
}: {
  title: string;
  assignChatToGroup: (chatId: number, groupId: number) => Promise<any>;
  unassignChatToGroup: (chatId: number, groupId: number) => Promise<any>;
  createChatGroup: (name: string) => Promise<any>;
  getChatBotInfo: () => Promise<IBot>;
  getChatGroups: () => Promise<IGroup[]>;
  getChats: (groupId?: number) => Promise<IChat[]>;
  sendToChats: (message: string, groupId?: number) => Promise<IResponse>;
  createChatSheduled: (message: string, sendAfter: Date, groupId?: number) => Promise<any>;
  getChatScheduleds: () => Promise<IScheduled[]>;
  deleteChatScheduled: (id: number) => Promise<any>;
  Instruction: React.FC<{ bot: IBot }>;
}) => {
  return (
    <Tabs
      tabs={[
        {
          title: 'Send message',
          content: () => (
            <Columns
              columns={[
                <Form
                  key="form"
                  title={title}
                  assignChatToGroup={assignChatToGroup}
                  createChatGroup={createChatGroup}
                  getChatGroups={getChatGroups}
                  getChats={getChats}
                  sendToChats={sendToChats}
                  unassignChatToGroup={unassignChatToGroup}
                  createChatSheduled={createChatSheduled}
                />,
                <Instruction key="instruction" component={InstructionComponent} getChatBotInfo={getChatBotInfo} />,
              ]}
            />
          ),
        },
        { title: 'Scheduled messages', content: () => <ScheduledList getList={getChatScheduleds} removeItem={deleteChatScheduled} /> },
      ]}
    />
  );
};
export default Outstaff;
