import { Add, MoreVert } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React from 'react';
import { IExtendedCreateCallbacks, IGroup, removeGroupFromItemType } from '../../../../interfaces/outstaff';
import { close, open } from '../../../../stores/menu';
import GroupMenu from './GroupMenu';

interface IProps extends IExtendedCreateCallbacks {
  uid: number;
  groups: IGroup[];
  disabled: boolean;
  showMore: boolean;
  removeGroupFromItem: removeGroupFromItemType;
}

class Managment extends React.Component<IProps> {
  render() {
    const openMenu = async (event: any) => {
      const allGroups = await this.props.getGroups();
      const component = () => (
        <GroupMenu
          uid={this.props.uid}
          currentGroups={this.props.groups}
          groups={allGroups}
          addGroupToItem={this.props.addGroupToItem}
          createAndAddGroupToItem={this.props.createAndAddGroupToItem}
          removeGroupFromItem={this.props.removeGroupFromItem}
          isDisabled={this.props.disabled}
          handleClose={close}
        />
      );
      open(event.target, component, undefined);
    };

    return (
      <>
        {this.props.showMore && <Chip icon={<MoreVert />} disabled={this.props.disabled} label="More" onClick={openMenu} sx={{ mr: 1 }} size="small" />}
        <Chip icon={<Add />} disabled={this.props.disabled} label="Add group" onClick={openMenu} size="small" />
      </>
    );
  }
}

export default Managment;
