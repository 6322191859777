import { EnumValues } from 'enum-values';

export enum ESpecificRoles {
  USER_MANAGER = 'USER_MANAGER',
  FINANCE = 'FINANCE',
  OUTSTAFFER = 'OUTSTAFFER',
  CONTENT = 'CONTENT',
}

export enum ERoles {
  NINJA = 'NINJA',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  HR = 'HR',
  KINZHAL = 'KINZHAL',
  LEAD_GEN = 'LEAD_GEN',
  PM = 'PM',
  SALE = 'SALE',
  ADMIN = 'ADMIN',
  GOD = 'GOD',

  USER_MANAGER = 'USER_MANAGER',
  FINANCE = 'FINANCE',
  OUTSTAFFER = 'OUTSTAFFER',
  CONTENT = 'CONTENT',
}

export const roles = EnumValues.getValues(ERoles);
export const specificRoles = EnumValues.getValues(ESpecificRoles);

export enum EOAuth {
  GOOGLE = 'google',
  SLACK = 'slack',
}
