import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

const Heading = ({ children, sx, small }: { children: any; small?: boolean; sx?: SxProps }) => {
  return (
    <Typography component="h4" variant={small ? 'h6' : 'h4'} sx={{ mb: 1, my: small ? 1 : 2, ...(sx || {}) }}>
      {children}
    </Typography>
  );
};

export default Heading;
