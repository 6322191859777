// import { Event } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
// import { format } from 'date-fns';
import React from 'react';
import striptags from 'striptags';
import { getVacancionsFeed } from '../../../apis/dou';
import Link from '../../../components/elements/Link';
import Heading from '../../../components/elements/typography/Heading';
import Preloader from '../../../components/Preloader';
// import { dateFormat } from '../../constants';

class Feed extends React.Component {
  state: { isLoading: boolean; vacancies: Array<{ title: string; pubDate: string; link: string; description: string }>; error: string | null } = {
    isLoading: true,
    vacancies: [],
    error: null,
  };
  async componentDidMount() {
    try {
      const { item: vacancies } = await getVacancionsFeed();
      this.setState({ isLoading: false, vacancies });
    } catch (e) {
      this.setState({ isLoading: false, error: (e as Error).message });
    }
  }
  render() {
    return (
      <>
        <Heading>Current vacancies</Heading>
        <Preloader isLoading={this.state.isLoading} fallback={this.state.error}>
          {this.state.vacancies.map(({ title, pubDate, link, description }, i) => {
            return (
              <Box key={link} sx={{ mb: 1 }}>
                {i > 0 && <Divider sx={{ mb: 1 }} />}
                <Typography variant="subtitle1">
                  <Link target="_blank" href={link}>
                    {title}
                  </Link>
                </Typography>
                <Typography component="div" variant="body2">
                  {/* <Chip label={format(new Date(pubDate), dateFormat)} size="small" icon={<Event />} /> */}
                  <span dangerouslySetInnerHTML={{ __html: striptags(description).substr(0, 256) }} />
                </Typography>
              </Box>
            );
          })}
        </Preloader>
      </>
    );
  }
}

export default Feed;
