import React from 'react';
import Heading from '../../../components/elements/typography/Heading';
import Preloader from '../../../components/Preloader';
import { IBot } from '../../../interfaces/outstaff';
import './Form.scss';

class Instruction extends React.Component<{
  getChatBotInfo: () => Promise<IBot>;
  component: React.FC<{ bot: IBot }>;
}> {
  state: {
    loading: boolean;
    bot?: IBot;
    error?: string;
  } = { loading: true };

  // constructor(props: any) {
  //   super(props);
  // }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const bot = await this.props.getChatBotInfo();
      this.setState({ loading: false, bot });
    } catch (e) {
      this.setState({ loading: false, error: (e as Error).message });
    }
  }

  render() {
    const InstructionComponent = this.props.component;
    return (
      <>
        <Heading>Instructions</Heading>
        <Preloader isLoading={this.state.loading} fallback={!this.state.bot && <>An error occurred while retrieving data</>}>
          {this.state.bot && <InstructionComponent bot={this.state.bot} />}
        </Preloader>
      </>
    );
  }
}
export default Instruction;
