import { Divider } from '@mui/material';
import React from 'react';
import Copy from '../../../components/elements/Copy';
import Link from '../../../components/elements/Link';

const Instruction = ({ bot }: { bot: { displayName: string; username: string } }) => {
  return (
    <div>
      <p>
        1. For the bot to send messages to a specific chat add bot with username <Copy label={bot.username} />
        (displayed name is <b>{bot.displayName}</b>) to the chats that you want to receive the newsletter.
      </p>
      <p>
        2. Immediately after adding, the bot should write to this chat something like <i>"Hello. Current chat successfully saved to subscription list"</i>. If
        the bot didn't write anything, try to write something to this chat.
      </p>
      <p>
        If the bot wrote a message to the chat, then the chat has been successfully added to the list of subscriptions and will receive messages that you can
        send using the form below
      </p>
      <p>
        If the bot did not write anything, then most likely something went wrong, <Link href="/feedback">contact the developer</Link>
      </p>
      <Divider />
      <p>Also you may subscribe to bot directly, in private chat. Just start conversation with bot and follow steps above</p>
      <Divider />
      <p>To prevent the bot from sending messages to a specific chat - remove it from this chat</p>
    </div>
  );
};

export default Instruction;
