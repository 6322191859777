import { Box, Divider } from '@mui/material';
import React from 'react';
import { getMyOvertime } from '../../apis/user';
import From from '../../components/CommunicationForm';
import Heading from '../../components/elements/typography/Heading';
import UsedDetails from '../../components/UsedDetails';

const Secondary = () => {
  return (
    <>
      <Box>
        <Heading>Your personal overtimes</Heading>
        <UsedDetails getDetails={getMyOvertime} />
      </Box>
      <Divider />
      <From title="Leave your question about overtimes" category="overtime" />
    </>
  );
};

export default Secondary;
