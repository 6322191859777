import React from 'react';
import Article from '../../components/Article';
export { default as Secondary } from './Secondary';

class Covid extends React.Component {
  render() {
    return (
      <>
        <Article alias="covid" />
      </>
    );
  }
}

export default Covid;
