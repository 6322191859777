import { Add, Delete, MoreVert } from '@mui/icons-material';
import { Chip, ListItem, ListItemText, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { assignRole, unassignRole } from '../../../apis/user';
import IconButton from '../../../components/elements/IconButton';
import { ERoles, ESpecificRoles, specificRoles } from '../../../constants/user';
import IUser from '../../../interfaces/user';
import { close, open } from '../../../stores/menu';
import convertRole from '../../../utils/convert-role';

interface IProps {
  user: IUser;
  reloadUsers: () => any;
  disabled?: boolean;
  sx?: SxProps;
}

interface IState {
  isLoading: boolean;
}

class Manage extends React.Component<IProps, IState> {
  state: IState = { isLoading: false };
  render() {
    const openMenu = (event: any) => {
      const getUnassign = (role: ESpecificRoles) => async () => {
        await this.setState({ isLoading: true });
        try {
          await unassignRole(user.id, role);
          await this.props.reloadUsers();
        } catch (e) {
          // do nothing
        }
        await this.setState({ isLoading: false });
        close();
      };
      const getAssign = (role: ESpecificRoles) => async () => {
        await this.setState({ isLoading: true });
        try {
          await assignRole(user.id, role);
          await this.props.reloadUsers();
        } catch (e) {
          // do nothing
        }
        await this.setState({ isLoading: false });
        close();
      };
      const { user } = this.props;
      open(event.currentTarget, () => (
        <>
          {(specificRoles as ESpecificRoles[]).map(role => (
            <ListItem disabled={this.state.isLoading || this.props.disabled} key={role}>
              <ListItemText sx={{ my: 0 }}>{convertRole(role)}</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {user.roles.includes(role as unknown as ERoles) ? (
                  <IconButton
                    sx={{ ml: 1, mt: -1.25, mb: -1 }}
                    disabled={this.state.isLoading || this.props.disabled}
                    edge="end"
                    size="small"
                    color="inherit"
                    onClick={getUnassign(role)}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ ml: 1, mt: -1.25, mb: -1 }}
                    disabled={this.state.isLoading || this.props.disabled}
                    edge="end"
                    size="small"
                    color="inherit"
                    onClick={getAssign(role)}
                  >
                    <Add fontSize="inherit" />
                  </IconButton>
                )}
              </Typography>
            </ListItem>
          ))}
        </>
      ));
    };
    return <Chip sx={this.props.sx} variant="outlined" color="primary" icon={<MoreVert />} label="Edit" onClick={openMenu} size="small" />;
  }
}

export default Manage;
