import { observer } from 'mobx-react';
import React from 'react';
import { ERoles } from '../../constants/user';
import { Context } from '../../stores/auth';
import acl from '../../utils/acl';

@observer
class ACL extends React.Component<{ roles: Array<ERoles | null>; children: React.ReactNode }> {
  static contextType = Context;

  render() {
    if (acl(this.props.roles, this.context.user)) {
      return this.props.children;
    }
    return null;
  }
}

export default ACL;
