import React from 'react';
import Link from '../../../components/elements/Link';
import Heading from '../../../components/elements/typography/Heading';
import './index.scss';

class CreateUser extends React.Component<any> {
  render() {
    return (
      <>
        <Heading>Instructions</Heading>
        <p>
          Messages are sent once a day. Messages are based on entries in the Statistics table. Do not change the table structure without the developer's
          knowledge, and also keep the email field in order
        </p>
        <p>
          Messages are divided into two categories - <b>HAPPYBIRTHDAY</b> and <b>ANNIVERSARY</b>. Anniversary also has subcategories <b>ANNIVERSARY_1</b> for
          the first anniversary, <b>ANNIVERSARY_2</b> for the second, and so on. If you need more subcategories -{' '}
          <Link href="/feedback">contact the developer</Link>
        </p>
        <p>
          If there is no message in the anniversary subcategories, a message from the main <b>ANNIVERSARY</b> category will be sent.
        </p>
        <p>If there are no messages in the main category, the message will not be sent</p>
        <p>If the email in the table is specified incorrectly, the message will not be sent</p>
        <p>
          <b>ANNIVERSARY</b> messages are sent from the first
        </p>
      </>
    );
  }
}

export default CreateUser;
