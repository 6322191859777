import React from 'react';
import Article from '../../components/Article';
export { default as Secondary } from './Secondary';

class Overtime extends React.Component {
  render() {
    return (
      <>
        <Article alias="overtime" />
      </>
    );
  }
}

export default Overtime;
