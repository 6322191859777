import { Divider } from '@mui/material';
import React from 'react';
import CommunicationForm from '../../components/CommunicationForm';
import Feed from './partials/Feed';

class Secondary extends React.Component {
  render() {
    return (
      <>
        <CommunicationForm title="Vacancies question or refferal request" category="refferal" />,
        <Divider sx={{ my: 2 }} />
        <Feed />
      </>
    );
  }
}

export default Secondary;
