import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { ExampleButtons } from './ExampleButtons';
import { IFieldProps, IProp, IString } from './interfaces';

export const StringInput = ({ id, disabled, changed, edited, field, error, value }: IFieldProps) => {
  const string = field as IString & IProp;
  const val = (value as string | undefined) || '';
  const hasError = !!error;
  let helper = '';
  if (string.maxLength !== undefined) {
    const { maxLength } = string;
    const left = maxLength - (val || '').length;
    helper = `Characters left: ${left}`;
  }
  let type;
  if (string.format === 'password') {
    type = 'password';
  }
  return (
    <>
      <TextField
        id={id}
        type={type}
        error={hasError}
        disabled={disabled}
        onChange={changed}
        onBlur={edited}
        multiline={string.multiline}
        minRows="3"
        label={string.title}
        required={string.required}
        variant="outlined"
        value={val}
        InputProps={{
          endAdornment: <InputAdornment position="end">{string.suffix}</InputAdornment>,
        }}
      />
      {string.examples && <ExampleButtons disabled={disabled} examples={string.examples} id={id} changed={changed} />}
      <FormHelperText>{error || helper}</FormHelperText>
    </>
  );
};
