import React from 'react';
import Article from '../../components/Article';

export { default as Secondary } from './Secondary';

class Digest extends React.Component {
  render() {
    return (
      <div className="digest">
        <Article alias="digest" type="blog" />
      </div>
    );
  }
}

export default Digest;
