import { Schedule, Telegram, Update } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import locale from 'date-fns/locale/en-GB';
import React from 'react';
import Button from '../../../components/elements/Button';
import Heading from '../../../components/elements/typography/Heading';
import { IChat, IGroup, IResponse } from '../../../interfaces/outstaff';
import { show } from '../../../stores/alert';
import { createEvent } from '../../../utils/analitycs';
import isInvalid from '../../../utils/is-invalid';
import stringToBoolean from '../../../utils/string-to-boolean';
import './Form.scss';
import SubscriberList from './partials/SubscriberList';

const clearErrorState = {};
const defaultState = {
  data: { message: '' },
  errors: clearErrorState,
  suggestions: [],
  loading: false,
  wait: false,
  response: undefined,
};
const maxLength = 4096;

interface IState {
  data: {
    [key: string]: string | number | boolean | Date;
  };
  errors: {
    [key: string]: string;
  };
  loading: boolean;
  wait: boolean;
  chats?: IChat[];
  groups?: IGroup[];
  response?: IResponse;
}

interface IProps {
  title: string;
  assignChatToGroup: (chatId: number, groupId: number) => Promise<any>;
  unassignChatToGroup: (chatId: number, groupId: number) => Promise<any>;
  createChatGroup: (name: string) => Promise<any>;
  getChatGroups: () => Promise<IGroup[]>;
  getChats: (groupId?: number) => Promise<IChat[]>;
  sendToChats: (message: string, groupId?: number) => Promise<IResponse>;
  createChatSheduled: (message: string, sendAfter: Date, groupId?: number) => Promise<any>;
}

class Form extends React.Component<IProps, IState> {
  state: IState = { ...defaultState };

  // constructor(props: any) {
  //   super(props);
  // }
  private async _loadChats() {
    const chats = await this.props.getChats(this.state.data.groupId as number);
    this.setState({ chats });
  }

  private async _loadGroups() {
    const groups = await this.props.getChatGroups();
    this.setState({ groups });
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      await Promise.all([this._loadGroups(), this._loadChats()]);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(_prevProps: any, prevState: any) {
    if (this.state.data.groupId !== prevState.data.groupId) {
      await this._reloadChats();
    }
  }

  to?: ReturnType<typeof setTimeout>;
  componentWillUnmount() {
    this.to && clearTimeout(this.to);
  }

  private _setData(id: string, value: string | boolean) {
    this.setState({ data: { ...this.state.data, [id]: value } });
  }

  private _setError(id: string, value: string) {
    this.setState({ errors: { ...this.state.errors, [id]: value } });
  }

  private _validationPropertiesShort: any = {
    groupId: { type: ['integer', 'null'] },
    message: { type: 'string', minLength: 1, maxLength },
  };

  private _validationProperties: any = {
    ...this._validationPropertiesShort,
    isScheduled: { type: 'boolean' },
    sendAfter: { type: 'string', format: 'date-time' },
  };

  private _validationSendShema: any = {
    type: 'object',
    properties: this._validationPropertiesShort,
    required: ['message'],
  };

  private _validationScheduleShema: any = {
    type: 'object',
    properties: this._validationProperties,
    required: ['message', 'sendAfter'],
  };

  private _validateField(id: string, value: string | boolean) {
    const schema = this._validationProperties[id];
    if (schema) {
      const invalid = isInvalid(schema, value);
      if (invalid) {
        const messages = invalid.map(({ message }) => message).join('; ');
        const msg = `Error: ${messages}`;
        this._setError(id, msg);
        return false;
      }
    }
    this._setError(id, '');
    return true;
  }

  private async _changed(id: string, value: string) {
    let val: string | boolean = value;

    // pre
    switch (id) {
      case 'groupId':
      case 'message':
        await this.setState({ response: undefined });
        break;
      case 'isScheduled':
        val = stringToBoolean(value);
        break;
    }
    await this._setData(id, val);
    return val;
  }

  private async _reloadChats() {
    this.setState({ loading: true });
    try {
      await Promise.all([this._loadGroups(), this._loadChats()]);
    } finally {
      this.setState({ loading: false });
    }
  }

  private async _edited(id: string, value: string) {
    if (!id) {
      return;
    }
    const val: string | boolean = await this._changed(id, value);
    if (!this._validateField(id, val)) {
      return;
    }
    // post
    switch (id) {
      case 'message':
        break;
      case 'groupId':
        break;
      case 'isScheduled':
        this._setSendAfter(val ? new Date(this._getDefaultScheduleDate()) : null);
        break;
    }
  }

  private async _save() {
    this.setState({ loading: true, response: undefined });
    const isScheduled = !!this.state.data.isScheduled;
    const schema = isScheduled ? this._validationScheduleShema : this._validationSendShema;
    const invalid = isInvalid(schema, this.state.data);
    if (invalid) {
      const errors: { [key: string]: string } = {};
      for (const error of invalid) {
        const { instancePath, message } = error;
        const id = instancePath.replace(/^\//, '').split('/')[0];
        if (!errors[id]) {
          errors[id] = `Error: ${message}`;
        } else {
          errors[id] += `; ${message}`;
        }
      }
      this.setState({ errors, loading: false });
      return;
    }
    this.setState({ errors: clearErrorState });
    try {
      if (isScheduled) {
        await this.props.createChatSheduled(
          this.state.data.message as string,
          new Date(this.state.data.sendAfter as string),
          this.state.data.groupId as number,
        );
        this.setState({ loading: false, wait: true });
        this.to && clearTimeout(this.to);
        this.to = setTimeout(() => {
          this.setState({ ...defaultState });
        }, 5000);
        show('Message scheduled successfully');
        createEvent({ category: 'outstaff', action: 'scheduled', label: this.props.title });
        return;
      }
      const response = await this.props.sendToChats(this.state.data.message as string, this.state.data.groupId as number);
      const current = this.state.chats || [];
      if (response.success.length + response.error.length + response.unsubscribed.length !== current.length) {
        const chats = await this.props.getChats();
        chats.push(...current.filter(({ uid }) => response.unsubscribed.includes(uid)));
        this.setState({ chats });
      }
      this.setState({ loading: false, response, wait: true });
      this.to && clearTimeout(this.to);
      this.to = setTimeout(() => {
        this.setState({ ...defaultState, response });
      }, 5000);
      show('Message sent successfully');
      createEvent({ category: 'outstaff', action: 'sent', label: this.props.title });
    } catch (e) {
      this.setState({ loading: false, response: undefined });
    }
  }

  private _getDefaultScheduleDate() {
    return Date.now() + 3600000;
  }

  private _setSendAfter(date: Date | null) {
    this._edited('sendAfter', date && !isNaN(date.getTime()) ? date.toISOString() : '');
  }

  render() {
    const onClickReset = () => this.setState({ ...defaultState });
    const edited = (event: any) => this._edited(event.target.id || event.target.name, event.target.value);
    const changed = (event: any) => this._changed(event.target.id || event.target.name, event.target.value);
    const setSendAfter = (date: Date | null) => this._setSendAfter(date);
    const save = () => this._save();
    const reloadChats = () => this._reloadChats();
    const charactersLeftCounter = `Characters left: ${maxLength - (this.state.data.message as string).length}`;
    const dateTimeRenderInput = (params: any) => <TextField {...params} id="sendAfter" error={!!this.state.errors.sendAfter} />;

    const addGroupToChatHandler = async (chatId: number, groupId: number) => {
      await this.props.assignChatToGroup(chatId, groupId);
      await this._reloadChats();
    };
    const removeGroupFromChatHandler = async (chatId: number, groupId: number) => {
      await this.props.unassignChatToGroup(chatId, groupId);
      await this._reloadChats();
    };
    const getGroupsHandler = async () => {
      return this.props.getChatGroups();
    };
    const createAndAddGroupToChatHandler = async (chatId: number, groupName: string) => {
      const { id } = await this.props.createChatGroup(groupName);
      await this.props.assignChatToGroup(chatId, id);
      await this._reloadChats();
    };

    return (
      <>
        <Heading>{this.props.title} message form</Heading>
        <div className="send-chat-post-form">
          <FormControl
            disabled={this.state.wait || this.state.loading}
            error={!!this.state.errors.groupId}
            fullWidth={true}
            sx={{ m: theme => theme.spacing(1), width: theme => `calc(100% - ${theme.spacing(2)})` }}
          >
            <InputLabel id="groupIdLabel">Group</InputLabel>
            <Select
              name="groupId"
              error={!!this.state.errors.groupId}
              labelId="groupIdLabel"
              value={this.state.data.groupId || ''}
              label="Group"
              onChange={edited}
              variant="outlined"
            >
              <MenuItem value={undefined}>-All groups-</MenuItem>
              {this.state.groups &&
                this.state.groups.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{this.state.errors.groupId}</FormHelperText>
          </FormControl>
          <FormControl
            disabled={this.state.wait || this.state.loading}
            error={!!this.state.errors.message}
            fullWidth={true}
            sx={{ m: theme => theme.spacing(1), width: theme => `calc(100% - ${theme.spacing(2)})` }}
          >
            <TextField
              id="message"
              error={!!this.state.errors.message}
              disabled={this.state.wait || this.state.loading}
              onChange={changed}
              onBlur={edited}
              multiline={true}
              label="Message"
              variant="outlined"
              minRows="3"
              value={this.state.data.message}
              helperText={charactersLeftCounter}
            />
            <FormHelperText>{this.state.errors.message}</FormHelperText>
          </FormControl>
          <FormControl
            disabled={this.state.wait || this.state.loading}
            error={!!this.state.errors.isScheduled}
            component="fieldset"
            fullWidth={true}
            sx={{ m: 1 }}
          >
            <FormControlLabel
              control={<Checkbox id="isScheduled" value={!!this.state.data.isScheduled} checked={!!this.state.data.isScheduled} />}
              disabled={this.state.wait || this.state.loading}
              onClick={edited}
              label="Schedule a message to be sent later"
            />
            {this.state.errors.isScheduled && <FormHelperText>{this.state.errors.isScheduled}</FormHelperText>}
          </FormControl>
          {!!this.state.data.isScheduled && (
            <FormControl
              disabled={this.state.wait || this.state.loading}
              error={!!this.state.errors.sendAfter}
              fullWidth={true}
              sx={{ m: theme => theme.spacing(1), width: theme => `calc(100% - ${theme.spacing(2)})` }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <DateTimePicker
                  label="Date and time after which the message will be sent"
                  disabled={this.state.wait || this.state.loading}
                  value={new Date((this.state.data.sendAfter as Date) || this._getDefaultScheduleDate())}
                  onChange={setSendAfter}
                  renderInput={dateTimeRenderInput}
                />
              </LocalizationProvider>
              <FormHelperText>{this.state.errors.sendAfter}</FormHelperText>
            </FormControl>
          )}
        </div>
        <Divider />
        <div className="send-chat-post-form">
          <FormControl fullWidth={true} sx={{ m: 1 }}>
            <Stack spacing={2} direction="row">
              <Button
                disabled={this.state.wait || this.state.loading}
                variant="contained"
                onClick={save}
                startIcon={this.state.data.isScheduled ? <Schedule /> : <Telegram />}
              >
                {this.state.data.isScheduled ? 'Save' : 'Send'}
              </Button>
              <Button disabled={this.state.wait || this.state.loading} variant="outlined" onClick={onClickReset}>
                Cancel
              </Button>
            </Stack>
          </FormControl>
        </div>
        <Divider />
        <Heading>
          Subscribed {this.props.title} list{this.state.data.groupId && ' in selected group'}
        </Heading>
        {this.state.chats && this.state.chats.length > 5 && (
          <Button disabled={this.state.wait || this.state.loading} size="small" onClick={reloadChats} variant="outlined" startIcon={<Update />}>
            Reload list
          </Button>
        )}
        <SubscriberList
          items={this.state.chats}
          loading={this.state.loading}
          response={this.state.response}
          addGroupToItem={addGroupToChatHandler}
          createAndAddGroupToItem={createAndAddGroupToChatHandler}
          removeGroupFromItem={removeGroupFromChatHandler}
          getGroups={getGroupsHandler}
        />
        <Button disabled={this.state.wait || this.state.loading} size="small" onClick={reloadChats} variant="outlined" startIcon={<Update />}>
          Reload list
        </Button>
      </>
    );
  }
}
export default Form;
