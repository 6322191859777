import { ContentCopy } from '@mui/icons-material';
import { Container, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import React from 'react';
import Button from '../../../../components/elements/Button';
import Heading from '../../../../components/elements/typography/Heading';
import copy from '../../../../utils/copy';

const Created = ({ user, reset }: { user: { email: string; password: string }; reset: () => void }) => {
  const onClickCopy = (value: string) => () => copy(value);
  return (
    <Container maxWidth="sm">
      <div className="created-user-form">
        <Heading>Created user details</Heading>
        <FormControl fullWidth={true} sx={{ m: 1 }}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            id="email"
            value={user.email}
            label="Email"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onClickCopy(user.email)} edge="end">
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth={true} sx={{ m: 1 }}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            value={user.password}
            label="Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onClickCopy(user.password)} edge="end">
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Divider />
        <FormControl fullWidth={true} sx={{ m: 1 }}>
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={onClickCopy(`Email: ${user.email}\nPassword: ${user.password}`)} startIcon={<ContentCopy />}>
              Copy credentials
            </Button>
            <Button variant="outlined" onClick={reset}>
              Create another one
            </Button>
          </Stack>
        </FormControl>
      </div>
    </Container>
  );
};
export default Created;
