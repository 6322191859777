import LRUCache from 'lru-cache';

const def = { max: 2000, maxAge: 3300000 };

type TKey = string | number;

class Cache {
  cache: LRUCache<TKey, any>;
  constructor(options: LRUCache.Options<TKey, any> = def) {
    this.cache = new LRUCache(options);
  }

  set(key: TKey, value: any) {
    return this.cache.set(key, value);
  }
  get(key: TKey) {
    return this.cache.get(key);
  }
  del(key: TKey) {
    return this.cache.del(key);
  }
}

export default Cache;

const global = new Cache(def);
export const set = (key: TKey, value: any) => global.set(key, value);
export const get = (key: TKey) => global.get(key);
export const del = (key: TKey) => global.del(key);
