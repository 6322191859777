import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './components/App';
import './index.scss';
import StateProvider from './stores';
import history from './utils/history';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#fecc38',
      contrastText: '#000',
    },
    primary: {
      main: '#1f88f8',
      contrastText: '#fff',
    },
    info: {
      main: '#000',
      contrastText: '#FFF',
    },
  },
  typography: {
    h4: {
      fontSize: '1.8rem',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <StateProvider>
        <App />
      </StateProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root') as HTMLElement,
);
