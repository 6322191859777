import { BeachAccess, LocalHospital, MiscellaneousServices, People, Person, Whatshot } from '@mui/icons-material';
import React from 'react';
import Brain from '../../components/elements/icons/Brain';
import CovidIcon from '../../components/elements/icons/Covid';
import { ERoles } from '../../constants/user';
import IRouteItems from '../../interfaces/routing';
import Covid, { Secondary as CovidSecondary } from '../../pages/Covid';
import Overtime, { Secondary as OvertimeSecondary } from '../../pages/Overtime';
import SickLeave, { Secondary as SickLeaveSecondary } from '../../pages/SickLeave';
import Training, { Secondary as TrainingSecondary } from '../../pages/Training';
import Authorize from '../../pages/user/Authorize';
import Login from '../../pages/user/Login';
import My from '../../pages/user/My';
import Vacation, { Secondary as VacationSecondary } from '../../pages/Vacation';

const items: IRouteItems = {
  'personal-data': {
    title: 'Personal Data',
    icon: <Person />,
    component: My,
    roles: [ERoles.NINJA],
    description: 'View your own personal data',
    items: {
      vacation: {
        title: 'Vacation',
        description: 'Your personal vacation and company vacation policies',
        icon: <BeachAccess />,
        roles: [ERoles.NINJA],
        component: Vacation,
        secondary: VacationSecondary,
        // disabled: true,
      },
      'sick-leave': {
        title: 'Sick leave',
        description: 'Your available sick leave and company sick leave policies',
        icon: <LocalHospital />,
        roles: [ERoles.NINJA],
        component: SickLeave,
        secondary: SickLeaveSecondary,
        // disabled: true,
      },
      covid: {
        title: 'Covid-19',
        description: "Company's COVID-19 policies",
        icon: <CovidIcon />,
        roles: [ERoles.NINJA],
        component: Covid,
        secondary: CovidSecondary,
        // disabled: true,
      },
      training: {
        title: 'Training',
        description: 'Skill training, learning new technologies',
        icon: <Brain />,
        roles: [ERoles.NINJA],
        component: Training,
        secondary: TrainingSecondary,
        // disabled: true,
      },
      overtime: {
        title: 'Overtime',
        description: 'Overtime politics and your personal overtime',
        icon: <Whatshot />,
        roles: [ERoles.NINJA],
        component: Overtime,
        secondary: OvertimeSecondary,
        // disabled: true,
      },
      services: {
        title: 'Your services',
        description: 'Jira, etc.',
        icon: <MiscellaneousServices />,
        roles: [ERoles.NINJA],
        disabled: true,
      },
    },
  },
  login: {
    title: 'Login',
    icon: <People />,
    component: Login,
    menu: false,
    fullscreen: true,
    roles: [null],
    items: {
      'token/:via': {
        title: 'Exchange token',
        icon: <People />,
        component: Authorize,
        menu: false,
        fullscreen: true,
        roles: [null],
      },
    },
  },
};

export default items;
