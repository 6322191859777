import { Menu as MuiMenu } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Context } from '../../../stores/menu';

const ITEM_HEIGHT = 48;
const MAX_VISIBLE_ITEMS = 10.5;

@observer
class Menu extends React.Component {
  static contextType = Context;
  render() {
    if (!this.context.component) {
      return null;
    }
    const handleClose = () => (this.context.handleClose ? this.context.handleClose() : this.context.close());
    const Component: React.FC | typeof React.Component = this.context.component;
    return (
      <MuiMenu
        {...(this.context.props || {})}
        anchorEl={this.context.anchorEl}
        open={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * MAX_VISIBLE_ITEMS,
          },
        }}
      >
        <Component />
      </MuiMenu>
    );
  }
}

export default Menu;
