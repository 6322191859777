import { ERoles, ESpecificRoles } from '../../constants/user';
import { pascalCaseToString } from '../../pages/user/My/index';

const convertRole = (role: ERoles | ESpecificRoles) => {
  switch (role) {
    case ERoles.USER_MANAGER:
      return 'User man.';
    case ERoles.OUTSTAFFER:
      return 'Outstaff man.';
    case ERoles.CONTENT:
      return 'Content man.';
    case ERoles.ACCOUNT_MANAGER:
      return 'Account man.';
    case ERoles.HR:
      return 'HR';
    case ERoles.KINZHAL:
      return 'Kinzhal man.';
    case ERoles.PM:
      return 'PM';
    case ERoles.GOD:
      return 'DIO Brando';
    default:
      return pascalCaseToString(role);
  }
};

export default convertRole;
