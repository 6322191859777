import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { create, getById, updateById } from '../../../apis/message';
import ContentForm, { IContentFormSchema } from '../../../components/ContentForm';
import { IMessage, IMessageShort } from '../../../interfaces/message';
import { Context, goTo } from '../../../stores/navigation';
import './index.scss';

export { default as Secondary } from './Instruction';

interface IState {
  isLoading: boolean;
  message: IMessage | null;
  error?: string;
}

@observer
class Form extends React.Component<{}, IState> {
  static contextType = Context;

  state: IState = { isLoading: false, message: null };

  async componentDidMount() {
    const id = this.context.options.params.id;
    if (id) {
      try {
        await this.setState({ isLoading: true });
        const message = await getById(id);
        this.setState({ isLoading: false, message });
      } catch (e) {
        this.setState({ isLoading: false, error: (e as Error).message });
      }
    }
  }

  render() {
    const reset = () => goTo('/hr/messages');
    const save = async (message: any): Promise<void> => {
      const id = this.context.options.params.id;
      if (id) {
        await updateById(id, message as IMessageShort);
      } else {
        await create(message as IMessageShort);
      }
      reset();
    };

    const schema: IContentFormSchema = {
      category: {
        title: 'Category',
        type: 'string',
        enum: [
          'ANNIVERSARY',
          'ANNIVERSARY_1',
          'ANNIVERSARY_2',
          'ANNIVERSARY_3',
          'ANNIVERSARY_4',
          'ANNIVERSARY_5',
          'ANNIVERSARY_6',
          'ANNIVERSARY_7',
          'ANNIVERSARY_8',
          'ANNIVERSARY_9',
          'ANNIVERSARY_10',
          'HAPPYBIRTHDAY',
        ],
        required: true,
      },
      title: { title: 'Message title', type: 'string', required: true },
      template: { title: 'Content template', type: 'string', required: true, multiline: true },
      imageUrl: { title: 'Image URL', type: 'string', nullable: true },
      status: { title: 'Status', type: 'string', enum: ['ACTIVE', 'DRAFT'], required: true },
    };
    return (
      <div className="message-form" key="form">
        {this.state.error && <Box>{this.state.error}</Box>}
        <ContentForm
          title="Message"
          restore={true}
          disabled={this.state.isLoading}
          data={this.state.message as any}
          schema={schema}
          submitBtnText="Save"
          onSubmit={save}
          onReset={reset}
        />
      </div>
    );
  }
}

export default Form;
