import { Link as MuiLink, LinkProps } from '@mui/material';
import React from 'react';
import { goTo } from '../../../stores/navigation';

interface IProps extends LinkProps {
  href?: string;
}

class Link extends React.Component<IProps, any> {
  render() {
    let onClick = this.props.onClick;
    if (this.props.href !== undefined && !this.props.onClick) {
      onClick = (e: any) => {
        if (this.props.href !== undefined) {
          e.preventDefault();
          goTo(this.props.href);
        }
      };
    }
    return <MuiLink {...this.props} onClick={onClick} sx={{ cursor: 'pointer', ...(this.props.sx || {}) }} />;
  }
}

export default Link;
