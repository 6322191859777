import {
  assignChannelToGroup,
  createChannelGroup,
  createChannelSheduled,
  deleteChannelScheduled,
  getChannelBotInfo,
  getChannelGroups,
  getChannels,
  getChannelScheduleds,
  sendToChannels,
  unassignChannelToGroup,
} from '../../../apis/outstaff';
import Outstaff from '../components';
import Instruction from './Instruction';

const Channel = () => {
  return (
    <Outstaff
      title="Chat"
      Instruction={Instruction}
      assignChatToGroup={assignChannelToGroup}
      createChatGroup={createChannelGroup}
      getChatBotInfo={getChannelBotInfo}
      getChatGroups={getChannelGroups}
      getChats={getChannels}
      sendToChats={sendToChannels}
      unassignChatToGroup={unassignChannelToGroup}
      createChatSheduled={createChannelSheduled}
      getChatScheduleds={getChannelScheduleds}
      deleteChatScheduled={deleteChannelScheduled}
    />
  );
};
export default Channel;
