import {
  assignChatToGroup,
  createChatGroup,
  createChatSheduled,
  deleteChatScheduled,
  getChatBotInfo,
  getChatGroups,
  getChats,
  getChatScheduleds,
  sendToChats,
  unassignChatToGroup,
} from '../../../apis/outstaff';
import Outstaff from '../components';
import Instruction from './Instruction';

const Chat = () => {
  return (
    <Outstaff
      title="Chat"
      Instruction={Instruction}
      assignChatToGroup={assignChatToGroup}
      createChatGroup={createChatGroup}
      getChatBotInfo={getChatBotInfo}
      getChatGroups={getChatGroups}
      getChats={getChats}
      sendToChats={sendToChats}
      unassignChatToGroup={unassignChatToGroup}
      createChatSheduled={createChatSheduled}
      getChatScheduleds={getChatScheduleds}
      deleteChatScheduled={deleteChatScheduled}
    />
  );
};
export default Chat;
