import { ContentCopy } from '@mui/icons-material';
import { Chip as MuiChip, ChipProps } from '@mui/material';
import React from 'react';
import copy from '../../../utils/copy';
import Tooltip from '../Tooltip';

interface IProps extends ChipProps {
  label: string;
  onClick?: never;
  onDelete?: never;
}

class Copy extends React.Component<IProps, any> {
  render() {
    const onClick = () => {
      copy(this.props.label);
    };

    return (
      <Tooltip title="Copy">
        <MuiChip size="small" deleteIcon={this.props.icon ? undefined : <ContentCopy />} {...this.props} onClick={onClick} onDelete={onClick} />
      </Tooltip>
    );
  }
}

export default Copy;
