import { Divider, Grid, GridSize } from '@mui/material';
import React from 'react';
import { ComponentType } from '../../../interfaces/system';

const Columns = ({ columns }: { columns: ComponentType[] }) => {
  const md = Math.max(12 - ((columns.length > 2 ? 2 : 3) * columns.length - 1), 4) as GridSize;
  return (
    <Grid container={true} spacing={2} mt={0}>
      {columns.map((column, i) => (
        <>
          {i > 0 && (
            <>
              <Grid item={true} md="auto" sm={false} component={Divider} orientation="vertical" flexItem={true} sx={{ display: { sm: 'none', md: 'block' } }} />
              <Grid item={true} xs={12} component={Divider} orientation="horizontal" sx={{ display: { md: 'none' } }} />
            </>
          )}
          <Grid item={true} sm={false} md={i === 0 ? md : true}>
            {column}
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default Columns;
