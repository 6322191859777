import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ParsedUrlQueryInput } from 'querystring';
import { IData } from '../interfaces/api';
import IUser from '../interfaces/user';
import { show } from '../stores/alert';
import { logout, setUser } from '../stores/auth';
import { finish, start } from '../stores/progress';
import { createEvent } from '../utils/analitycs';
import { get as getAuth } from '../utils/auth';

type TMethod = 'get' | 'post' | 'patch' | 'put' | 'delete';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`; // http://localhost:3030/api

const clear = (str: string) => str.trim().replace(/(^\/)|(\/$)/g, '');

const handleUser = (user: string) => {
  if (user) {
    setUser(JSON.parse(user) as IUser);
  }
};

export class ResError extends Error {
  code: string | number;
  meta: any;
  constructor(message: string, code: string | number = 500, meta?: any) {
    super(message);
    this.message = message;
    this.code = code;
    this.meta = meta;
  }
}

const request =
  (prefix: string) =>
  async (uri: string = '/', method: TMethod = 'get', query?: ParsedUrlQueryInput, data?: IData) => {
    start(2);
    const token = getAuth();
    finish(1);
    const url = `${baseUrl}/${clear(prefix)}/${clear(uri)}`;
    const authorization = token ? `Bearer ${token}` : null;
    const headers: { [key: string]: any } = {
      accept: 'application/json',
    };
    if (authorization) {
      headers.authorization = authorization;
    }
    const config: AxiosRequestConfig = {
      url,
      method,
      headers,
      data,
      params: query || {},
    };
    try {
      const {
        data,
        headers: { user },
      } = await axios(config);
      handleUser(user);
      return data;
    } catch (e) {
      const error: AxiosError = e as AxiosError;
      if (error.response && error.response.status === 401 && token) {
        logout();
      }

      let message = error.message;
      let code: string | number = error.code || 500;
      let meta;

      if (error.response) {
        message = error.response.statusText;
        if (error.response.status) {
          code = error.response.status;
        }
        if (error.response.data && error.response.data.message) {
          message = error.response.data.message;
          if (error.response.data.code) {
            code = error.response.data.code;
          }
          meta = error.response.data.meta;
        }
        handleUser(error.response.headers.user);
      }

      createEvent({ category: 'api-error', action: `${method}: ${error.request.responseURL}`, label: `${error.request.responseText}` });
      show(message, 'error');
      throw new ResError(message, code, meta);
    } finally {
      finish(1);
    }
  };

export default request;
