import { Stack } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Context } from '../../../stores/float-buttons';
import makeBtnColorSx from '../../../utils/make-btn-color-sx';
import Fab from '../../elements/Fab';

@observer
class FABs extends React.Component {
  static contextType = Context;

  render() {
    const list: any[] = this.context.getList();
    const btns = list.map(({ title, href, onClick, icon, color, bgColor }, i) => {
      const style = makeBtnColorSx('Fab', bgColor, color);
      return (
        <Fab key={`${i}-${title}`} sx={{ mt: 1, ...style }} color="secondary" tooltip={title} href={href} onClick={onClick}>
          {icon}
        </Fab>
      );
    });
    return (
      <Stack sx={{ position: 'fixed', right: 20, bottom: 20 }} direction="column">
        {btns}
      </Stack>
    );
  }
}

export default FABs;
