import React from 'react';
import pic from './dio1.png';

class DIO1 extends React.Component {
  render() {
    return (
      <>
        <img alt="KONO DIO DA" src={pic} />
        <p>You thought there was such a page, but it was me, DIO!</p>
      </>
    );
  }
}
export default DIO1;
