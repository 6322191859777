import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import React from 'react';
import { IBoolean, IFieldProps, IProp } from './interfaces';

export const CheckboxInput = ({ id, disabled, edited, field, error, value }: IFieldProps) => {
  const bool = field as IBoolean & IProp;
  const val = !!(value as boolean | undefined);
  return (
    <>
      <FormControlLabel
        control={<Checkbox id={id} value={val} /* checked={bool.example} */ sx={!!error ? { color: theme => theme.palette.error.main } : {}} />}
        disabled={disabled}
        onClick={edited}
        sx={!!error ? { color: theme => theme.palette.error.main } : {}}
        label={bool.title}
      />
      <FormHelperText>{error}</FormHelperText>
    </>
  );
};
