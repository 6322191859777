import React from 'react';
import From from '../../components/CommunicationForm';

const Secondary = () => {
  return (
    <>
      <From title="COVID Treatment Reimbursement Request" category="covid" />
    </>
  );
};

export default Secondary;
