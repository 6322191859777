import { ButtonGroup, FormLabel, InputLabel } from '@mui/material';
import React from 'react';
import Button from '../../../../components/elements/Button';

const SuggestionList = ({
  suggestions,
  isLoading,
  isRequired,
  isCanGet,
  callback,
}: {
  suggestions: string[];
  isLoading: boolean;
  isRequired: boolean;
  isCanGet: boolean;
  callback: (id: string, suggestion: string) => any;
}) => {
  let label = 'Suggestions';
  if (isLoading) {
    label = 'Loading suggestions...';
  }
  const def = 'Enter first and last name and set is team member checkbox to retreive email suggestions';
  if (isRequired) {
    label = def;
  } else if (isCanGet && suggestions.length === 0) {
    label = 'There are no suggestion for specified first and last names';
  }
  const id = 'emailRecipient';
  return (
    <div style={{ minHeight: '53px' }}>
      <FormLabel component={InputLabel} shrink={isCanGet && suggestions.length > 0}>
        {label}
      </FormLabel>
      <ButtonGroup sx={{ mx: 1, mb: 1 }} variant="outlined" size="small">
        1
        {isCanGet &&
          suggestions.map((suggestion: string, i: number) => {
            const onclick = () => {
              callback(id, suggestion);
            };
            return (
              <Button key={`${i}-${suggestion}`} onClick={onclick}>
                {suggestion}
              </Button>
            );
          })}
      </ButtonGroup>
    </div>
  );
};
export default SuggestionList;
