import React from 'react';
import Button from '../../elements/Button';

class Forbidden extends React.Component {
  render() {
    return (
      <>
        Forbidden <br />
        <Button variant="contained" color="primary" href="/" sx={{ mx: 1 }}>
          Go Home
        </Button>
      </>
    );
  }
}
export default Forbidden;
