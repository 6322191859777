import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React from 'react';
import Button from '../../components/elements/Button';
import UserAvatar from '../../components/elements/UserAvatar';
import Navigation from '../../components/Navigation';
import { Context as AuthContext } from '../../stores/auth';
import { get, set } from '../../utils/local-storage';

const VAR_NAME = 'last-visit';

@observer
class Home extends React.Component<any> {
  static contextType = AuthContext;

  // constructor(props: any) {
  //   super(props);
  // }

  private isFirstToday: boolean = false;

  private today = format(new Date(), 'yyyyMMdd');

  constructor(props: any) {
    super(props);
    const value = get(VAR_NAME);
    this.isFirstToday = !value || value !== this.today;
  }

  private _isAuth() {
    return !!this.context.token;
  }

  componentDidMount() {
    const isAuth = this._isAuth();
    if (isAuth && this.isFirstToday) {
      set(VAR_NAME, this.today);
    }
  }

  render() {
    const isAuth = this._isAuth();
    let intro;
    if (isAuth) {
      const { user } = this.context;
      intro = (
        <>
          <Stack direction="row" spacing={1} sx={{ py: 1 }}>
            <Typography component="div" variant="h4">
              Hello, {user.name.firstName}
            </Typography>
            <UserAvatar sx={{ width: '2.1rem', height: '2.1rem' }} user={user} />
          </Stack>
          <Typography component="div" variant="subtitle1">
            {this.isFirstToday ? 'where do we start?' : 'what do you want to do?'}
          </Typography>
        </>
      );
    } else {
      intro = (
        <>
          <Typography component="div" variant="h4">
            Hello, ninja
          </Typography>
          <Typography component="div" variant="subtitle1">
            To get all the available options, we suggest{' '}
            <Button href="/login" variant="outlined" size="small">
              Sign In
            </Button>
          </Typography>
        </>
      );
    }
    return (
      <>
        {intro}
        <Navigation sx={{ mx: -4, /* mb: -2, */ mt: 1 }} firstExpandedByDefault={true} />
      </>
    );
  }
}

export default Home;
