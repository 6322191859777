import { Button as MuiButton } from '@mui/material';
import React from 'react';
import { close } from '../../../stores/modal';

export const Modal = (props: { link: string; onLink?: () => any }) => {
  const onClick = () => {
    if (props.onLink) {
      props.onLink();
    }
    close();
  };
  return (
    <div>
      <p>CV was successfully exported as Google Document</p>
      <MuiButton variant="contained" href={props.link} target="_blank" onClick={onClick}>
        Click here to open document in new Tab
      </MuiButton>
    </div>
  );
};
