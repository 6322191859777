import React from 'react';
import { send } from '../../apis/communication';
import ContentForm, { IContentFormSchema } from '../../components/ContentForm';
import { show } from '../../stores/alert';

class Secondary extends React.Component {
  render(): React.ReactNode {
    const schema: IContentFormSchema = {
      service: {
        title: 'Service name',
        type: 'string',
        enum: ['Company Google account', 'Slack', 'Jira', 'Gitlab', 'Other (describe)'],
        required: true,
      },
      message: {
        title: 'Describe your problem',
        type: 'string',
        required: true,
        maxLength: 4000,
        multiline: true,
      },
    };
    const onSubmit = async (data: any) => {
      await send('accessToServices', data.message, [{ title: 'Service name', value: data.service }]);
      await show('Your message has been successfully sent');
    };
    return <ContentForm title="Are you having problems accessing the company's services?" schema={schema} onSubmit={onSubmit} />;
  }
}

export default Secondary;
