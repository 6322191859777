import { makeAutoObservable } from 'mobx';
import React, { Component, createContext, FC } from 'react';

class Modal {
  isOpened: boolean = false;
  component?: FC | typeof Component;
  onClose?: () => any;

  constructor() {
    makeAutoObservable(this);
  }

  public open(component: FC | typeof Component, onClose?: () => any) {
    this.isOpened = true;
    this.component = component;
    this.onClose = onClose;
  }

  public close() {
    if (this.onClose) {
      this.onClose();
    }
    this.isOpened = false;
    this.component = undefined;
    this.onClose = undefined;
  }
}

const modal = new Modal();

export const open = (component: FC | typeof Component, onClose?: () => any) => modal.open(component, onClose);
export const close = () => modal.close();

export const Context = createContext<Modal>(modal);
const Provider = ({ children }: any) => <Context.Provider value={modal}>{children}</Context.Provider>;

export default Provider;
