import { Avatar, AvatarProps } from '@mui/material';
import React from 'react';
import IUser from '../../../interfaces/user';
import { stringToColor } from '../../Routing/Partial/Header/index';

const UserAvatar = (props: AvatarProps & { user: IUser }) => {
  const { user } = props;
  const alt = `${user.name.firstName} ${user.name.lastName}`.trim();
  if (user.photo) {
    return <Avatar {...props} alt={alt} src={user.photo} />;
  }
  const initials = [];
  if (user.name.firstName) {
    initials.push(user.name.firstName[0]);
  }
  if (user.name.lastName) {
    initials.push(user.name.lastName[0]);
  }
  if (!initials.length && user.email) {
    const receiver = user.email.split('@')[0];
    const words = receiver.split('.');
    initials.push(words[0][0]);
    if (words[1]) {
      initials.push(words[1][0]);
    } else if (words[0][1]) {
      initials.push(words[0][1]);
    }
  }
  return (
    <Avatar {...props} alt={alt} sx={{ ...(props.sx || {}), bgcolor: stringToColor(alt) }}>
      {initials.join('')}{' '}
    </Avatar>
  );
};

export default UserAvatar;
