import { IGroup, IResponse, IScheduled } from '../../interfaces/outstaff';
import request from '../request';

const outstaffApi = request('outstaff');

// CHANNEL SECTION
export const getChannelBotInfo = async (): Promise<{ displayName: string; username: string }> => {
  return outstaffApi('channel/bot');
};

export const getChannels = async (groupId?: number): Promise<Array<{ uid: number; name: string; groups: IGroup[] }>> => {
  return outstaffApi('channel', 'get', { groupId });
};

export const sendToChannels = async (message: string, groupId?: number): Promise<IResponse> => {
  return outstaffApi('channel', 'post', undefined, groupId ? { groupId, message } : { message });
};

export const getChannelGroups = async (): Promise<IGroup[]> => {
  return outstaffApi('channel/group');
};

export const createChannelGroup = async (name: string): Promise<IGroup> => {
  return outstaffApi('channel/group', 'post', undefined, { name });
};

export const deleteChannelGroup = async (groupId: number): Promise<void> => {
  await outstaffApi(`channel/group/${groupId}`, 'delete');
};

export const assignChannelToGroup = async (channelId: number, groupId: number): Promise<void> => {
  await outstaffApi(`channel/${channelId}/group/${groupId}`, 'post');
};

export const unassignChannelToGroup = async (channelId: number, groupId: number): Promise<void> => {
  await outstaffApi(`channel/${channelId}/group/${groupId}`, 'delete');
};

export const getChannelScheduleds = async (groupId?: number): Promise<IScheduled[]> => {
  const list = await outstaffApi('channel/scheduled', 'get', { groupId });
  return list.map((item: IScheduled) => Object.assign({}, item, { sendAfter: new Date(item.sendAfter) }));
};

export const createChannelSheduled = async (message: string, sendAfter: Date, groupId?: number): Promise<void> => {
  await outstaffApi('channel/scheduled', 'post', undefined, { message, sendAfter: sendAfter.toISOString(), groupId: groupId || null });
};

export const deleteChannelScheduled = async (scheduledId: number): Promise<void> => {
  await outstaffApi(`channel/scheduled/${scheduledId}`, 'delete');
};

// CHAT SECTION

export const getChatBotInfo = async (): Promise<{ displayName: string; username: string }> => {
  return outstaffApi('chat/bot');
};

export const getChats = async (groupId?: number): Promise<Array<{ uid: number; name: string; groups: IGroup[] }>> => {
  return outstaffApi('chat', 'get', { groupId });
};

export const sendToChats = async (message: string, groupId?: number): Promise<IResponse> => {
  return outstaffApi('chat', 'post', undefined, groupId ? { groupId, message } : { message });
};

export const getChatGroups = async (): Promise<IGroup[]> => {
  return outstaffApi('chat/group');
};

export const createChatGroup = async (name: string): Promise<IGroup> => {
  return outstaffApi('chat/group', 'post', undefined, { name });
};

export const deleteChatGroup = async (groupId: number): Promise<void> => {
  await outstaffApi(`chat/group/${groupId}`, 'delete');
};

export const assignChatToGroup = async (chatId: number, groupId: number): Promise<void> => {
  await outstaffApi(`chat/${chatId}/group/${groupId}`, 'post');
};

export const unassignChatToGroup = async (chatId: number, groupId: number): Promise<void> => {
  await outstaffApi(`chat/${chatId}/group/${groupId}`, 'delete');
};

export const getChatScheduleds = async (groupId?: number): Promise<IScheduled[]> => {
  const list = await outstaffApi('chat/scheduled', 'get', { groupId });
  return list.map((item: IScheduled) => Object.assign({}, item, { sendAfter: new Date(item.sendAfter) }));
};

export const createChatSheduled = async (message: string, sendAfter: Date, groupId?: number): Promise<void> => {
  await outstaffApi('chat/scheduled', 'post', undefined, { message, sendAfter: sendAfter.toISOString(), groupId: groupId || null });
};

export const deleteChatScheduled = async (scheduledId: number): Promise<void> => {
  await outstaffApi(`chat/scheduled/${scheduledId}`, 'delete');
};
