import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { observer } from 'mobx-react';
import React from 'react';

import { Context as AlertContext } from '../../../stores/alert';

const AlertElement = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

@observer
class Alert extends React.Component {
  static contextType = AlertContext;
  render() {
    const handleClose = (event?: any, reason?: any) => {
      if (reason === 'clickaway') {
        return;
      }
      this.context.handleClose();
    };
    const handleExited = () => this.context.handleExited();
    return (
      <>
        <Snackbar
          key={this.context.state.ts}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.context.state.open}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        >
          <AlertElement onClose={handleClose} severity={this.context.state.severity || 'success'}>
            {this.context.state.message}
          </AlertElement>
        </Snackbar>
      </>
    );
  }
}

export default Alert;
