import { Box } from '@mui/material';
import React from 'react';
import { createEvent } from '../../../utils/analitycs';
import Button from '../../elements/Button';
import DIO1 from './DIO1';
import DIO2 from './DIO2';
import Honeybadger1 from './Honeybadger1';
import Honeybadger2 from './Honeybadger2';
import './index.scss';
import Speedwagon from './Speedwagon';

const pages = [DIO1, DIO2, Honeybadger1, Honeybadger2, Speedwagon];

const getRandom = () => pages[Math.floor(Math.random() * pages.length)];

class NotFound extends React.Component {
  componentDidMount() {
    createEvent({ category: 'error', action: 'NOT_FOUND', label: window.location.pathname });
  }
  render() {
    const Page = getRandom();
    return (
      <div id="not-found">
        <Box>
          <Page />
        </Box>
        <Button variant="outlined" color="primary" href="/" sx={{ mx: 1 }}>
          Go Home
        </Button>
      </div>
    );
  }
}
export default NotFound;
