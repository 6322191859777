import { observer } from 'mobx-react';
import { stringify } from 'querystring';
import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import { ERoles } from '../../../constants/user';
import { Context as AuthContext } from '../../../stores/auth';
import { goTo, redirect, setActive } from '../../../stores/navigation';
import acl from '../../../utils/acl';
import { createEvent } from '../../../utils/analitycs';
import setPageTitle from '../../../utils/set-page-title';
import Columns from '../Columns';
import Forbidden from '../Forbidden';
import Full from '../Full';
import '../index.scss';
import Partial from '../Partial';

type ComponentType = typeof React.Component;

type ComponentRouteType = { component: ComponentType; secondary?: ComponentType };
type RedirectRouteType = { redirect: string };

type MainType = ComponentRouteType | RedirectRouteType;

type PropType = {
  key: string;
  exact: boolean;
  title: string;
  path: string;
  fullscreen?: boolean;
  roles?: Array<ERoles | null>;
} & MainType;

@observer
class Route extends React.Component<PropType> {
  static contextType = AuthContext;

  constructor(props: any) {
    super(props);
    const { title, path, computedMatch } = props;
    setActive(path, title, computedMatch);
  }

  render() {
    const { exact, title, path, fullscreen, roles } = this.props;

    const { user } = this.context;
    if (!acl(roles, user)) {
      if (!user) {
        const url = new URL(window.location.href);
        const redirect = `${url.pathname}${url.search}${url.hash}`;
        const query = stringify({ redirect });
        createEvent({ category: 'error', action: 'UNAUTHORIZED', label: path });
        goTo(`/login?${query}`);
        return null;
      }
      createEvent({ category: 'error', action: 'FORBIDDEN', label: path });
      return (
        <Full>
          <Forbidden />
        </Full>
      );
    }
    if (this.props.hasOwnProperty('redirect')) {
      redirect((this.props as RedirectRouteType).redirect);
      return null;
    }
    setPageTitle(title);
    const Wrapper = fullscreen ? Full : Partial;
    const componentProps = this.props as ComponentRouteType;
    const MainComponent = componentProps.component;
    const SecComponent = componentProps.secondary as ComponentType;

    const render = (props: any) => (
      <Wrapper>
        {componentProps.secondary ? (
          <Columns columns={[<MainComponent key="main" {...props} />, <SecComponent key="secondary" {...props} />]} />
        ) : (
          <MainComponent {...props} />
        )}
      </Wrapper>
    );
    return <ReactRoute exact={exact} path={path} render={render} />;
  }
}
export default Route;
