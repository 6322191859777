import React from 'react';
import { send } from '../../apis/communication';
import { CategoryType } from '../../constants/communication';
import { show } from '../../stores/alert';
import ContentForm, { IContentFormSchema } from '../ContentForm';

class CommunicationForm extends React.Component<{ category: CategoryType; title?: string; disabled?: boolean }> {
  private async _save(message: string) {
    try {
      await send(this.props.category, message);
      show('Your request was send successfully');
    } catch (e) {
      //
    }
  }

  render() {
    const save = (data: any) => this._save(data.message);

    const schema: IContentFormSchema = {
      message: {
        title: 'Message',
        type: 'string',
        required: true,
        maxLength: 4000,
        multiline: true,
      },
    };

    return (
      <div className="faq-send-message-form">
        <ContentForm title={this.props.title || 'Ask your question'} schema={schema} onSubmit={save} disabled={this.props.disabled} />
      </div>
    );
  }
}
export default CommunicationForm;
