import { ButtonGroup } from '@mui/material';
import React from 'react';
import Button from '../elements/Button';

export const ExampleButtons = (props: { examples: Array<string | number>; id: string; changed: (event: any) => any; disabled?: boolean }) => (
  <ButtonGroup sx={{ mt: 1 }} variant="outlined" size="small">
    {props.examples.map((example: string | number, i: number) => (
      <Button name={props.id} key={`${props.id}-${i}-${example}`} disabled={props.disabled} onClick={props.changed} value={example}>
        {example}
      </Button>
    ))}
  </ButtonGroup>
);
