import { Tooltip as MuiTooltip, TooltipProps, Typography } from '@mui/material';
import React from 'react';

class Tooltip extends React.Component<TooltipProps, any> {
  render() {
    const fsx = (theme: any) => theme.typography.pxToRem(16);
    return <MuiTooltip arrow={true} disableFocusListener={true} {...this.props} title={<Typography fontSize={fsx}>{this.props.title}</Typography>} />;
  }
}

export default Tooltip;
