import { People } from '@mui/icons-material';
import React from 'react';
import { ERoles } from '../../constants/user';
import IRouteItems from '../../interfaces/routing';
import File from '../../pages/File';

const items: IRouteItems = {
  recruiterbox: {
    title: 'Recruiterbox',
    icon: <People />,
    menu: false,
    items: {
      'candidate/:id/offer': {
        title: 'Offer',
        icon: <People />,
        component: File,
        fullscreen: true,
        roles: [ERoles.FINANCE],
      },
    },
  },
};

export default items;
