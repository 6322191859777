import { AccountTree, AttachMoney, Laptop, MiscellaneousServices, PeopleAlt, PersonOutline, Warning } from '@mui/icons-material';
import React from 'react';
import IRouteItems from '../../interfaces/routing';
import Hardware, { Secondary as HardwareSecondary } from '../../pages/Hardware';
import ProblemColeague, { Secondary as ProblemColeagueSecondary } from '../../pages/ProblemColeague';
import ProblemManager, { Secondary as ProblemManagerSecondary } from '../../pages/ProblemManager';
import ProblemProject, { Secondary as ProblemProjectSecondary } from '../../pages/ProblemProject';
import ProblemWithAccessToService, { Secondary as ProblemWithAccessToServiceSecondary } from '../../pages/ProplemWithAccessToService';
import Salary, { Secondary as SalarySecondary } from '../../pages/Salary';

const items: IRouteItems = {
  issues: {
    title: 'Issues & services',
    icon: <Warning />,
    description: 'Tell us if you have some issues, questions or you need for some services',
    items: {
      hardware: {
        title: 'Hardware problem',
        description: 'Problem with laptop/PC/Mac, tablet, smartphone, etc.',
        icon: <Laptop />,
        roles: [],
        component: Hardware,
        secondary: HardwareSecondary,
        // disabled: true,
      },
      'access-to-services': {
        title: 'Problem with access to services',
        description: 'Problem with access to Google Account, Slack, Jira etc.',
        icon: <MiscellaneousServices />,
        roles: [],
        component: ProblemWithAccessToService,
        secondary: ProblemWithAccessToServiceSecondary,
      },
      salary: {
        title: 'Salary problem',
        description: 'Financial questions',
        icon: <AttachMoney />,
        roles: [],
        component: Salary,
        secondary: SalarySecondary,
        disabled: true,
      },
      project: {
        title: 'Problem with project',
        // description: '',
        icon: <AccountTree />,
        roles: [],
        component: ProblemProject,
        secondary: ProblemProjectSecondary,
        disabled: true,
      },
      colleagues: {
        title: 'Problem with colleagues',
        // description: '',
        icon: <PeopleAlt />,
        roles: [],
        component: ProblemColeague,
        secondary: ProblemColeagueSecondary,
        disabled: true,
      },
      manager: {
        title: 'Problem with manager',
        // description: '',
        icon: <PersonOutline />,
        roles: [],
        component: ProblemManager,
        secondary: ProblemManagerSecondary,
        disabled: true,
      },
    },
  },
};

export default items;
