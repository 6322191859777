import React from 'react';

import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import { Context } from '../../../stores/modal';

const styles: any = (theme: any) => ({
  modal: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

@observer
class SModal extends React.Component<any> {
  static contextType = Context;
  render() {
    const handleClose = () => this.context.close();
    const Component: React.FC | typeof React.Component = this.context.component;

    const { classes } = this.props;

    if (!this.context.isOpened || !Component) {
      return null;
    }

    return (
      <Modal open={this.context.isOpened} onClose={handleClose}>
        <Box>
          <Paper className={classes.modal}>
            <IconButton size="small" className={classes.close} onClick={handleClose}>
              <Close />
            </IconButton>
            <Component />
          </Paper>
        </Box>
      </Modal>
    );
  }
}

export default withStyles(styles)(SModal);
