import React from 'react';
import From from '../../components/CommunicationForm';

class Secondary extends React.Component {
  render() {
    return <From title="Send training request" category="training" />;
  }
}

export default Secondary;
