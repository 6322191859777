import { Google } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { parse } from 'querystring';
import React from 'react';
import Button from '../../../components/elements/Button';
import Slack from '../../../components/elements/icons/Slack';
import TryAgain from '../../../components/elements/TryAgain';
import { EOAuth } from '../../../constants/user';
import { isAuth } from '../../../stores/auth';
import { redirectToAuth } from '../../../utils/auth';
import makeBtnColorSx from '../../../utils/make-btn-color-sx';
import './index.scss';

interface IState {
  loading: boolean;
  error: string | null;
}

class Login extends React.Component<{}, IState> {
  state: IState = { loading: false, error: null };
  private async _redirect(auth: EOAuth) {
    try {
      await this.setState({ loading: true });
      const currentQuery = window.location.search.substring(1);
      const { redirect } = parse(currentQuery) as { redirect?: string };
      await redirectToAuth(auth, redirect);
    } catch (e: any) {
      this.setState({ loading: false, error: e.message });
    }
  }

  async componentDidMount() {
    if (isAuth()) {
      try {
        await this.setState({ loading: true });
        const query = window.location.search.substring(1);
        const { redirect } = parse(query) as { redirect?: string };
        window.location.href = redirect || '/';
      } catch (e: any) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }

  render() {
    if (this.state.error) {
      const reload = () => window.location.reload();
      return (
        <div className="Login">
          <header>
            <div>Something went wrong while getting the link</div>
            <TryAgain />
            <div>
              <Button variant="contained" color="secondary" onClick={reload} sx={{ mx: 1 }}>
                Try again
              </Button>
              or
              <Button variant="contained" color="primary" href="/" sx={{ mx: 1 }}>
                Go Home
              </Button>
            </div>
          </header>
        </div>
      );
    }
    const redirect = (via: EOAuth) => () => this._redirect(via);
    return (
      <div className="Login">
        <header className="App-header">
          {this.state.loading && <CircularProgress color="inherit" />}
          <Box my={1}>Please pass authentication using button below</Box>
          <Box mt={1}>
            <Button disabled={this.state.loading} variant="contained" color="primary" onClick={redirect(EOAuth.GOOGLE)} startIcon={<Google />}>
              Login via Google
            </Button>
          </Box>
          <Box mt={1}>
            <Button
              disabled={this.state.loading}
              sx={makeBtnColorSx('Button', '#611f69', '#FFF')}
              variant="contained"
              onClick={redirect(EOAuth.SLACK)}
              startIcon={<Slack />}
            >
              Login via Slack
            </Button>
          </Box>
          <Box my={1}>or</Box>
          <Box my={1}>
            <Button disabled={this.state.loading} href="/" color="primary" variant="outlined" sx={{ mx: 1 }}>
              Go Home
            </Button>
          </Box>
        </header>
      </div>
    );
  }
}

export default Login;
