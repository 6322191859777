import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { ReactComponent as Colorful } from './colorfull.svg';
import { ReactComponent as White } from './white.svg';

const RATIO = 7.5;
const INIT_WIDTH = 150;

const Logo = (props: { width?: number; variant?: 'white' | 'color' } & SvgIconProps) => {
  const width = props.width || INIT_WIDTH;
  const height = Math.round(width / RATIO);
  return <SvgIcon {...props} component={props.variant === 'white' ? White : Colorful} sx={{ ...(props.sx || {}), width, height }} viewBox="102 24 1010 146" />;
};

export default Logo;
