import { Add, Close } from '@mui/icons-material';
import { Box, Divider, FormControl, Stack, Typography } from '@mui/material';
import { SomeJTDSchemaType } from 'ajv/dist/core';
import md5 from 'md5';
import React from 'react';
import { ResError } from '../../apis/request';
import { close, open } from '../../stores/modal';
import { createEvent } from '../../utils/analitycs';
// import { show } from '../../stores/alert';
import isInvalid from '../../utils/is-invalid';
import { del, get, set } from '../../utils/local-storage';
import stringToBoolean from '../../utils/string-to-boolean';
import Button from '../elements/Button';
import Heading from '../elements/typography/Heading';
import { CheckboxInput } from './CheckboxInput';
import { DateInput } from './DateInput';
import { DateTimeInput } from './DateTimeInput';
import './index.scss';
import { IArray, IFieldProps, IItemProp, INumberEnum, IString, IStringEnum, PropType, SchemaPropertyType } from './interfaces';
import { NumberInput } from './NumberInput';
import { Section } from './Section';
import { SelectInput } from './SelectInput';
import { StringInput } from './StringInput';
import { TimeInput } from './TimeInput';
export type { IItemProp as IContentFormSchema } from './interfaces';

// interface ISchema extends ISchemaProperties {}

const nth = (num: number) => {
  switch ((num > 10 && num < 20 ? num - 10 : num) % 10) {
    case 1:
      return `${num}-st`;
    case 2:
      return `${num}-nd`;
    case 3:
      return `${num}-rd`;
    default:
      return `${num}-th`;
  }
};

const mergeErrors = (path: string[], l: any): Array<{ path: string[]; value: string }> => {
  if (typeof l !== 'object') {
    return [{ path, value: l }];
  }
  const res: Array<{ path: string[]; value: string }> = [];
  for (const key in l) {
    res.push(...mergeErrors([...path, key], l[key]));
  }
  return res;
};

interface IValidationSchema {
  type: 'object';
  properties: Record<string, SomeJTDSchemaType>;
  required: string[];
}

const propConverter = (prop: PropType) => {
  const property: any = { ...prop };
  if (prop.nullable) {
    if (property.type) {
      property.type = [property.type, 'null'];
    } else {
      property.type = 'null';
    }
    if (property.enum && !property.enum.includes(null)) {
      property.enum.push(null);
    }
  }
  delete property.required;
  delete property.multiline;
  delete property.suffix;
  switch (prop.type) {
    case 'object':
      property.required = [];
      property.properties = Object.keys(property.properties).reduce((acc, key) => {
        const item = property.properties[key];
        if (item.required) {
          property.required.push(key);
        }
        return Object.assign(acc, { [key]: propConverter(item) });
      }, {});
      break;
    case 'boolean':
      if (property.required) {
        if (!property.enum) {
          property.enum = [];
        }
        property.enum.push(true);
      }
      break;
    case 'array':
      const propArray = prop as IArray;
      property.items = propConverter(propArray.items);
      break;
  }
  return property;
};

const convertSchemaToValidationSchema = (schema: IItemProp): IValidationSchema => {
  const res: IValidationSchema = { type: 'object', properties: {}, required: [] };
  for (const id in schema) {
    const property = schema[id];
    const prop = propConverter(property);
    res.properties[id] = prop;
    if (property.required) {
      res.required.push(id);
    }
  }
  return res;
};

const clearData = (data: any, isNulls: boolean = false): any => {
  if ([null, '', undefined].includes(data)) {
    if (isNulls) {
      return null;
    }
    return;
  }
  if (Array.isArray(data)) {
    return data.map(i => clearData(i, isNulls));
  }
  if (typeof data === 'object') {
    return Object.keys(data).reduce((acc, k) => {
      const v: any = clearData(data[k], isNulls);
      if (v !== undefined) {
        return Object.assign(acc, { [k]: v });
      }
      return acc;
    }, {});
  }
  return data;
};

type DataType = {
  [key: string]: string | number | boolean | DataType | DataType[];
};

type ErrorType = {
  [key: string]: string;
};

interface IComponentProps {
  title: string;
  schema: IItemProp;
  data?: DataType;
  disabled?: boolean;
  submitBtnText?: string;
  submitBtnIcon?: React.ReactNode;
  onSubmit: (data: DataType) => any | void | Promise<any | void>;
  resetBtnText?: string;
  resetBtnIcon?: React.ReactNode;
  onReset?: () => any | Promise<any>;
  onData?: (data: DataType, field: string) => any;
  onError?: (errors: ErrorType, field: string) => any;
  success?: React.FC<{ result: any }>;
  error?: React.FC<{ error: string }>;
  restore?: boolean;
}

interface IComponentState {
  isLoading: boolean;
  data: DataType;
  errors: ErrorType;
  result?: any;
  error?: string;
}

class ContentForm extends React.Component<IComponentProps, IComponentState> {
  private _defaultState = { isLoading: false, data: {}, errors: {} };

  state: IComponentState = this._defaultState;

  private myRef: any;

  private _validationSchema: IValidationSchema = { type: 'object', properties: {}, required: [] };

  private _id: string;

  constructor(props: IComponentProps) {
    super(props);
    this._id = `form:${window.location.href}:${this.props.title}:${md5(JSON.stringify(this.props.schema))}`;
    this.myRef = React.createRef();
    this._makeValidationSchema();
  }

  componentWillUnmount() {
    this.setState({ data: {} });
  }

  shouldComponentUpdate(prev: any) {
    this._compareAndSetData(prev);
    return true;
  }

  async componentDidMount() {
    let def: any = {};
    if (this.props && this.props.data) {
      const { data } = this.props;
      def = data;
    } else {
      for (const id in this.props.schema) {
        const field = this.props.schema[id];
        def[id] = this._schemaToValue(field, true);
      }
    }
    this._defaultState.data = def;
    await this.setState({ data: def });
    this._restore();
  }

  private _store() {
    if (!this.props.restore) {
      return;
    }
    set(this._id, { data: this.state.data }, 900000);
  }

  private _restore() {
    if (!this.props.restore) {
      return;
    }
    const exists = get(this._id);
    if (!exists) {
      return;
    }
    const data = { ...exists.data };

    const doIt = () => {
      this.setState({ data });
      close();
    };

    open(() => (
      <Box>
        <p>Found data for this form, want to recover?</p>
        <Stack spacing={2} direction="row">
          <Button disabled={this.props.disabled || this.state.isLoading} variant="contained" onClick={doIt}>
            Yes
          </Button>
          <Button variant="outlined" onClick={close}>
            No
          </Button>
        </Stack>
      </Box>
    ));
    del(this._id);
  }

  private _makeValidationSchema() {
    this._validationSchema = convertSchemaToValidationSchema(this.props.schema);
  }

  private _changeDataStruct(path: string, item: string | number, value?: any | DataType | undefined) {
    const data = { ...this.state.data };
    const waypoints = path.split('.');
    let res: any = data;
    for (const waypoint of waypoints) {
      if (['string', 'number', 'boolean', 'null', 'undefined'].includes(typeof res)) {
        return;
      }
      res = res[waypoint];
    }
    if (!res || typeof res !== 'object') {
      return;
    }
    const pos = parseInt(item.toString(), 10);
    if (value) {
      if (Array.isArray(res)) {
        if (pos === -1) {
          res.push(...value);
        } else {
          res.splice(pos, 0, ...value);
        }
      } else {
        res[item] = value;
      }
    } else {
      if (Array.isArray(res)) {
        if (pos === -1) {
          res.pop();
        } else {
          res.splice(pos, 1);
        }
      } else {
        delete res[item];
      }
    }
    this.setState({ data });
  }

  private _setData(path: string, value?: string | number | boolean | null | undefined) {
    const data = { ...this.state.data };
    const waypoints = path.split('.');
    const lastWP = waypoints.pop();
    if (!lastWP) {
      return;
    }
    let res: any = data;
    for (const waypoint of waypoints) {
      if (['string', 'number', 'boolean', 'null', 'undefined'].includes(typeof res)) {
        return;
      }
      res = res[waypoint];
    }
    if (value === undefined) {
      delete res[lastWP];
    } else {
      res[lastWP] = value;
    }
    this.setState({ data });
    if (this.props.onData) {
      this.props.onData(data, path);
    }
  }

  // private _getData(id: string): string | number | boolean | undefined {
  //   return this.state.data[id];
  // }

  private _setError(path: string, value?: string | undefined) {
    const errors = { ...this.state.errors };
    if (value === undefined) {
      delete errors[path];
    } else {
      errors[path] = value;
    }
    this.setState({ errors });
    if (this.props.onError && Object.keys(errors).length) {
      this.props.onError(errors, path);
    }
  }

  private _getError(path: string): string | undefined {
    return this.state.errors[path];
  }

  private _validateField(path: string, value: string | boolean | number | null) {
    const schema = this._findSchemaByPath(path, this._validationSchema.properties);
    if (schema) {
      const invalid = isInvalid(schema, value);
      if (invalid) {
        const messages = invalid.map(({ message }) => message).join('; ');
        const msg = `Error: ${messages}`;
        this._setError(path, msg);
        return false;
      }
    }
    this._setError(path);
    return true;
  }

  private _findSchemaByPath(path: string, schema: any) {
    const waypoints = path.split('.');
    const firstWP = waypoints.shift();
    if (!firstWP || !schema) {
      return;
    }
    let res = schema[firstWP];
    if (!res) {
      return;
    }
    for (const waypoint of waypoints) {
      switch (res.type) {
        case 'object':
          res = res.properties[waypoint];
          break;
        case 'array':
          if (!isNaN(parseInt(waypoint, 10))) {
            res = res.items;
          }
          break;
        default:
          return;
      }
      if (!res) {
        return;
      }
    }
    return res;
  }

  private async _changed(id: string, value: string) {
    const schema = this._findSchemaByPath(id, this.props.schema);
    if (!id || !schema) {
      return;
    }
    let val: string | boolean | null | undefined = value;
    switch (schema.type) {
      case 'string':
        if (!value) {
          val = null;
        }
        break;
      case 'boolean':
        val = stringToBoolean(value);
        break;
    }
    await this._setData(id, val);
    return val;
  }

  private async _edited(id: string, value: string) {
    const schema = this._findSchemaByPath(id, this.props.schema);
    if (!id || !schema) {
      return;
    }
    const val: string | boolean | null | undefined = await this._changed(id, value);
    if (!this._validateField(id, val === undefined ? null : val)) {
      return;
    }
  }
  private async _save() {
    this.setState({ isLoading: true });
    const schema = this._validationSchema;
    const { data } = this.state;

    const validationData = clearData(data);
    const invalid = isInvalid(schema, validationData);
    if (invalid) {
      const errors: { [key: string]: string } = {};
      for (const error of invalid) {
        const { instancePath, message, params, keyword } = error;
        let id = instancePath.replace('/', '').replace(/\//g, '.');
        let msg;
        switch (keyword) {
          case 'required':
            id += `.${params.missingProperty}`;
            msg = 'Required';
            break;
          default:
            msg = message;
            break;
        }
        if (!errors[id]) {
          errors[id] = `Error: ${msg}`;
        } else {
          errors[id] += `; ${msg}`;
        }
      }
      await this.setState({ errors, isLoading: false });
      this.myRef.current.scrollIntoView();
      return;
    }
    this.setState({ errors: {} });
    try {
      const result = await this.props.onSubmit(clearData(data, true));
      createEvent({ category: 'form', action: 'submit', label: this.props.title || 'unnamed' });
      this.setState({ isLoading: false, result });
      // show('Form sent successfully');
    } catch (e) {
      let errors = this.state.errors;
      const error = e as ResError;
      let needToScroll = false;
      switch (error.code) {
        case 401:
          this._store();
          break;
        case 422:
          if (error.meta) {
            const ers = mergeErrors([], error.meta);
            errors = ers.reduce((acc, { path, value }) => Object.assign({}, acc, { [path.join('.')]: value }), {});
            needToScroll = true;
          }
          break;
      }
      await this.setState({ isLoading: false, errors, error: (e as Error).message });
      if (needToScroll) {
        this.myRef.current.scrollIntoView();
      }
    }
  }

  private _needToReplaceData(a?: any) {
    if (((!a || !a.data) && this.props && this.props.data) || (a && a.data && (!this.props || !this.props.data))) {
      return true;
    }
    const nextData: DataType = a.data;
    const currentData: DataType = this.props.data as DataType;
    const compared: string[] = [];
    for (const key in nextData) {
      compared.push(key);
      const current = currentData[key];
      const next = nextData[key];
      if (current === undefined || current !== next) {
        return true;
      }
    }
    for (const key in currentData) {
      if (!compared.includes(key)) {
        compared.push(key);
        const current = currentData[key];
        const next = nextData[key];
        if (next === undefined || current !== next) {
          return true;
        }
      }
    }
    return false;
  }

  private _compareAndSetData(prev?: any) {
    if (this._needToReplaceData(prev)) {
      const data = prev && prev.data ? prev.data : {};
      this._defaultState.data = data;
      this.setState({ data });
    }
  }

  private _fieldToInput(id: string, field: SchemaPropertyType, value: any, level: number) {
    const error = this._getError(id);
    const hasError = !!error;

    const editedRaw = (id: string, value: any) => this._edited(id, value);
    const edited = (event: any) => editedRaw(event.target.id || event.target.name, event.target.value);
    const changed = (event: any) => this._changed(event.target.id || event.target.name, event.target.value);

    const isLoading = this.state.isLoading || this.props.disabled || false;

    const params: IFieldProps = { id, disabled: isLoading, changed, edited, editedRaw, field, error, value: value === null ? '' : value };

    let Input: typeof StringInput | typeof NumberInput | typeof DateInput | typeof TimeInput | typeof DateTimeInput | typeof SelectInput | typeof CheckboxInput;

    const ref = this.state.errors && Object.keys(this.state.errors)[0] === id ? this.myRef : null;

    switch (field.type) {
      case 'array':
        const add = () => {
          if (!this.state.isLoading && !this.props.disabled && (!field.maxItems || !value || value.length < field.maxItems)) {
            this._changeDataStruct(id, -1, this._schemaToValue(field));
          }
        };
        const rem = (i: number) => () => {
          if (!this.state.isLoading && !this.props.disabled && (!field.minItems || (value && value.length > field.minItems))) {
            this._changeDataStruct(id, i);
          }
        };

        let subtitle = field.maxItems ? `up to ${field.maxItems}` : '';
        let subtitle2 = nth((value ? value.length : 0) + 1);
        if (field.maxItems) {
          subtitle2 += ` of ${field.maxItems}`;
        }
        if (value && value.length) {
          subtitle = value.length;
          if (field.maxItems) {
            subtitle += ` of ${field.maxItems}`;
          }
        }
        const maxDisabled = field.maxItems && value && value.length >= field.maxItems;
        const minDisabled = field.minItems && value && value.length <= field.minItems;
        return (
          <Section
            ref={ref}
            key={id}
            title={field.title}
            subtitle={subtitle && `(${subtitle})`}
            description={field.description}
            level={level}
            className="array-wrapper"
            error={error}
          >
            {value && value.length > 0 && (
              <Box sx={{ mt: 2 }}>
                {value.map((v: any, i: number) => (
                  <div key={`${id}.${i}`} className="array-item-wrapper">
                    <Button
                      disabled={this.state.isLoading || this.props.disabled || minDisabled}
                      onClick={rem(i)}
                      color="error"
                      variant="contained"
                      size="small"
                      className="rem-btn"
                      sx={{ mb: -1 }}
                      tooltip={
                        minDisabled
                          ? `You cannot remove this ${field.items.title}. There must be at least ${field.minItems} entries`
                          : `Remove this ${field.items.title}`
                      }
                    >
                      <Close />
                    </Button>
                    {this._fieldToInput(`${id}.${i}`, field.items, v, level + 1)}
                  </div>
                ))}
              </Box>
            )}
            <Button
              disabled={this.state.isLoading || this.props.disabled || maxDisabled}
              onClick={add}
              size="small"
              color="primary"
              startIcon={<Add />}
              tooltip={maxDisabled ? `You cannot add another  ${field.items.title}, ${field.maxItems} limit reached` : ''}
            >
              Add another one {field.items.title}
              {subtitle2 && ` (${subtitle2})`}
            </Button>
          </Section>
        );
      // break;
      case 'object':
        return (
          <Section ref={ref} key={id} title={field.title} description={field.description} level={level} className="object-wrapper" error={error}>
            {field.properties && Object.keys(field.properties).length && (
              <Box sx={{ mt: 2 }}>
                {Object.keys(field.properties).reduce((acc, i) => {
                  acc.push(this._fieldToInput(`${id}.${i}`, field.properties[i], value && value[i], level + 1));
                  return acc;
                }, [] as any[])}
              </Box>
            )}
          </Section>
        );
      // break;
      case 'boolean':
        Input = CheckboxInput;
        break;
      case 'number':
      case 'integer':
        Input = (field as INumberEnum).enum ? SelectInput : NumberInput;
        break;
      case 'string':
        const stringEnumField = field as IStringEnum;
        if (stringEnumField.enum) {
          Input = SelectInput;
        } else {
          switch ((field as IString).format) {
            case 'date':
              Input = DateInput;
              break;
            case 'time':
              Input = TimeInput;
              break;
            case 'date-time':
              Input = DateTimeInput;
              break;
            default:
              Input = StringInput;
          }
        }
        break;
    }
    return (
      <Box sx={{ pb: 2 }} key={id}>
        <FormControl ref={ref} disabled={isLoading} error={hasError} required={field.required} fullWidth={true}>
          <Input {...params} />
          {field.description ? (
            <>
              <Typography
                variant="subtitle2"
                className="field-description"
                sx={{
                  borderWidth: 1,
                  borderTopWidth: 0,
                  borderStyle: 'solid',
                  borderColor: 'grey.400',
                  borderBottomLeftRadius: '4px',
                  borderBottomRightRadius: '4px',
                  px: 2,
                  py: 1,
                }}
              >
                {field.description}
              </Typography>
              <Divider sx={{ pt: 2 }} />
            </>
          ) : null}
        </FormControl>
      </Box>
    );
  }

  private _schemaToValue(field: SchemaPropertyType, isInit: boolean = false): any {
    switch (field.type) {
      case 'object':
        return Object.keys(field.properties).reduce((acc, key: string) => {
          return Object.assign({}, { [key]: this._schemaToValue(field.properties[key], true) }, acc);
        }, {});
      case 'array':
        if (!isInit) {
          return [this._schemaToValue(field.items, true)];
        }
        const arr = [];
        for (let i = 0; i < (field.minItems ? field.minItems : 0); i += 1) {
          arr.push(this._schemaToValue(field.items, true));
        }
        return arr;
      default:
        return null;
    }
  }

  render() {
    const disabled = this.state.isLoading || this.props.disabled || false;

    if (!disabled) {
      if (this.state.result && this.props.success) {
        const SuccessPage = this.props.success;
        return <SuccessPage result={this.state.result} />;
      }
      if (this.state.error && this.props.error) {
        const ErrorPage = this.props.error;
        return <ErrorPage error={this.state.error} />;
      }
    }

    const save = () => this._save();
    const onClickReset = async () => {
      await this.setState({ ...this._defaultState });
      if (this.props.onReset) {
        this.props.onReset();
      }
    };
    const inputs = [];
    for (const id in this.props.schema) {
      const field = this.props.schema[id];
      const value = this.state.data[id];
      const res = this._fieldToInput(id, field, value, 0);
      if (res) {
        inputs.push(res);
      }
    }
    inputs.push(
      <FormControl key="buttons" fullWidth={true} sx={{ mt: 1 }}>
        <Stack spacing={2} direction="row">
          <Button disabled={disabled} variant="contained" onClick={save} startIcon={this.props.submitBtnIcon}>
            {this.props.submitBtnText || 'Send'}
          </Button>
          <Button disabled={disabled} variant="outlined" onClick={onClickReset} startIcon={this.props.resetBtnIcon}>
            {this.props.resetBtnText || 'Cancel'}
          </Button>
        </Stack>
      </FormControl>,
    );

    return (
      <Box className="content-form">
        <Heading>{this.props.title}</Heading>
        {inputs}
      </Box>
    );
  }
}

export default ContentForm;
