import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import Heading from '../elements/typography/Heading';
import Preloader from '../Preloader';

export class Page extends React.Component<{
  isLoading: boolean;
  article?: {
    title: string;
    content: string;
  };
  error: {
    code: string | number;
    message: string;
  } | null;
  children?: any;
  sx?: SxProps;
}> {
  render() {
    return (
      <Box sx={this.props.sx}>
        <Preloader
          isLoading={this.props.isLoading}
          fallback={
            this.props.error && (
              <>
                <Heading>Error</Heading>
                <Typography component="div" variant="body1">
                  {this.props.error.code === 404 ? 'It seems this article is not ready yet' : `${this.props.error.code}: ${this.props.error.message}`}
                </Typography>
              </>
            )
          }
        >
          {this.props.article && (
            <Box mb={4}>
              <Heading>{this.props.article.title}</Heading>
              <Typography component="div" variant="body1">
                {<div dangerouslySetInnerHTML={{ __html: this.props.article.content }} />}
              </Typography>
              {this.props.children && <Box mt={4}>{this.props.children}</Box>}
            </Box>
          )}
        </Preloader>
      </Box>
    );
  }
}
