import React from 'react';
import Article from '../../components/Article';

export { default as Secondary } from './Secondary';

class Vacancies extends React.Component {
  render() {
    return <Article alias="refferal" key="article" />;
  }
}

export default Vacancies;
