import { EOAuth, ESpecificRoles } from '../../constants/user';
import IUser, { IAccount, IRemaining, ISlack, IStats, UsedDetailsType } from '../../interfaces/user';
import request from '../request';

const userApi = request('users');

export const getLoginLink = async (via: EOAuth): Promise<string> => {
  const { link } = await userApi(`auth/${via}`, 'get');
  return link;
};

export const exchangeCode = async (via: EOAuth, code: string): Promise<{ token: string }> => {
  const data: any = { code };
  const { token } = await userApi(`auth/${via}`, 'post', undefined, data);
  return { token };
};

export const getMy = async (): Promise<IUser> => {
  const user = await userApi('my');
  return user;
};

export const getMyStats = async (): Promise<IStats> => {
  const stats = await userApi('my/stats');
  return { ...stats, startWorkingDate: new Date(stats.startWorkingDate), birthDate: new Date(stats.birthDate) };
};

export const getMySlack = async (): Promise<ISlack> => {
  const slack = await userApi('my/slack');
  return slack;
};

export const deleteMyConnection = async (via: EOAuth): Promise<ISlack> => {
  const slack = await userApi(`my/${via}`, 'delete');
  return slack;
};

export const getMyOvertime = async (): Promise<UsedDetailsType> => {
  const vacation = await userApi('my/overtime');
  return vacation;
};

export const getMyVacation = async (): Promise<IRemaining> => {
  const vacation = await userApi('my/vacation');
  return vacation;
};

export const getMyVacationUsed = async (): Promise<UsedDetailsType> => {
  const sickLeave = await userApi('my/vacation/used');
  return sickLeave;
};

export const getMySickLeave = async (): Promise<IRemaining> => {
  const sickLeave = await userApi('my/sick-leave');
  return sickLeave;
};

export const getMySickLeaveUsed = async (): Promise<UsedDetailsType> => {
  const sickLeave = await userApi('my/sick-leave/used');
  return sickLeave;
};

export const checkEmail = async (emailRecipient: string, isTeamMember: boolean): Promise<void> => {
  await userApi('check', 'post', undefined, { emailRecipient, isTeamMember });
};

export const getSuggestions = async (firstName: string, lastName: string, isTeamMember: boolean): Promise<string[]> => {
  return userApi('suggest', 'post', undefined, { firstName, lastName, isTeamMember, suggestionLimit: 5 });
};

export const create = async (
  firstName: string,
  lastName: string,
  emailRecipient: string,
  isTeamMember: boolean,
): Promise<{ email: string; password: string }> => {
  const { email, password } = await userApi('', 'post', undefined, { firstName, lastName, isTeamMember, emailRecipient });
  return { email, password };
};

export const get = async (): Promise<IAccount[]> => {
  const users = await userApi('', 'get');
  return users;
};

export const assignRole = async (uid: number, role: ESpecificRoles): Promise<void> => {
  await userApi('roles', 'post', undefined, { uid, role });
};

export const unassignRole = async (uid: number, role: ESpecificRoles): Promise<void> => {
  await userApi('roles', 'delete', undefined, { uid, role });
};
