import { Box, Tab, Tabs as MuiTabs } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

interface IState {
  id: number;
  content: React.ReactNode;
}

export interface ITab {
  title: string;
  content: () => React.ReactNode;
}

class Tabs extends React.Component<{ tabs: ITab[]; sx?: SxProps }, IState> {
  state: IState;

  private _makeTabState = (id: number): IState => ({ id, content: this.props.tabs[id].content() });

  private async _setTab(id: number) {
    await this.setState({ id, content: null });
    await this.setState(this._makeTabState(id));
  }

  constructor(props: any) {
    super(props);
    this.state = this._makeTabState(0);
  }

  render() {
    const handleChange = (e: any, id: number) => {
      this._setTab(id);
    };

    return (
      <Box sx={this.props.sx}>
        <Box>
          <MuiTabs value={this.state.id} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {this.props.tabs.map((tab, i) => (
              <Tab label={tab.title} key={tab.title} value={i} />
            ))}
          </MuiTabs>
        </Box>
        <Box className={`tab-${this.state.id}`}>{this.state.content}</Box>
      </Box>
    );
  }
}

export default Tabs;
