import { ERoles } from '../../constants/user';
import IUser from '../../interfaces/user';

const acl = (acl: Array<ERoles | null> | null | undefined, user: IUser | null | undefined) => {
  if (!acl) {
    return true; // acl is not required
  }
  if (!user && acl.includes(null)) {
    return true; // guests allowed
  }
  if (user) {
    // authorized users only section
    if (!acl.length) {
      // empty acl - all authorized users
      return true;
    }
    for (const role of user.roles) {
      if (role === ERoles.GOD || acl.includes(role as ERoles)) {
        // check for specific role
        return true;
      }
    }
  }
  return false;
};

export default acl;
