import React from 'react';
import Article from '../../components/Article';

export { default as Secondary } from './Secondary';

const ProblemManager = () => {
  return (
    <>
      <Article alias="accessToServices" />
    </>
  );
};

export default ProblemManager;
