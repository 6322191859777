import React from 'react';
import Full from '../Full';
import NotFound from '../NotFound';

class Fallback extends React.Component {
  render() {
    return (
      <Full>
        <NotFound />
      </Full>
    );
  }
}
export default Fallback;
