import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ParsedUrlQueryInput } from 'querystring';
import { parseStringPromise } from 'xml2js';
import { show } from '../../stores/alert';
import { finish, start } from '../../stores/progress';
import { ResError } from '../request';

const request = async (url: string = '/', query?: ParsedUrlQueryInput) => {
  start(1);
  const config: AxiosRequestConfig = {
    url,
    method: 'get',
    params: query || {},
  };
  try {
    const { data } = await axios(config);
    return data;
  } catch (e) {
    const error: AxiosError = e as AxiosError;

    let message = error.message;
    let code: string | number = error.code || 500;

    if (error.response) {
      message = error.response.statusText;
      if (error.response.status) {
        code = error.response.status;
      }
      if (error.response.data && error.response.data.message) {
        message = error.response.data.message;
        if (error.response.data.code) {
          code = error.response.data.code;
        }
      }
    }
    show(message, 'error');
    throw new ResError(message, code);
  } finally {
    finish(1);
  }
};

const deArrayFeed = (feed: any): any => {
  if (!feed) {
    return feed;
  }
  if (Array.isArray(feed)) {
    if (feed.length === 1) {
      return deArrayFeed(feed[0]);
    }
    return feed.map(deArrayFeed);
  }
  if (typeof feed === 'object' && !(feed instanceof Date)) {
    return Object.keys(feed).reduce((acc, key) => {
      return Object.assign({}, acc, { [key]: deArrayFeed(feed[key]) });
    }, {});
  }
  return feed;
};

export const getVacancionsFeed = async () => {
  const xml = await request('https://jobs.dou.ua/vacancies/codemotion/feeds/');
  const feed = await parseStringPromise(xml);
  const obj = deArrayFeed(feed.rss.channel);
  return obj;
};
