import { Add, Block, Delete, Info, Lock } from '@mui/icons-material';
import { Box, Chip, Link } from '@mui/material';
import React from 'react';
import { get } from '../../../apis/user';
import ACL from '../../../components/ACL';
import ContentTable, { ContentTableColsType } from '../../../components/ContentTable';
import IconButton from '../../../components/elements/IconButton';
import Slack from '../../../components/elements/icons/Slack';
import Tooltip from '../../../components/elements/Tooltip';
import Heading from '../../../components/elements/typography/Heading';
import Preloader from '../../../components/Preloader';
import { ERoles, ESpecificRoles, specificRoles } from '../../../constants/user';
import { IAccount } from '../../../interfaces/user';
import { add, remove } from '../../../stores/float-buttons';
import convertRole from '../../../utils/convert-role';
import makeBtnColorSx from '../../../utils/make-btn-color-sx';
import './index.scss';
import Manage from './Manage';

interface IState {
  users: IAccount[];
  isLoading: boolean;
}

class List extends React.Component<{}, IState> {
  state: IState = { users: [], isLoading: false };

  // constructor(props: any) {
  //   super(props);
  // }

  btnKey?: any;

  async componentDidMount() {
    this.btnKey = add({ title: 'Create new Account', icon: <Add />, href: '/hr/accounts/create' });
    this._loadUsers();
  }

  private async _loadUsers() {
    try {
      await this.setState({ isLoading: true });
      const users = await get();
      await this.setState({ users, isLoading: false });
    } catch (e) {
      await this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    if (this.btnKey) {
      remove(this.btnKey);
    }
  }

  render() {
    const doNothing = () => {
      // do nothing
    };
    const reloadUsers = async () => {
      await this._loadUsers();
    };

    const cols: ContentTableColsType = [
      {
        title: 'ID',
        getValue: ({ user: { id } }) => (
          <IconButton tooltip={id}>
            <Info />
          </IconButton>
        ),
      },
      {
        title: 'First name',
        getValue: ({
          user: {
            name: { firstName },
          },
        }) => (firstName && firstName !== firstName.trim() ? `'${firstName}'` : firstName),
      },
      {
        title: 'Last name',
        getValue: ({
          user: {
            name: { lastName },
          },
        }) => (lastName && lastName !== lastName.trim() ? `'${lastName}'` : lastName),
      },
      {
        title: 'Email',
        getValue: ({ user: { email } }) =>
          email && email.length > 20 ? (
            <>
              {email.substr(0, 13)}...{' '}
              <IconButton tooltip={email}>
                <Info />
              </IconButton>
            </>
          ) : (
            email
          ),
      },
      {
        title: 'Slack',
        getValue: ({ slack }) =>
          slack && (
            <Chip
              component={Link}
              sx={makeBtnColorSx('Chip', '#611f69', '#FFF')}
              target="_blank"
              href={`https://app.slack.com/team/${slack.id}`}
              icon={<Slack />}
              size="small"
              disabled={this.state.isLoading}
              label={slack.nickname ? `@${slack.nickname}` : slack.id}
              deleteIcon={
                <>
                  {[
                    slack.isForced && (
                      <Tooltip title="Assigned manually">
                        <Lock />
                      </Tooltip>
                    ),
                    slack.isBlocked && (
                      <Tooltip title="Blocked">
                        <Block />
                      </Tooltip>
                    ),
                    slack.isDeleted && (
                      <Tooltip title="Deleted">
                        <Delete />
                      </Tooltip>
                    ),
                  ]}
                </>
              }
              onClick={doNothing}
              onDelete={slack.isForced || slack.isBlocked || slack.isDeleted ? doNothing : undefined}
            />
          ),
      },
      {
        title: 'Roles',
        getValue: ({ user, user: { roles } }) => (
          <Box mb={-1}>
            {roles.map((role: ERoles | ESpecificRoles) => (
              <Chip sx={{ mr: 1, mb: 1 }} key={role} color={specificRoles.includes(role) ? 'primary' : 'secondary'} label={convertRole(role)} size="small" />
            ))}
            <ACL roles={[ERoles.ADMIN]}>
              <Manage user={user} reloadUsers={reloadUsers} disabled={this.state.isLoading} sx={{ mr: 1, mb: 1 }} />
            </ACL>
          </Box>
        ),
      },
    ];
    return (
      <>
        <Heading>Available Account list</Heading>
        <Preloader
          isLoading={this.state.isLoading && this.state.users.length === 0}
          fallback={this.state.users.length === 0 && <>There are no available users</>}
        >
          <ContentTable sx={{ maxWidth: '100%' }} cols={cols} data={this.state.users} />
        </Preloader>
      </>
    );
  }
}

export default List;
