import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { parse } from 'querystring';
import React from 'react';
import { exchangeCode } from '../../../apis/user';
import Button from '../../../components/elements/Button';
import TryAgain from '../../../components/elements/TryAgain';
import Preloader from '../../../components/Preloader';
import { StateContext } from '../../../stores';

import './index.scss';

@observer
class Authorize extends React.Component<any> {
  static contextType = StateContext;
  state: { completed: boolean; error: boolean } = { completed: false, error: false };
  private toReload: any;
  async componentDidMount() {
    const query = window.location.search.substring(1);
    const { code, state } = parse(query) as { code?: string; state?: string };
    if (code) {
      try {
        const via = this.context.navigation.options.params.via;
        const { token } = await exchangeCode(via, code);
        await this.context.auth.login(token);
        this.toReload = setTimeout(() => {
          window.location.href = state || '/';
        }, 2000);
        this.setState({ completed: true });
        return;
      } catch (e) {
        this.setState({ error: true, completed: true });
      }
    }
    this.setState({ error: true, completed: true });
  }
  componentWillUnmount() {
    clearTimeout(this.toReload);
  }

  render() {
    return (
      <Preloader isLoading={!this.state.completed}>
        {this.state.error ? (
          <div className="Login">
            <header>
              <TryAgain />
              <Box mt={1}>
                <Button variant="contained" color="secondary" href="/login" sx={{ mx: 1 }}>
                  Try again
                </Button>
                or
                <Button variant="contained" color="primary" href="/" sx={{ mx: 1 }}>
                  Go Home
                </Button>
              </Box>
            </header>
          </div>
        ) : (
          <div className="Login">
            <header>
              <div>Almost done. Please wait another seconds</div>
            </header>
          </div>
        )}
      </Preloader>
    );
  }
}

export default Authorize;
