import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { ExampleButtons } from './ExampleButtons';
import { IFieldProps, INumber, IProp } from './interfaces';

export const NumberInput = ({ id, disabled, changed, edited, field, error, value }: IFieldProps) => {
  const num = field as INumber & IProp;
  const val = (value as number | undefined) || null;
  const hasError = !!error;
  return (
    <>
      <TextField
        id={id}
        type="number"
        error={hasError}
        disabled={disabled}
        onChange={changed}
        onBlur={edited}
        label={num.title}
        required={num.required}
        variant="outlined"
        value={val}
        InputProps={{
          endAdornment: <InputAdornment position="end">{num.suffix}</InputAdornment>,
        }}
      />
      {num.examples && <ExampleButtons disabled={disabled} examples={num.examples} id={id} changed={changed} />}
      <FormHelperText>{error}</FormHelperText>
    </>
  );
};
