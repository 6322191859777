import { GroupAdd, People } from '@mui/icons-material';
import React from 'react';
import { ERoles } from '../../constants/user';
import IRouteItems from '../../interfaces/routing';

const items: IRouteItems = {
  contacts: {
    title: 'Accounts',
    icon: <People />,
    description: 'Manage company accounts',
    menu: false,
    items: {
      '': {
        title: 'Account List',
        icon: <People />,
        redirect: '/hr/accounts',
        roles: [ERoles.USER_MANAGER],
        description: 'View list of all existing company accounts',
      },
      create: {
        title: 'Create new Account',
        icon: <GroupAdd />,
        redirect: '/hr/accounts/create',
        roles: [ERoles.USER_MANAGER],
        description: 'Make new account for new team members or outsourcers/outstaffers',
      },
    },
  },
};

export default items;
