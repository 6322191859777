import { Avatar, ListItemAvatar, ListItemText, MenuItem as MuiMenuItem, MenuItemProps } from '@mui/material';
import React from 'react';
import { goTo } from '../../../stores/navigation';
// import { redirect } from '../../stores/routing';

interface IProps extends MenuItemProps {
  icon: React.ReactNode;
  title: string;
  description?: string;
  href?: string;
  children?: never;
}

class MenuItem extends React.Component<IProps, any> {
  render() {
    let onClick = this.props.onClick;
    if (this.props.href !== undefined && !this.props.onClick) {
      onClick = (e: any) => {
        if (this.props.href !== undefined) {
          e.preventDefault();
          goTo(this.props.href);
        }
      };
    }
    return (
      <MuiMenuItem {...this.props} onClick={onClick}>
        <ListItemAvatar>
          <Avatar>{this.props.icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={this.props.title} secondary={this.props.description} />
      </MuiMenuItem>
    );
  }
}

export default MenuItem;
