import { Divider } from '@mui/material';
import React from 'react';
import Copy from '../../../components/elements/Copy';
import Link from '../../../components/elements/Link';

const Instruction = ({ bot }: { bot: { displayName: string; username: string } }) => {
  return (
    <div>
      <p>
        1. For the bot to send messages to a specific channel add bot with username <Copy label={bot.username} />
        (displayed name is <b>{bot.displayName}</b>) to the channels that you want to receive the newsletter.
      </p>
      <p>2. Make the bot the admin of the channel (Telegram will ask for this itself, otherwise the bot cannot be added to the channel)</p>
      <p>
        3. Immediately after adding, the bot should write to this channel something like <i>"Hello. Current channel successfully saved to subscription list"</i>
        . If the bot didn't write anything, try to write something to this channel.
      </p>
      <p>
        If the bot wrote a message to the channel, then the channel has been successfully added to the list of subscriptions and will receive messages that you
        can send using the form below
      </p>
      <p>
        If the bot did not write anything, then most likely something went wrong, <Link href="/feedback">contact the developer</Link>
      </p>
      <Divider />
      <p>To prevent the bot from sending messages to a specific channel - remove it from this channel</p>
    </div>
  );
};

export default Instruction;
