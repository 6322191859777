import { Typography } from '@mui/material';
import React from 'react';
import pic from './speedwagon.jpg';

class Speedwagon extends React.Component {
  render() {
    return (
      <>
        <p>We are sorry, but specified page was not found</p>
        <img alt="Robert E.O. Speedwagon" src={pic} />
        <Typography sx={{ mt: 1, mb: 2 }} variant="body2">
          This message sponsored by Speedwagon Foundation
        </Typography>
      </>
    );
  }
}
export default Speedwagon;
