import { AxiosError } from 'axios';
import { get } from '../../apis/system';
import Cache from '../memory';

const cache = new Cache({ maxAge: undefined });

const init = async () => {
  const { build } = await get();
  cache.set('build', build);
};

init();

let promise: Promise<boolean> | null;

const checkVersion = async (): Promise<boolean> => {
  try {
    if (!promise) {
      promise = (async () => {
        const { build } = await get();
        setTimeout(() => {
          promise = null;
        }, 2000);
        return build === cache.get('build');
      })();
    }
    return promise;
  } catch (e) {
    const error = e as AxiosError;
    if (error.message === 'Network Error' && !error.response) {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            resolve(await checkVersion());
          } catch (e) {
            reject(e);
          }
        }, 2000);
      });
    }
  }
  return false;
};

export default checkVersion;
