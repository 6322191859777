import { Add } from '@mui/icons-material';
import { Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import React from 'react';
import { create as createUser, getSuggestions } from '../../../apis/user';
import Button from '../../../components/elements/Button';
import Heading from '../../../components/elements/typography/Heading';
import { createEvent } from '../../../utils/analitycs';
import isInvalid from '../../../utils/is-invalid';
import strToBoolean from '../../../utils/string-to-boolean';
import './index.scss';
import Created from './partials/Created';
import SuggestionList from './partials/SuggestionList';

export { default as Secondary } from './Instruction';

const clearErrorState = {};

const defaultState = {
  data: { firstName: '', lastName: '', emailRecipient: '', isTeamMember: true },
  errors: clearErrorState,
  suggestions: [],
  loading: false,
  required: true,
  user: null,
};

class Create extends React.Component<any> {
  state: {
    data: { [key: string]: string | number | boolean };
    errors: { [key: string]: string };
    suggestions: string[];
    loading: boolean;
    required: boolean;
    user: { email: string; password: string } | null;
  } = { ...defaultState };

  // constructor(props: any) {
  //   super(props);
  // }

  private _setData(id: string, value: string | number | boolean) {
    this.setState({ data: { ...this.state.data, [id]: value } });
  }

  private _setError(id: string, value: string | number | boolean) {
    this.setState({ errors: { ...this.state.errors, [id]: value } });
  }

  private _validationShema: any = {
    type: 'object',
    properties: {
      firstName: { type: 'string', minLength: 1, maxLength: 20, pattern: '^[A-z0-9\\s]+$' },
      lastName: { type: 'string', minLength: 1, maxLength: 20, pattern: '^[A-z0-9\\s]+$' },
      isTeamMember: { type: 'boolean' },
      emailRecipient: { type: 'string', minLength: 1, maxLength: 20, pattern: '^[a-z]+$' },
    },
    required: ['firstName', 'lastName', 'isTeamMember', 'emailRecipient'],
  };

  private _canGetSuggestions() {
    const { firstName, lastName } = this.state.data;
    return !!(firstName && lastName);
  }

  private async _getSuggestions() {
    this.setState({ loading: true });
    const { firstName, lastName, isTeamMember } = this.state.data;
    if (this._canGetSuggestions()) {
      try {
        const suggestions = await getSuggestions(firstName as string, lastName as string, isTeamMember as boolean);
        this.setState({ suggestions, required: false });
        // if (!this.state.data.emailRecipient) {
        this._edited('emailRecipient', suggestions[0]);
        // }
      } catch (e) {
        // do nothing
      }
    }
    this.setState({ loading: false });
  }

  private _validateField(id: string, value: string | number | boolean) {
    const schema = this._validationShema.properties[id];
    if (schema) {
      const invalid = isInvalid(schema, value);
      if (invalid) {
        const messages = invalid.map(({ message }) => message).join('; ');
        const msg = `Error: ${messages}`;
        this._setError(id, msg);
        return false;
      }
    }
    this._setError(id, '');
    return true;
  }

  private async _changed(id: string, value: string) {
    let val: string | boolean = value;
    // pre
    switch (id) {
      case 'firstName':
      case 'lastName':
        await this.setState({ required: true });
        break;
      case 'emailRecipient':
        break;
      case 'isTeamMember':
        val = strToBoolean(value);
        await this.setState({ required: true });
        break;
    }
    await this._setData(id, val);
    return val;
  }

  private async _edited(id: string, value: string) {
    const val: string | boolean = await this._changed(id, value);
    if (!this._validateField(id, val)) {
      return;
    }
    // post
    switch (id) {
      case 'firstName':
      case 'lastName':
        await this._getSuggestions();
        break;
      case 'emailRecipient':
        break;
      case 'isTeamMember':
        await this._getSuggestions();
        break;
    }
  }

  private async _save() {
    this.setState({ loading: true });
    const invalid = isInvalid(this._validationShema, this.state.data);
    if (invalid) {
      const errors: { [key: string]: string } = {};
      for (const error of invalid) {
        const { instancePath, message } = error;
        const id = instancePath.replace(/^\//, '').split('/')[0];
        if (!errors[id]) {
          errors[id] = `Error: ${message}`;
        } else {
          errors[id] += `; ${message}`;
        }
      }
      this.setState({ errors, loading: false });
      return;
    }
    this.setState({ errors: clearErrorState });
    try {
      const user = await createUser(
        this.state.data.firstName as string,
        this.state.data.lastName as string,
        this.state.data.emailRecipient as string,
        this.state.data.isTeamMember as boolean,
      );
      createEvent({ category: 'account', action: 'created' });
      this.setState({ user, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const onClickReset = () => this.setState({ ...defaultState });
    if (this.state.user) {
      return <Created user={this.state.user} reset={onClickReset} />;
    }
    const edited = (event: any) => this._edited(event.target.id, event.target.value);
    const changed = (event: any) => this._changed(event.target.id, event.target.value);
    const save = () => this._save();
    const email = `${!this.state.data.isTeamMember ? '.k' : ''}@codemotion.us`;
    const suggestionsCallback = (id: string, suggestion: string) => this._edited(id, suggestion);
    return (
      <div className="create-user-form" key="form">
        <Heading>Create new account form</Heading>
        <FormControl error={!!this.state.errors.isTeamMember} component="fieldset" fullWidth={true} sx={{ m: 1 }}>
          <FormControlLabel
            control={<Checkbox id="isTeamMember" value={!!this.state.data.isTeamMember} checked={!!this.state.data.isTeamMember} />}
            disabled={this.state.loading}
            onClick={edited}
            label="Is team member"
          />
          {this.state.errors.isTeamMember && <FormHelperText>{this.state.errors.isTeamMember}</FormHelperText>}
        </FormControl>
        <Divider />
        <FormControl
          error={!!this.state.errors.firstName}
          disabled={this.state.loading}
          sx={{ m: theme => theme.spacing(1), mr: 0, width: theme => `calc(50% - ${theme.spacing(1.5)})` }}
        >
          <InputLabel htmlFor="firstName">First name</InputLabel>
          <OutlinedInput
            id="firstName"
            value={this.state.data.firstName}
            label="First name"
            error={!!this.state.errors.firstName}
            onChange={changed}
            onBlur={edited}
          />
          <FormHelperText>{this.state.errors.firstName}</FormHelperText>
        </FormControl>
        <FormControl
          error={!!this.state.errors.lastName}
          disabled={this.state.loading}
          sx={{ m: theme => theme.spacing(1), mr: 0, width: theme => `calc(50% - ${theme.spacing(1.5)})` }}
        >
          <InputLabel htmlFor="lastName">Last name</InputLabel>
          <OutlinedInput id="lastName" value={this.state.data.lastName} label="Last name" onChange={changed} onBlur={edited} />
          <FormHelperText>{this.state.errors.lastName}</FormHelperText>
        </FormControl>
        <Divider />
        <FormControl
          error={!!this.state.errors.emailRecipient}
          disabled={this.state.loading}
          fullWidth={true}
          sx={{ m: theme => theme.spacing(1), width: theme => `calc(100% - ${theme.spacing(2)})` }}
        >
          <InputLabel htmlFor="emailRecipient">Email</InputLabel>
          <OutlinedInput
            id="emailRecipient"
            value={this.state.data.emailRecipient}
            label="Email"
            onChange={changed}
            onBlur={edited}
            endAdornment={<InputAdornment position="end">{email}</InputAdornment>}
          />
          <FormHelperText>{this.state.errors.emailRecipient}</FormHelperText>
        </FormControl>
        <FormControl fullWidth={true}>
          <SuggestionList
            suggestions={this.state.suggestions}
            isLoading={this.state.loading}
            isRequired={this.state.required}
            isCanGet={this._canGetSuggestions()}
            callback={suggestionsCallback}
          />
        </FormControl>
        <Divider />
        <FormControl error={!!this.state.errors.createSlack} component="fieldset" fullWidth={true} sx={{ m: 1 }}>
          <FormControlLabel
            control={<Checkbox id="createSlack" value={!!this.state.data.createSlack} checked={!!this.state.data.createSlack} />}
            disabled={true || this.state.loading}
            onClick={edited}
            label="Create slack account"
          />
          {this.state.errors.createSlack && <FormHelperText>{this.state.errors.createSlack}</FormHelperText>}
        </FormControl>
        <Divider />
        <FormControl error={!!this.state.errors.createJira} component="fieldset" fullWidth={true} sx={{ m: 1 }}>
          <FormControlLabel
            control={<Checkbox id="createJira" value={!!this.state.data.createJira} checked={!!this.state.data.createJira} />}
            disabled={true || this.state.loading}
            onClick={edited}
            label="Create Jira account"
          />
          {this.state.errors.createJira && <FormHelperText>{this.state.errors.createJira}</FormHelperText>}
        </FormControl>
        <Divider />
        <FormControl fullWidth={true} sx={{ m: 1 }}>
          <Stack spacing={2} direction="row">
            <Button disabled={this.state.loading} variant="contained" onClick={save} startIcon={<Add />}>
              Create
            </Button>
            <Button disabled={this.state.loading} variant="outlined" onClick={onClickReset}>
              Cancel
            </Button>
          </Stack>
        </FormControl>
      </div>
    );
  }
}

export default Create;
