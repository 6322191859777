import { alpha, darken, getContrastRatio, lighten, SxProps } from '@mui/system';

const makeBtnColorSx = (type: 'Fab' | 'Button' | 'Chip' = 'Button', bgColor: string, color: string): SxProps => {
  let hover;
  let transparent;
  if (bgColor) {
    hover = darken(bgColor, 0.2);
    if (getContrastRatio(bgColor, hover) < 1.1) {
      hover = lighten(bgColor, 0.1);
    }
    transparent = alpha(hover, 0.1);
  }
  const style = {
    [`&.Mui${type}-root:not(.Mui-disabled)`]: {
      // [`&.Mui${type}-contained`]: {
      color,
      backgroundColor: bgColor,
      '&:hover': {
        backgroundColor: hover,
      },
      // },
      [`&.Mui${type}-text`]: {
        color: bgColor,
        backgroundColor: 'transparent',
        '&:hover': {
          color: hover,
          backgroundColor: transparent,
        },
      },
      [`&.Mui${type}-outlined`]: {
        color: bgColor,
        backgroundColor: 'transparent',
        borderColor: bgColor,
        '&:hover': {
          color: hover,
          borderColor: hover,
          backgroundColor: transparent,
        },
      },
      '.MuiSvgIcon-root': {
        color: 'inherit',
      },
    },
  };
  return style;
};

export default makeBtnColorSx;
