import { CategoryType } from '../../constants/communication';
import { IData } from '../../interfaces/api';
import request from '../request';

const messageApi = request('communications');

export const send = async (category: CategoryType, message: string, fields?: Array<{ title: string; value: string }>): Promise<void> => {
  const data: IData = { category, message, fields: fields || [] };
  await messageApi('', 'post', undefined, data);
};
