import { Article as ArticleIcon } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { SxProps } from '@mui/system';
import format from 'date-fns/format';
import React from 'react';
import { getBlogByAlias, getBlogItemByIdAndParentAlias, getByAlias, getFAQByAlias } from '../../apis/article';
import { ResError } from '../../apis/request';
import { ArticleAliasType, BlogAliasType, FAQAliasType } from '../../constants/article';
import { dateTimeFormat } from '../../constants/date';
import { IBlog, IFAQ } from '../../interfaces/article';
import MenuItem from '../elements/MenuItem';
import FAQ from '../FAQ';
import { Page } from './Page';

interface IState {
  isLoading: boolean;
  article?: {
    title: string;
    content: string;
    link: string;
    items?: Array<{ id: number; title: string; content?: string; link: string }>;
  };
  error: {
    code: string | number;
    message: string;
  } | null;
}

type ArticleType =
  | { type?: 'article'; alias: ArticleAliasType }
  | { type: 'blog'; alias: BlogAliasType }
  | { type: 'faq'; alias: FAQAliasType }
  | { type: 'blog-item'; alias: BlogAliasType; id: number };

class Article extends React.Component<{ sx?: SxProps } & ArticleType, IState> {
  state: IState = { isLoading: true, error: null };

  async componentDidMount() {
    try {
      let article;
      switch (this.props.type) {
        case 'blog':
          article = await getBlogByAlias(this.props.alias);
          break;
        case 'blog-item':
          article = await getBlogItemByIdAndParentAlias(this.props.alias, this.props.id);
          break;
        case 'faq':
          article = await getFAQByAlias(this.props.alias);
          break;
        case 'article':
        default:
          article = await getByAlias(this.props.alias);
          break;
      }
      this.setState({ isLoading: false, article });
    } catch (e) {
      const { message, code } = e as ResError;
      this.setState({ isLoading: false, error: { code, message } });
    }
  }

  render() {
    let children: React.ReactNode = null;
    if (this.state.article) {
      switch (this.props.type) {
        case 'faq':
          const faq = this.state.article as IFAQ;
          children = <FAQ questions={faq.items.map(({ title: question, content: answer }) => ({ question, answer }))} />;
          break;
        case 'blog':
          const blog = this.state.article as IBlog;
          children = blog.items.map(({ id, title, createdAt }, i) => (
            <>
              {i > 0 && <Divider />}
              <MenuItem
                key={id}
                icon={<ArticleIcon />}
                href={`/company/digest/${id}`}
                title={title}
                description={`Created at ${format(createdAt, dateTimeFormat)}`}
              />
            </>
          ));
          break;
      }
    }
    return (
      <Page article={this.state.article} isLoading={this.state.isLoading} error={this.state.error}>
        {children}
      </Page>
    );
  }
}

export default Article;
