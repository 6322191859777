import { Add, Delete, Edit, Send, Update } from '@mui/icons-material';
import React from 'react';
import { deleteById, get, testById } from '../../../apis/message';
import ContentTable, { ContentTableColsType } from '../../../components/ContentTable';
import Button from '../../../components/elements/Button';
import IconButton from '../../../components/elements/IconButton';
import Heading from '../../../components/elements/typography/Heading';
import Preloader from '../../../components/Preloader';
import { IMessage } from '../../../interfaces/message';
import { show } from '../../../stores/alert';
import { add, remove } from '../../../stores/float-buttons';
import './index.scss';

export { default as Secondary } from './Instruction';

class List extends React.Component<any> {
  state: {
    messages: IMessage[];
    isLoading: boolean;
    isWaiting: boolean;
  } = { messages: [], isLoading: true, isWaiting: false };

  // constructor(props: any) {
  //   super(props);
  // }

  private async _loadMessages() {
    try {
      await this.setState({ isLoading: true });
      const messages = await get();
      this.setState({ messages, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  btnKey?: any;

  componentDidMount() {
    this.btnKey = add({ title: 'Create new Message', icon: <Add />, href: '/hr/messages/create' });
    this._loadMessages();
  }

  componentWillUnmount() {
    if (this.btnKey) {
      remove(this.btnKey);
    }
  }

  render() {
    const reloadMessages = () => this._loadMessages();
    const makeDeleteCb = (id: number) => async () => {
      await this.setState({ isWaiting: true });
      try {
        await deleteById(id);
        show('Message was successfully deleted');
        await reloadMessages();
      } catch (e) {
        //
      }
      await this.setState({ isWaiting: false });
    };
    const makeTestCb = (id: number) => async () => {
      await this.setState({ isWaiting: true });
      try {
        await testById(id);
        show('Test message was successfully sent');
      } catch (e) {
        //
      }
      await this.setState({ isWaiting: false });
    };

    const reloadButton = (
      <Button disabled={this.state.isLoading || this.state.isWaiting} size="small" onClick={reloadMessages} variant="outlined" startIcon={<Update />}>
        Reload list
      </Button>
    );

    const cols: ContentTableColsType = [
      { title: 'ID', getValue: ({ id }) => id },
      { title: 'Title', getValue: ({ title }) => title },
      { title: 'Category', getValue: ({ category }) => category },
      { title: 'Status', getValue: ({ status }) => status },
      {
        title: '',
        textAlign: 'right',
        getValue: ({ id }) => (
          <>
            <IconButton disabled={this.state.isWaiting} onClick={makeTestCb(id)} tooltip="Send test message to your Slack">
              <Send />
            </IconButton>
            <IconButton disabled={this.state.isWaiting} href={`/hr/messages/edit/${id}`} tooltip="Edit">
              <Edit />
            </IconButton>
            <IconButton disabled={this.state.isWaiting} onClick={makeDeleteCb(id)} tooltip="Delete">
              <Delete />
            </IconButton>
          </>
        ),
      },
    ];
    return (
      <>
        <Heading>Message list</Heading>
        <Preloader
          isLoading={this.state.isLoading}
          fallback={
            this.state.messages.length === 0 && (
              <>
                <p>There are no messages</p>
                {reloadButton}
              </>
            )
          }
        >
          {this.state.messages.length > 5 && reloadButton}
          <ContentTable cols={cols} data={this.state.messages} />
          {reloadButton}
        </Preloader>
      </>
    );
  }
}

export default List;
