import { List } from '@mui/material';
import React from 'react';
import Preloader from '../../../../components/Preloader';
import { ICallbacks, IChat, IResponse } from '../../../../interfaces/outstaff';
import Subscriber from './Subscriber';

class SubscriberList extends React.Component<{ items?: IChat[]; response?: IResponse; loading: boolean } & ICallbacks> {
  state: { [key: number]: boolean } = {};

  private prevLoading?: boolean;

  componentDidUpdate() {
    const loading = this.props.loading;
    if (this.props.items) {
      if (loading !== this.prevLoading) {
        if (this.props.response) {
          this.props.items.forEach(({ uid }) => {
            setTimeout(() => this.setState({ [uid]: false }), 200 + Math.random() * 300);
          });
        } else {
          this.setState(this.props.items.reduce((acc, { uid }) => Object.assign({}, acc, { [uid]: loading }), {}));
        }
      }
    }
    this.prevLoading = loading;
  }

  render() {
    return (
      <Preloader isLoading={this.props.loading} fallback={(!this.props.items || !this.props.items.length) && <p>There are no items</p>}>
        <p>Note. This is not a definitive list. This can be changed in the process</p>
        <List sx={{ pt: 0 }}>
          {this.props.items &&
            this.props.items.map(({ uid, name, groups }) => (
              <Subscriber
                key={uid}
                uid={uid}
                name={name}
                groups={groups}
                response={this.props.response}
                loading={this.props.loading || this.state[uid]}
                addGroupToItem={this.props.addGroupToItem}
                createAndAddGroupToItem={this.props.createAndAddGroupToItem}
                removeGroupFromItem={this.props.removeGroupFromItem}
                getGroups={this.props.getGroups}
              />
            ))}
        </List>
      </Preloader>
    );
  }
}
export default SubscriberList;
