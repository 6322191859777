import React from 'react';
import pic from './honeybadger1a.gif';

class Honeybadger1 extends React.Component {
  render() {
    return (
      <>
        <img alt="Does not cares" src={pic} />
        <p>Honey badger does not cares thar this page does not exist</p>
      </>
    );
  }
}
export default Honeybadger1;
