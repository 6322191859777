import { makeAutoObservable } from 'mobx';
import React, { createContext } from 'react';

class Progress {
  private _queue: any[] = [];

  private _finished: any[] = [];

  private _timer: any;

  private _timeout: any;

  public completed: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  private _setCompleted(value: number | null) {
    this.completed = value;
  }

  private _calc() {
    clearInterval(this._timer);
    clearTimeout(this._timeout);
    this._setCompleted((this._finished.length / this._queue.length) * 100);
    const next = ((this._finished.length + 1) / this._queue.length) * 100;
    this._timer = setInterval(() => {
      if (this.completed !== null) {
        this._setCompleted(this.completed + Math.max(Math.random() * (next * 0.9 - this.completed), 0));
      }
    }, 100);

    if (this._finished.length === this._queue.length) {
      this._finished = [];
      this._queue = [];
      this._timeout = setTimeout(() => {
        this._setCompleted(null);
      }, 500);
    }
  }

  public start(count: number = 1) {
    this._queue.push(...new Array(count));
    this._calc();
  }

  public finish(count: number = 1) {
    this._finished.push(...new Array(count));
    this._calc();
  }
}

const progress = new Progress();

export const start = (count: number = 1) => progress.start(count);
export const finish = (count: number = 1) => progress.finish(count);

export const Context = createContext<Progress>(progress);
const Provider = ({ children }: any) => <Context.Provider value={progress}>{children}</Context.Provider>;

export default Provider;
