import { ArrowBack } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import Article from '../../components/Article';
import Button from '../../components/elements/Button';
import { Context } from '../../stores/navigation';

@observer
class DigestItem extends React.Component {
  static contextType = Context;

  render() {
    const id = this.context.options.params.id;
    const backButton = (
      <Box sx={{ textAlign: 'right' }}>
        <Button href="/company/digest" startIcon={<ArrowBack />}>
          Back to digest list
        </Button>
      </Box>
    );
    return (
      <div className="digest-item">
        {backButton}
        <Divider sx={{ my: 2 }} />
        <Article alias="digest" type="blog-item" id={id} />
        <Divider sx={{ my: 2 }} />
        {backButton}
      </div>
    );
  }
}

export default DigestItem;
