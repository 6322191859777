import React from 'react';
import From from '../../components/CommunicationForm';

const Secondary = () => {
  return (
    <>
      <From title="Describe your problem" category="problemWithManager" />
    </>
  );
};

export default Secondary;
