import React from 'react';
import { Switch } from 'react-router-dom';
import IRouteItems from '../../interfaces/routing';
import items from '../../routes';
import Fallback from './Fallback';
import Route from './Route';

const sortRoutes = (array: any[]) => {
  return array.sort((a: any, b: any) => {
    const left = a.path.replace(/^\//, '').split('/');
    const right = b.path.replace(/^\//, '').split('/');
    const largest = left.length > right.length ? left : right;
    for (let i = 0; i < largest.length; i += 1) {
      const compareA = left[i];
      const compareB = right[i];
      if (!compareA && compareB) {
        return 1;
      }
      if (compareA && !compareB) {
        return -1;
      }
      const isAParam = compareA.indexOf(':') === 0;
      const isBParam = compareB.indexOf(':') === 0;
      if (isAParam && !isBParam) {
        return 1;
      }
      if (!isAParam && isBParam) {
        return -1;
      }
      if (compareA !== compareB) {
        return compareA > compareB ? 1 : -1;
      }
    }
    return -1;
  });
};

const makeRoute = (list: IRouteItems, parentPath = '/') => {
  const routes: any[] = [];
  for (const path in list) {
    const item = list[path];
    const { title, items: children } = item;
    const childPath = `${parentPath}/${path}`.replace(/\/{2,}/g, '/').replace(/([^^])\/$/g, '$1');
    if ((item.hasOwnProperty('component') || item.hasOwnProperty('redirect')) && !item.disabled) {
      routes.push({ ...item, key: `${childPath}-${title}`, path: childPath });
    }
    if (children) {
      const childrenList = makeRoute(children, childPath);
      if (childrenList.length) {
        routes.push(...childrenList);
      }
    }
  }
  return routes;
};

const mapRoute = (item: any) => <Route exact={true} {...item} />;

const Routes = () => {
  const routes = makeRoute(items);
  const sorted = sortRoutes(routes);
  return (
    <Switch>
      {sorted.map(mapRoute)}
      <Fallback />
    </Switch>
  );
};
export default Routes;
