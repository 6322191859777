import { Add, Check, Close } from '@mui/icons-material';
import { Divider, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput } from '@mui/material';
import React from 'react';
import { ICreateCallbacks, IGroup, removeGroupFromItemType } from '../../../../interfaces/outstaff';
import isInvalid from '../../../../utils/is-invalid';
import GroupMenuItem from './GroupMenuItem';

interface IProps extends ICreateCallbacks {
  uid: number;
  groups: IGroup[];
  currentGroups: IGroup[];
  removeGroupFromItem: removeGroupFromItemType;
  isDisabled: boolean;
  handleClose: () => any;
}

interface IState {
  groupName: string | null;
  groupNameError: string | null;
  isLoading: boolean;
  isOpenCreateForm: boolean;
}
class GroupMenu extends React.Component<IProps, IState> {
  state = {
    list: [],
    groupName: null,
    groupNameError: null,
    isLoading: false,
    isOpenCreateForm: false,
  };

  render() {
    const { uid, groups, currentGroups, addGroupToItem, createAndAddGroupToItem, removeGroupFromItem, isDisabled, handleClose } = this.props;

    const { groupName, groupNameError, isLoading, isOpenCreateForm } = this.state;

    const openCreateForm = () => {
      if (!isLoading) {
        this.setState({ isOpenCreateForm: true });
      }
    };

    const onChange = ({ target: { value } }: any) => {
      this.setState({ groupName: value });
    };

    const validate = async () => {
      const error = isInvalid({ type: 'string', minLength: 1 }, groupName);
      this.setState({ groupNameError: error ? error.map(({ message }) => message).join('; ') : null });
      return !error;
    };

    const onBlur = async ({ target: { value } }: any) => {
      this.setState({ groupName: value });
      await validate();
    };

    const setLoading = (val: boolean) => this.setState({ isLoading: val });

    const handleSave = async () => {
      if ((await validate()) && groupName) {
        try {
          await createAndAddGroupToItem(uid, groupName);
          await handleClose();
        } catch (e) {
          // do nothing
        }
      }
    };

    let items: any[] = [];

    if (!groups.length) {
      if (isLoading) {
        items = [
          <MenuItem key="loading" disabled={true}>
            Groups are loading...
          </MenuItem>,
        ];
      } else {
        items = [
          <MenuItem key="empty" disabled={true}>
            There are no groups
          </MenuItem>,
        ];
      }
    } else {
      items = groups.map(({ id, name }) => {
        const used = !!currentGroups.find(({ id: exists }) => id === exists);
        return (
          <GroupMenuItem
            key={id}
            id={id}
            name={name}
            uid={uid}
            used={used}
            disabled={isDisabled || isLoading}
            setLoading={setLoading}
            handleClose={handleClose}
            addGroupToItem={addGroupToItem}
            removeGroupFromItem={removeGroupFromItem}
          />
        );
      });
    }
    const menu = [];
    if (isOpenCreateForm) {
      const stopPropagation = (e: any) => e.stopPropagation();
      menu.push(
        <MenuItem key="create" disabled={true}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          <ListItemText>Creating new group</ListItemText>
        </MenuItem>,
        <Divider key="head-divider" />,
        <FormControl key="name-input" sx={{ mx: 2, my: 1 }} error={!!groupNameError}>
          <InputLabel htmlFor="gname">Name</InputLabel>
          <OutlinedInput
            disabled={isDisabled || isLoading}
            value={groupName}
            label="Name"
            id="gname"
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={stopPropagation}
          />
          <FormHelperText>{groupNameError}</FormHelperText>
        </FormControl>,
        <Divider key="footer-divider" sx={{ my: 1 }} />,
        <MenuItem key="small" selected={false} disabled={isDisabled || isLoading} onClick={handleSave}>
          <ListItemIcon>
            <Check fontSize="small" />
          </ListItemIcon>
          <ListItemText>Save</ListItemText>
        </MenuItem>,
      );
    } else {
      menu.push(
        <MenuItem key="create" disabled={isDisabled || isLoading} onClick={openCreateForm}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          <ListItemText>Create new group</ListItemText>
        </MenuItem>,
        <Divider key="divider" />,
        ...items,
      );
    }
    return (
      <>
        {menu}
        <Divider sx={{ my: 1 }} />
        <MenuItem disabled={isDisabled || isLoading} onClick={handleClose}>
          <ListItemIcon>
            <Close fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cancel</ListItemText>
        </MenuItem>
      </>
    );
  }
}
export default GroupMenu;
