import { AlertColor } from '@mui/material';
import { makeAutoObservable } from 'mobx';
import React, { createContext } from 'react';

type TSeverity = AlertColor | null;

const def: { open: boolean; message: string; severity: TSeverity; ts: number } = { open: false, message: '', severity: null, ts: 0 };

interface IMessage {
  message: string;
  severity: TSeverity;
}

class Alert {
  constructor() {
    makeAutoObservable(this);
  }

  private queue: IMessage[] = [];

  public state = def;

  public show(message: string, severity: TSeverity = null) {
    this.queue.push({ message, severity });
    if (!this.state.open) {
      this._processQueue();
    }
  }

  // private to: any;

  private _processQueue() {
    if (this.queue.length > 0) {
      const { message, severity } = this.queue.shift() as IMessage;
      this.state = { open: true, message, severity, ts: Date.now() };
      // this.to = setTimeout(() => this.handleClose(), 5000);
    }
  }

  public handleClose() {
    this.state.open = false;
  }

  public handleExited() {
    this.handleClose();
    this._processQueue();
  }
}

const alert = new Alert();

export const show = (message: string, severity: TSeverity = null) => alert.show(message, severity);

export const Context = createContext<Alert>(alert);
const Provider = ({ children }: any) => <Context.Provider value={alert}>{children}</Context.Provider>;

export default Provider;
