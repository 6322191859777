import React from 'react';
import CommunicationForm from '../../components/CommunicationForm';
import Tabs, { ITab } from '../../components/Tabs';

class DevFeedback extends React.Component {
  render() {
    const tabs: ITab[] = [
      {
        title: 'Bug report',
        content: () => (
          <>
            <p>
              Hey. This application is currently under development. This is possible if it may contain errors, problems, etc. If you are faced with something
              similar - describe the problem using the form below. The more detailed the description, the faster the problem will be resolved.
            </p>
            <p>I would add the ability to attach screenshots, but I'm too lazy...</p>
            <CommunicationForm title="So, have you found a bug?" category="bugReport" />
          </>
        ),
      },
      {
        title: 'Feature request',
        content: () => (
          <>
            <p>
              Hey. If you know what is missing in this application but you or your colleague really need it - describe it and we will think about how to
              implement it
            </p>
            <CommunicationForm title="So, what feature do you want to offer?" category="featureRequest" />
          </>
        ),
      },
    ];
    return <Tabs tabs={tabs} />;
  }
}

export default DevFeedback;
