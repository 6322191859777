import GA from 'react-ga';

GA.initialize('UA-213647795-1');

export const createPageView = (uri: string) => {
  GA.pageview(uri);
};

export const createEvent = (params: { category: string; action: string; label?: string; value?: number; nonInteraction?: boolean }) => {
  GA.event(params);
};
