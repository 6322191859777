import { makeAutoObservable } from 'mobx';
import React, { Component, createContext, FC } from 'react';

interface IProps {
  color: string;
}

class TopMessage {
  public component?: FC | typeof Component;
  public canBeClosed?: boolean;
  public props?: IProps;

  constructor() {
    makeAutoObservable(this);
  }

  public open(component: FC | typeof Component, canBeClosed?: boolean, props?: IProps) {
    this.component = component;
    this.canBeClosed = canBeClosed;
    this.props = props;
  }

  public close() {
    if (this.canBeClosed) {
      this.component = undefined;
      this.props = undefined;
      this.canBeClosed = undefined;
    }
  }
}

const topMessage = new TopMessage();

export const open = (component: FC | typeof Component, canBeClosed?: boolean, props?: IProps) => topMessage.open(component, canBeClosed, props);
export const close = () => topMessage.close();

export const Context = createContext<TopMessage>(topMessage);
const Provider = ({ children }: any) => <Context.Provider value={topMessage}>{children}</Context.Provider>;

export default Provider;
