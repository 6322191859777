import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

interface IField {
  title: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
  getValue: (item: any) => React.ReactNode;
}

export type ContentTableColsType = IField[];

interface IProps {
  cols: ContentTableColsType;
  data: any[];
  sx?: SxProps;
}

const ContentTable = (props: IProps) => {
  return (
    <Table sx={props.sx}>
      <TableHead>
        <TableRow>
          {props.cols.map(({ title }) => (
            <TableCell key={title}>
              <b>{title}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data.map((item, i) => (
          <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {props.cols.map(({ textAlign, getValue }, k) => (
              <TableCell key={`${i}-${k}`} sx={{ textAlign }}>
                {getValue(item)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ContentTable;
