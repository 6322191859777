// import { EventEmitter } from 'events';

const { localStorage } = window;

// class LocalStorage extends EventEmitter {
//   public write = set;

//   public read = get;

//   public remove = set;
// }
// const ls = new LocalStorage();
// export default ls;

export const set = (key: string, value: any, expire?: number) => {
  localStorage.setItem(key, JSON.stringify({ created: Date.now(), value, expire }));
  // ls.emit('ls:write', { key, value, expire });
};

export const get = (key: string): any => {
  const val = localStorage.getItem(key);
  if (val) {
    try {
      const parsed = JSON.parse(val);
      if (parsed) {
        if (!parsed.expire || parsed.expire + parsed.created >= Date.now()) {
          const { value } = parsed;
          // ls.emit('ls:read', { key, value });
          return value;
        }
        del(key);
      }
    } catch (e) {
      // TODO log later
    }
  }
  return null;
};

export const del = (key: string) => {
  localStorage.removeItem(key);
  // ls.emit('ls:remove', { key });
};

let run = false;

const clearExpired = () => {
  if (run) {
    return;
  }
  run = true;
  for (const key in window.localStorage) {
    get(key);
  }
};

setInterval(() => clearExpired(), 600000);
