import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Preloader from '../Preloader';

export interface IFieldView {
  title: string;
  value: React.ReactNode;
  compact?: boolean;
}

interface ISectionView {
  title: string;
  fields: IFieldView[];
  isLoading?: boolean;
  isError?: React.ReactNode;
}

export type DataType = ISectionView[];

export type DataViewProps = { data: DataType };

const Field = ({ field }: { field: IFieldView }) => {
  const value = (
    <Typography variant="h6">
      <b>{field.value}</b>
    </Typography>
  );
  // if (field.compact) {
  return (
    <Box className="data-section" sx={{ my: 2 }}>
      <Typography variant="subtitle1">{field.title}</Typography>
      {value}
    </Box>
  );
  // }
  // return (
  //   <Grid container={true}>
  //     <Grid item={true} xs={6}>
  //       <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
  //         {field.title}
  //       </Typography>
  //     </Grid>
  //     <Grid item={true} xs={6}>
  //       {value}
  //     </Grid>
  //   </Grid>
  // );
};

const Section = ({ section }: { section: ISectionView }) => {
  return (
    <div className="data-section">
      <Typography variant="h5" mt={2} mb={1}>
        {section.title}
      </Typography>
      <Preloader isLoading={!!section.isLoading} fallback={section.isError && <div>{section.isError}</div>}>
        {section.fields && (
          <Grid container={true}>
            {section.fields.map((field, i) => (
              <Grid key={`${i}-${field.title}`} item={true} md={field.compact ? 6 : 12}>
                <Field field={field} />
              </Grid>
            ))}
          </Grid>
        )}
      </Preloader>
    </div>
  );
};

const DataView = ({ data }: { data: DataType }) => {
  return (
    <div className="data-view">
      {data.map((section, i) => (
        <div key={`${i}-${section.title}`}>
          {i > 0 && <Divider sx={{ my: 2 }} />}
          <Section section={section} />
        </div>
      ))}
    </div>
  );
};

export default DataView;
