import { parse, stringify } from 'querystring';
import { getLoginLink } from '../../apis/user';
import { EOAuth } from '../../constants/user';
import { del as delLs, get as getLs, set as setLs } from '../local-storage';

export const get = (): string | null => {
  const { token } = getLs('token') || {};
  return token || null;
};

export const set = (token: string) => {
  setLs('token', { token });
};

export const del = () => {
  delLs('token');
};

export const redirectToAuth = async (via: EOAuth, redirect?: string) => {
  const link = await getLoginLink(via);
  const url = new URL(link);
  const urlQuery = parse(url.search);
  if (redirect) {
    urlQuery.state = redirect;
  }
  url.search = stringify(urlQuery);
  window.location.href = url.toString();
};
