import { Assignment, Edit, List, PeopleAlt, PostAdd } from '@mui/icons-material';
import React from 'react';
import Megaphone from '../../components/elements/icons/Megaphone';
import { ERoles } from '../../constants/user';
import IRouteItems from '../../interfaces/routing';
import CreateCV from '../../pages/cv/Create';
import ListCV from '../../pages/cv/List';
import PostToChannel from '../../pages/outstaff/Channel';
import PostToChat from '../../pages/outstaff/Chat';

const items: IRouteItems = {
  outstaff: {
    title: 'Outstaff',
    icon: <Assignment />,
    description: 'Tools to work with outstaff/outsource companies',
    items: {
      channel: {
        title: 'Post to telegram channels',
        icon: <Megaphone />,
        component: PostToChannel,
        roles: [ERoles.OUTSTAFFER],
      },
      chat: {
        title: 'Post to telegram chats',
        icon: <PeopleAlt />,
        component: PostToChat,
        roles: [ERoles.OUTSTAFFER],
      },
      cv: {
        title: 'List of CVs',
        icon: <List />,
        component: ListCV,
        roles: [ERoles.OUTSTAFFER],
      },
      'cv/create': {
        title: 'Create CV',
        icon: <PostAdd />,
        component: CreateCV,
        roles: [ERoles.KINZHAL],
      },
      'cv/edit/:id': {
        title: 'Edit CV',
        icon: <Edit />,
        component: CreateCV,
        menu: false,
        roles: [ERoles.KINZHAL],
      },
    },
  },
};

export default items;
