import React from 'react';
import pic from './dio2.png';

class DIO2 extends React.Component {
  render() {
    return (
      <>
        <img alt="KONO DIO DA" src={pic} />
        <p>KONO DIO DA!</p>
      </>
    );
  }
}
export default DIO2;
