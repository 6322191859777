import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { IFieldProps, INumberEnum, IProp, IStringEnum } from './interfaces';

const filter: <T extends string | number>(arr: T[]) => T[] = arr => arr.filter(item => item || item === 0);
export const SelectInput = ({ id, disabled, edited, field, error, value }: IFieldProps) => {
  const input = field as (IStringEnum | INumberEnum) & IProp;
  const val = (value as string | undefined) || '';
  const hasError = !!error;
  const values = filter<string | number>(input.enum);
  return (
    <>
      <InputLabel id={id} required={input.required}>
        {input.title}
      </InputLabel>
      <Select
        name={id}
        disabled={disabled}
        error={hasError}
        value={val}
        label={`${input.title}${input.required ? ' *' : ''}`}
        onChange={edited}
        variant="outlined"
      >
        <MenuItem value={undefined} disabled={!input.nullable}>
          -Select item-
        </MenuItem>
        {values.map((item: string | number) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </>
  );
};
