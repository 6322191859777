import { Info, Update } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { IRemaining, UsedDetailsType } from '../../interfaces/user';
import IconButton from '../elements/IconButton';
import Heading from '../elements/typography/Heading';
import Preloader from '../Preloader';
import UsedDetails from '../UsedDetails';

const WORKDAY_LENGTH = 8;

interface IState {
  isLoading: boolean;
  remainingHours?: number;
  error?: string;
}

class Remaining extends React.Component<{
  getRemaining: () => Promise<IRemaining>;
  getDetails?: () => Promise<UsedDetailsType>;
  title?: string;
  description?: string;
  details?: string;
}> {
  state: IState = { isLoading: true };

  private async _loadRemaining() {
    try {
      await this.setState({ remaining: { isLoading: true } });
      const { remainingHours } = await this.props.getRemaining();
      await this.setState({ isLoading: false, remainingHours, error: null });
    } catch (e) {
      this.setState({ isLoading: false, error: (e as Error).message });
    }
  }

  componentDidMount() {
    this._loadRemaining();
  }

  private _formatRemaining() {
    if (this.state.remainingHours === undefined) {
      return 'n/a';
    }
    const days = Math.floor(this.state.remainingHours / WORKDAY_LENGTH);

    const hours = Math.floor(this.state.remainingHours % WORKDAY_LENGTH);
    let exp = '';
    if (days) {
      exp += `${days} ${days > 1 ? 'working days' : 'working day'}`;
    }
    if (hours) {
      exp += ` ${hours} ${hours > 1 ? 'hours' : 'hour'}`;
    }

    if (!exp) {
      exp = "Looks like you've completely exhausted it";
    }

    return exp;
  }

  render() {
    const reload = () => this._loadRemaining();
    return (
      <div className="remaining">
        <Heading>{this.props.title || 'Remaining'}</Heading>
        <Preloader isLoading={this.state.isLoading} fallback={this.state.error}>
          <Typography component="div" variant="h6">
            <div className="remaining-description">{this.props.description}</div>
            <div className="remaining-value">
              <IconButton disabled={this.state.isLoading} size="small" onClick={reload} sx={{ mr: 1, mt: -0.5 }}>
                <Update />
              </IconButton>
              {this._formatRemaining()}
              <IconButton sx={{ mr: 1, mt: -0.5 }} tooltip={`${this.state.remainingHours} hours`}>
                <Info />
              </IconButton>
            </div>
          </Typography>
          {this.props.getDetails && <UsedDetails sx={{ mt: 2 }} getDetails={this.props.getDetails} details={this.props.details} />}
        </Preloader>
      </div>
    );
  }
}

export default Remaining;
