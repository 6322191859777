import { Delete } from '@mui/icons-material';
import { IconButton, ListItem, ListItemText, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { addGroupToItemType, removeGroupFromItemType } from '../../../../interfaces/outstaff';

const GroupMenuItem = ({
  id,
  name,
  uid,
  used,
  disabled,
  setLoading,
  addGroupToItem,
  removeGroupFromItem,
  handleClose,
}: {
  id: number;
  name: string;
  uid: number;
  used: boolean;
  disabled: boolean;
  addGroupToItem: addGroupToItemType;
  removeGroupFromItem: removeGroupFromItemType;
  // setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: (l: boolean) => any;
  handleClose: () => void;
}) => {
  const assign = async () => {
    if (used) {
      return;
    }
    await setLoading(true);
    try {
      await addGroupToItem(uid, id);
      await handleClose();
    } catch (e) {
      // do nothing
    } finally {
      await setLoading(false);
    }
  };
  const unassign = async () => {
    if (!used) {
      return;
    }
    await setLoading(true);
    try {
      await removeGroupFromItem(uid, id);
      await handleClose();
    } catch (e) {
      // do nothing
    } finally {
      await setLoading(false);
    }
  };
  if (used) {
    return (
      <ListItem disabled={disabled} key={id}>
        <ListItemText sx={{ my: 0 }}>{name}</ListItemText>
        <Typography variant="body2" color="text.secondary">
          Used
          <IconButton sx={{ ml: 1, mt: -1.25, mb: -1 }} edge="end" size="small" color="inherit" onClick={unassign}>
            <Delete fontSize="inherit" />
          </IconButton>
        </Typography>
      </ListItem>
    );
  }
  return (
    <MenuItem disabled={disabled} key={id} onClick={assign}>
      <ListItemText>{name}</ListItemText>
    </MenuItem>
  );
};

export default GroupMenuItem;
