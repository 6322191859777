import { makeAutoObservable } from 'mobx';
import React, { createContext } from 'react';
import IUser from '../../interfaces/user';
import { del as delAuth, get as getAuth, set as setAuth } from '../../utils/auth';
// import { roles } from '../../constants';

// import snackbarStore from '../snackbar';

class Auth {
  public token: string | null;
  public user: IUser | null = null;
  constructor() {
    makeAutoObservable(this);
    this.token = getAuth();
  }

  public async login(token: string) {
    // if (!roles.includes(role)) {
    //   // snackbarStore.show('You do not have access', 'error');
    //   throw new Error('Forbidden');
    // }
    this.token = token;
    this.user = null;
    setAuth(token);
  }

  public async setUser(user: IUser) {
    this.user = user;
  }

  public logout() {
    // this.token = null;
    // this.user = null;
    delAuth();
    window.location.reload();
  }
}

const auth = new Auth();

export const isAuth = () => !!auth.token;

export const logout = () => auth.logout();

export const setUser = (user: IUser) => auth.setUser(user);

export const Context = createContext<Auth>(auth);
const Provider = ({ children }: any) => <Context.Provider value={auth}>{children}</Context.Provider>;

export default Provider;
