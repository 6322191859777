import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import Heading from '../elements/typography/Heading';

interface IProps {
  title: string;
  subtitle?: string;
  description?: React.ReactNode;
  error?: string;
  className?: string;
  children?: React.ReactNode;
  level?: number;
  ref?: any;
}
export const Section = (props: IProps) => (
  <Paper
    ref={props.ref}
    sx={{ py: 1, px: 2, my: 2 }}
    square={true}
    elevation={props.level !== undefined ? Math.min(props.level * 2 + 2, 24) : props.level}
    className={props.className}
  >
    <Heading small={true} sx={{ color: props.error ? (theme: any) => theme.palette.error.main : undefined }}>
      {props.title}{' '}
      {props.subtitle && (
        <Typography variant="subtitle1" component="span">
          {props.subtitle}
        </Typography>
      )}
    </Heading>
    {props.description ? (
      <Typography variant="subtitle2" className="section-description">
        {props.description}
      </Typography>
    ) : null}
    {props.error && (
      <Box pb={1} sx={{ color: (theme: any) => theme.palette.error.main }}>
        {props.error}
      </Box>
    )}
    {props.children}
  </Paper>
);
