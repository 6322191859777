const copy = async (input: string) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(input);
    return;
  }
  if (window.hasOwnProperty('clipboardData')) {
    (window as any).clipboardData.setData('Text', input);
    return;
  }
  const element = document.createElement('textarea');
  element.value = input;
  document.body.appendChild(element);
  element.select();
  document.execCommand('Copy');
  element.remove();
  // document.execCommand('Copy', true, input);
};
export default copy;
