import { Logout } from '@mui/icons-material';
import { Divider, ListItem, ListItemIcon, ListItemText, MenuItem, MenuProps } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { StateContext } from '../../../../stores';
import { open } from '../../../../stores/menu';
import Button from '../../../elements/Button';
import Container from '../../../elements/Container';
import Link from '../../../elements/Link';
import Logo from '../../../elements/Logo';
import UserAvatar from '../../../elements/UserAvatar';

const styles: any = (theme: any) => ({
  root: {
    background: '#000',
    color: '#eee',
  },
  link: {
    color: '#eee',
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderColor: 'rgba(255,255,255,0.5)',
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    cursor: 'pointer',
  },
  menuAvatar: { marginRight: theme.spacing(1) },
  menu: { marginTop: theme.spacing(2) },
});

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

@observer
class Header extends React.Component<any> {
  static contextType = StateContext;

  state: { anchorEl: null | HTMLElement } = { anchorEl: null };

  render() {
    const { classes } = this.props;
    const { auth, navigation } = this.context;
    const logOut = () => auth.logout();
    const isAuth = !!auth.token;
    const handleClick = (event: any) => {
      const component = () => (
        <>
          <ListItem>
            <UserAvatar user={auth.user} className={classes.menuAvatar} />
            <ListItemText primary={`${auth.user.name.firstName} ${auth.user.name.lastName}`} secondary={auth.user.email} />
          </ListItem>
          <Divider />
          <MenuItem onClick={logOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </>
      );
      const props: MenuProps = {
        open: true,
        MenuListProps: {
          'aria-labelledby': 'basic-button',
        },
        className: classes.menu,
        transformOrigin: { horizontal: 'right', vertical: 'top' },
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      };

      open(event.currentTarget, component, props);
    };
    return (
      <AppBar className={classes.root} position="static">
        <Container>
          <Toolbar>
            <Link href="/" className={classes.link}>
              <Logo />
            </Link>
            <Divider className={classes.divider} orientation="vertical" variant="middle" flexItem={true} />
            <Typography variant="h6" className={classes.title}>
              {navigation.title}
            </Typography>
            {isAuth ? (
              <div>
                <UserAvatar className={classes.avatar} user={auth.user} onClick={handleClick} />
              </div>
            ) : (
              <Button color="inherit" href="/login">
                Sign In
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
