import { makeAutoObservable } from 'mobx';
import React, { createContext } from 'react';

type BtnLinkType = { href: string };
type BtnFnType = { onClick: (e: any) => any };
export type BtnType = { title: string; icon: React.ReactNode; bgColor?: string; color?: string } & (BtnLinkType | BtnFnType);

class FAB {
  constructor() {
    makeAutoObservable(this);
  }
  public keyList: symbol[] = [];
  public stack: { [key: symbol]: BtnType } = {};

  public add(btn: BtnType): symbol {
    const key = Symbol();
    this.stack[key] = btn;
    this.keyList.push(key);
    return key;
  }

  public remove(key: symbol) {
    delete this.stack[key];
    this.keyList = this.keyList.filter(s => s !== key);
  }

  public getList() {
    return this.keyList.map(key => this.stack[key]);
  }
}

const fab = new FAB();

export const add = (btn: BtnType): symbol => fab.add(btn);
export const remove = (key: symbol) => fab.remove(key);
export const getList = () => fab.getList();

export const Context = createContext<FAB>(fab);
const Provider = ({ children }: any) => <Context.Provider value={fab}>{children}</Context.Provider>;

export default Provider;
