import { Container as MuiContainer, ContainerProps } from '@mui/material';
import React from 'react';

interface IProps extends ContainerProps {
  maxWidth?: never;
}

class Container extends React.Component<IProps> {
  render() {
    return (
      <MuiContainer {...this.props} maxWidth="lg" sx={{ ...(this.props.sx || {}), minWidth: theme => theme.breakpoints.values.xs }}>
        {this.props.children}
      </MuiContainer>
    );
  }
}
export default Container;
